import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import SelectOrganization from './SelectOrganization';

function SelectDefaultOrganization(props) {
  const { appId, mode, userId, orgId, setOrgId } = props;

  const allOrganizations = useSelector((state) => state[appId].organizations);
  const allMemberships = useSelector((state) => state[appId].memberships);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    let availableOrganizations = [];
    let _organizations = [];
    let _portfolios = [];

    if (mode === 'edit') {
      const userMemberships = filter(allMemberships, { user_id: userId });
      const userOrgIds = uniq(
        userMemberships.map((membership) => membership.org_id)
      );
      availableOrganizations = filter(allOrganizations, (org) =>
        userOrgIds.includes(org.org_id)
      );
    } else if (mode === 'create') {
      availableOrganizations = allOrganizations;
    }

    _organizations = filter(availableOrganizations, { is_portfolio: false });
    _portfolios = filter(availableOrganizations, { is_portfolio: true });
    setOrganizations(sortBy(_organizations, 'name'));
    setPortfolios(sortBy(_portfolios, 'name'));
  }, [mode, allOrganizations, allMemberships, userId]);

  return (
    <SelectOrganization
      label='Default Organization'
      portfolios={portfolios}
      organizations={organizations}
      orgId={orgId}
      setOrgId={setOrgId}
    />
  );
}

SelectDefaultOrganization.propTypes = {
  appId: PropTypes.string.isRequired,
  mode: PropTypes.string,
  userId: PropTypes.string.isRequired,
  orgId: PropTypes.string,
  setOrgId: PropTypes.func.isRequired,
};

export default SelectDefaultOrganization;
