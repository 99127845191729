import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../../../../helpers/constants';
import { generateSiteTree } from '../../../../../helpers/demandTree';
import ResourceTree from '../../../../../components/ResourceTree';
import CreateResourceListButton from '../../../../../components/Buttons/CreateResourceListButton';
import MeterDialog from '../../../../../components/Dialogs/demand/MeterDialog';

const CreateComponent = (props) => {
  const { site } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CreateResourceListButton label='Create Meter' setOpen={setOpen} />
      <MeterDialog
        open={open}
        closeDialog={handleClose}
        orgId={site.org_id}
        siteId={site.site_id}
      />
    </>
  );
};

export default function SiteMeterss(props) {
  const { site } = props;
  const demandState = useSelector((state) => state.demand);

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const { meters } = demandState;
    const { children: nodes } = generateSiteTree(site, meters);
    setNodes(nodes);
  }, [site, demandState]);

  return (
    <ResourceTree
      nodes={nodes}
      path={ROUTES.GRIDCAP.path}
      createComponent={<CreateComponent site={site} />}
    />
  );
}
