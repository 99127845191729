import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import UsersTable from './Table/UsersTable';

function UsersGrid(props) {
  const { appId } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <UsersTable appId={appId} />
      </Grid>
    </Grid>
  );
}

export default UsersGrid;
