import reduce from 'lodash/reduce';

export const getMetricNames = (metrics) => {
  return reduce(
    metrics,
    (acc, group) => {
      return [...acc, ...group.map((settings) => settings.metric)];
    },
    []
  );
};
