import React, { useState } from 'react';
import get from 'lodash/get';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import { ROLES, findRole } from '../../../helpers/constants';

const CustomPopover = (props) => {
  const { value, row: membership, handleClick, appId } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open
    ? `popover-${membership?.org_id}-${membership?.user_id}`
    : undefined;

  const currentRoleName = findRole(appId, value);
  const currentRole = get(ROLES, `${appId}.${currentRoleName}`, null);
  return (
    <>
      <Chip
        onClick={handleChipClick}
        icon={<FontAwesomeIcon icon={['fal', currentRole.icon]} />}
        label={currentRoleName.toLowerCase()}
        sx={{ minWidth: '6rem' }}
      />
      {handleClick && (
        <Popover
          key={`popover-${membership?.org_id}-${membership?.user_id}`}
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>
          <List dense disablePadding>
            {Object.keys(ROLES[appId]).map((roleName) => {
              const role = ROLES[appId][roleName];
              return (
                <ListItemButton
                  key={`list-item-${roleName}`}
                  disabled={value === Number(role.value)}
                  selected={value === Number(role.value)}
                  onClick={() => {
                    handleClick(membership, role.value);
                    handleClose();
                  }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <FontAwesomeIcon icon={['fal', role.icon]} />
                  </ListItemIcon>
                  <ListItemText primary={roleName.toLowerCase()} />
                </ListItemButton>
              );
            })}
          </List>
        </Popover>
      )}
    </>
  );
};

export const MembershipRoleTypeProvider = (props) => {
  const { handleClick, appId } = props;

  return (
    <DataTypeProvider
      formatterComponent={(innerProps) => {
        return (
          <CustomPopover
            {...innerProps}
            handleClick={handleClick}
            appId={appId}
          />
        );
      }}
      {...props}
    />
  );
};
