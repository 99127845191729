import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import TextField from '@mui/material/TextField';

import { GOOGLE_MAPS_API_KEY } from './constants/google';
import '../styles/google-autocomplete.css';

export default function AddressField(props) {
  const { address, onAddressSelect, handleAddressChange } = props;

  const { ref: materialRef } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: onAddressSelect,
    libraries: ['places'],
    inputAutocompleteValue: 'address',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });

  return (
    <TextField
      fullWidth
      label='Address'
      variant='standard'
      inputRef={materialRef}
      value={address}
      onChange={handleAddressChange}
    />
  );
}
