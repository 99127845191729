import React from 'react';
import find from 'lodash/find';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const OrganizationIdNameTypeProvider = (props) => {
  const { organizations } = props;
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        const organization = find(organizations, { org_id: value });
        return organization?.name;
      }}
      {...props}
    />
  );
};
