import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MeterGridItem from './MeterGridItem';

function MetersGrid(props) {
  const { rto, meters, selectedSiteId } = props;
  const { organizations: allOrganizations, sites: allSites } = useSelector(
    (state) => state.demand
  );

  const [organizations, setOrganizations] = useState([]);
  const [groupedMeters, setGroupedMeters] = useState({});

  useEffect(() => {
    setGroupedMeters(groupBy(meters, 'org_id'));
  }, [meters]);

  useEffect(() => {
    const orgIds = Object.keys(groupedMeters);
    const organizations = map(orgIds, (orgId) =>
      find(allOrganizations, { org_id: orgId })
    );
    setOrganizations(sortBy(organizations, 'name'));
  }, [groupedMeters, allOrganizations]);

  return map(organizations, (organization, idx) => {
    const orgMeters = groupedMeters[organization?.org_id];
    return (
      <React.Fragment key={`organization-grid-items--${organization?.org_id}`}>
        <Grid key={`grid-item-text-${organization?.org_id}`} item xs={12}>
          <Typography align='left' fontWeight='bold' variant='h6' ml={2}>
            {organization?.name}
            {selectedSiteId &&
              ` - ${find(allSites, { site_id: selectedSiteId })?.name}`}
          </Typography>
        </Grid>
        {map(sortBy(orgMeters, ['name']), (meter) => (
          <MeterGridItem
            key={`meter-shed-grid-item-${meter.meter_id}`}
            meter={meter}
            rto={rto}
          />
        ))}
        {idx !== organizations.length - 1 && (
          <Grid
            key={`grid-item-divider-${organization?.org_id}`}
            item
            xs={12}
            mx={1}>
            <Divider style={{ marginTop: '1rem' }} />
          </Grid>
        )}
      </React.Fragment>
    );
  });
}

MetersGrid.propTypes = {
  rto: PropTypes.object,
  meters: PropTypes.array,
  selectedSiteId: PropTypes.string,
};

export default MetersGrid;
