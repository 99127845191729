import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const CreateResourceListButton = (props) => {
  const { label, setOpen, disabled = false } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <ListItemButton onClick={handleOpen} disabled={disabled}>
      <ListItemIcon>
        <FontAwesomeIcon icon={['fal', 'plus-circle']} size='lg' />
      </ListItemIcon>
      <ListItemText secondary={label} />
    </ListItemButton>
  );
};

export default CreateResourceListButton;
