import React, { useEffect, useState } from 'react';
import { concat, find, isEmpty, reverse, sortBy } from 'lodash';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const TIMEZONES = [
  { name: 'UTC', zone: 'UTC', offset: 0 },
  { name: 'MST', offset: -7, zone: 'America/Phoenix' },
];

function SelectTimezone(props) {
  const { selectedTimezone, setSelectedTimezone, disabled = false } = props;
  const theme = useTheme();

  const [timezone, setTimezone] = useState({ name: '' });
  const [timezones, setTimezones] = useState(TIMEZONES);

  useEffect(() => {
    setTimezone(selectedTimezone);
    if (!find(timezones, { name: selectedTimezone.name })) {
      setTimezones(
        reverse(sortBy(concat(timezones, selectedTimezone), 'offset'))
      );
    }

    /* eslint-disable-next-line */
  }, [selectedTimezone]);

  const handleSelect = (e) => {
    e.preventDefault();
    let _timezone = find(timezones, { name: e.target.value });
    setSelectedTimezone(_timezone);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='timezone-select-label'
        sx={{ textAlign: 'center', width: '8rem' }}>
        Timezone
      </InputLabel>
      <Select
        disabled={disabled}
        disableUnderline
        labelId='timezone-select-label'
        id='timezone-select'
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={timezone.name}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        value={timezone.name || ''}
        variant='standard'
        onChange={handleSelect}
        sx={{ width: '4rem', textAlign: 'center' }}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}>
        {timezones.map((timezone) => {
          if (!isEmpty(timezone)) {
            return (
              <MenuItem
                key={timezone.zone}
                value={timezone.name}
                sx={{
                  color: theme.palette.primary.main,
                  ...theme.typography.body2,
                }}>
                {timezone.name}
              </MenuItem>
            );
          }
          return null;
        })}
      </Select>
    </FormControl>
  );
}

SelectTimezone.propTypes = {
  selectedTimezone: PropTypes.object,
  setSelectedTimezone: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectTimezone;
