import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map, reduce } from 'lodash';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';

import { unixTimestampToString } from '../../../../../helpers/date';
import ChartTooltip from './Tooltip';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';

export const METRICS = {
  temperature_2m: { color: 'orange', type: 'line', unit: '°F' },
  relative_humidity_2m: { color: 'grey', type: 'line', unit: '%' },
  surface_pressure: { color: 'blue', type: 'line', unit: 'MB' },
  precipitation: { color: 'blue', type: 'line', unit: 'in' },
  precipitation_probability: { color: 'blue', type: 'line', unit: '%' },
  apparent_temperature: { color: 'orange', type: 'line', unit: '°F' },
  dew_point_2m: { color: 'purple', type: 'line', unit: '°F' },
  wind_speed_10m: { color: 'grey', type: 'line', unit: 'mph' },
  wind_gusts_10m: { color: 'grey', type: 'line', unit: 'mph' },
  cloud_cover: { color: 'grey', type: 'area', unit: '%' },
  uv_index: { color: 'orange', type: 'line', unit: '' },
  visibility: { color: 'green', type: 'area', unit: 'mi' },
  enthalpy: { color: 'purple', type: 'line', unit: 'J' },
};

function WeatherChart({
  chartData,
  selectedMetrics,
  range,
  timezone,
  loading,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  if (loading)
    return (
      <Box sx={{ height: 400, width: '100%' }}>
        <WidgetLoader loading={loading} height={50} />
      </Box>
    );

  if (isEmpty(chartData))
    return (
      <Box
        sx={{
          height: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography>No data available</Typography>
      </Box>
    );

  const ticks = (() => {
    let _ticks = [];
    let _date = range.start;

    while (_date < range.end) {
      _ticks.push(_date.add(12, 'hour').unix());
      _date = _date.add(1, 'day');
    }
    return _ticks;
  })();

  const lineMetrics = reduce(
    METRICS,
    (acc, values, metric) => {
      if (values.type === 'line' && selectedMetrics.includes(metric)) {
        return { ...acc, [metric]: values };
      }
      return acc;
    },
    {}
  );
  const areaMetrics = reduce(
    METRICS,
    (acc, values, metric) => {
      if (values.type === 'area' && selectedMetrics.includes(metric)) {
        return { ...acc, [metric]: values };
      }
      return acc;
    },
    {}
  );

  return (
    <ResponsiveContainer
      key='pjm-markets-weather-chart-responsive-container'
      width='100%'
      height={400}>
      <ComposedChart
        width='100%'
        height={400}
        data={chartData}
        margin={{
          top: 5,
          right: 0,
          left: isMobile ? 0 : 5,
          bottom: 5,
        }}>
        <XAxis
          type='number'
          dataKey='timestamp'
          ticks={ticks}
          tickMargin={15}
          domain={[range.start.unix(), range.end.unix()]}
          tickFormatter={(unixTime) => {
            return unixTimestampToString(unixTime, timezone.offset, 'MMM Do');
          }}
        />
        <Tooltip
          content={<ChartTooltip timezone={timezone} metrics={METRICS} />}
        />
        {map(lineMetrics, (settings, metric) => {
          return (
            <Line
              dot={false}
              key={`line-${metric}`}
              dataKey={metric}
              stroke={theme.palette.veregy_colors[settings.color]}
              strokeWidth={2}
              connectNulls
            />
          );
        })}
        {map(areaMetrics, (settings, metric) => {
          return (
            <Area
              key={`area-${metric}`}
              dataKey={metric}
              stroke={theme.palette.veregy_colors[settings.color]}
              fill={theme.palette.veregy_colors[settings.color]}
              connectNulls
            />
          );
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

WeatherChart.propTypes = {
  chartData: PropTypes.array,
  selectedMetrics: PropTypes.array,
  range: PropTypes.object,
  timezone: PropTypes.object,
  loading: PropTypes.bool,
};

export default WeatherChart;
