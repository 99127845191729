import { createSlice } from '@reduxjs/toolkit';
import each from 'lodash/each';
import { dialogs as initialState } from '../initialState';

const { reducer, actions } = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openInfoDialog: (state, action) => {
      const { title, content } = action.payload;
      state.info.title = title;
      state.info.content = content;
    },
    openConfirmDialog: (state, action) => {
      state.confirm.title = action.payload.title;
      state.confirm.message = action.payload.message;
      state.confirm.onConfirm = action.payload.onConfirm;
    },
    openDialog: (state, action) => {
      state[action.payload.type] = action.payload;
    },
    closeDialog: (state, action) => {
      if (action.payload) {
        state[action.payload] = initialState[action.payload];
      } else {
        each(initialState, (val, key) => (state[key] = val));
      }
    },
  },
});

const { openDialog, openInfoDialog, closeDialog, openConfirmDialog } = actions;
export { openDialog, openInfoDialog, closeDialog, openConfirmDialog };
export default reducer;
