import React from 'react';
import isNull from 'lodash/isNull';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';

export const CheckBoxTypeProvider = (props) => {
  const { handleClick = () => {} } = props;
  return (
    <DataTypeProvider
      formatterComponent={(props) => {
        const { value, row } = props;

        let effectiveValue = (() => {
          if (typeof value === 'boolean') return value;
          if (value === 'true') return true;
          if (value === 'false') return false;
        })();

        return (
          <Checkbox
            checked={effectiveValue}
            disabled={isNull(effectiveValue)}
            onClick={() => handleClick(row)}
          />
        );
      }}
      {...props}
    />
  );
};
