import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import BaseTable from '../../../../components/Table/BaseTable';
import { DateTypeProvider } from '../../../../components/Table/providers';
import { ETCheckboxTypeProvider } from '../../../../components/Table/providers/energytracer/ETCheckboxTypeProvider';
import EditCell from '../../../../components/Table/cells/EditCell';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';
import OrganizationLicenseDiaglog, {
  defaultState,
} from '../../../../components/Dialogs/energytracer/OrganizationLicenseDialog';

const OPPOSITE_KEYS = {
  active: 'inactive',
  inactive: 'active',
};

export default function Licenses() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [licenses, setLicenses] = useState([]);
  const [selectedOrganization, setSelectedOrganization] =
    useState(defaultState);

  const [tableColumnExtensions] = useState([
    { columnName: 'active', align: 'center' },
  ]);
  const [integratedFilteringColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      predicate: (value, filter) => {
        return value.toLowerCase().includes(filter.value.toLowerCase());
      },
    },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      compare: (a, b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
      },
    },
  ]);
  const [columns] = useState([
    { title: 'Active', name: 'active', width: 0.1 },
    { title: 'Inactive', name: 'inactive', width: 0.1 },
    { title: 'Organization', name: 'organization_name', width: 0.5 },
    { title: 'License', name: 'type', width: 0.15 },
    { title: 'End Date', name: 'end_date', width: 0.2 },
  ]);

  const fetchData = useCallback(async () => {
    dispatch(showLoading());
    try {
      const data = await new WebAPIClient().GET('/licenses');
      let new_licenses = [];
      data.forEach((org) => {
        orderBy(Object.values(org.licenses), ['type'], ['desc']).forEach(
          (lic) => {
            new_licenses.push({
              ...lic,
              organization_name: org.name,
              organization_uuid: org.uuid,
              organization_reference_number: org.reference_number,
            });
          }
        );
      });
      setLicenses(new_licenses);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
    dispatch(hideLoading());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleToggleCheck = (license, key) => {
    const val = !license[key];
    let payload = {
      active: false,
      inactive: false,
    };
    payload[key] = val;
    if (val && license[OPPOSITE_KEYS[key]]) {
      payload[OPPOSITE_KEYS[key]] = false;
    }
    setLicenses((prev) =>
      prev.map((lic) => {
        if (
          lic.organization_id === license.organization_id &&
          lic.type === license.type
        ) {
          let new_lic = { ...lic };
          new_lic[key] = payload[key];
          new_lic[OPPOSITE_KEYS[key]] = payload[OPPOSITE_KEYS[key]];
          return new_lic;
        }
        return { ...lic };
      })
    );
    handleLicenseRequest(license, payload);
  };

  const handleLicenseRequest = async (license, payload) => {
    const local_payload = { ...payload, license_type: license.type };
    if (license.id) {
      //update existing license
      await new WebAPIClient().POST(
        `licenses/${license.organization_id}`,
        local_payload
      );
    } else {
      //add new license
      const response = await new WebAPIClient().PUT(
        `licenses/${license.organization_id}`,
        local_payload
      );
      setLicenses((prev) =>
        prev.map((lic) => {
          if (
            lic.organization_id === license.organization_id &&
            lic.type === license.type
          ) {
            let new_lic = { ...lic };
            new_lic.id = response.id;

            return new_lic;
          }
          return { ...lic };
        })
      );
    }
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid
        item
        xs={12}
        px={1}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <Card raised sx={{ maxWidth: 1000, width: '100%', px: 1 }}>
          <BaseTable
            minWidth={1000}
            rows={licenses}
            columns={columns}
            tableColumnExtensions={tableColumnExtensions}
            editColumn={
              <TableEditColumn
                width={42}
                cellComponent={(props) => (
                  <EditCell
                    disabled={false}
                    tooltipText='Edit Organization'
                    icon={['fal', 'edit']}
                    // eslint-disable-next-line
                    handleClick={() => {
                      setSelectedOrganization(props.row);
                    }}
                    {...props}
                  />
                )}
              />
            }>
            <ETCheckboxTypeProvider
              for={['active', 'inactive']}
              handleClick={handleToggleCheck}
            />

            <DateTypeProvider for={['end_date']} />

            <EditingState />
            <FilteringState />
            <SortingState
              defaultSorting={[
                { columnName: 'organization_name', direction: 'asc' },
              ]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering
              columnExtensions={integratedFilteringColumnExtensions}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <IntegratedPaging />
          </BaseTable>
        </Card>
        <OrganizationLicenseDiaglog
          selectedOrganization={selectedOrganization}
          setSelectedOrganization={setSelectedOrganization}
          setLicenses={setLicenses}
        />
      </Grid>
    </Grid>
  );
}
