import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { SOLAR_APP_ID } from '../solar';
import { DEMAND_APP_ID } from '../demand';

const findSolarResource = (itemId, state) => {
  const { organizations, sites, loggers, meters, inverters, sensors } = state;
  let _resource = null;
  if (itemId?.startsWith('org:')) {
    _resource = organizations.find((org) => {
      return org.org_id === itemId;
    });
  } else if (itemId?.startsWith('site:')) {
    _resource = sites.find((site) => site.site_id === itemId);
  } else if (itemId?.startsWith('logger:')) {
    _resource = loggers.find((logger) => logger.logger_id === itemId);
  } else if (itemId?.startsWith('meter:')) {
    _resource = meters.find((meter) => meter.meter_id === itemId);
  } else if (itemId?.startsWith('inverter:')) {
    _resource = inverters.find((inverter) => inverter.inverter_id === itemId);
  } else if (itemId?.startsWith('sensor:')) {
    _resource = sensors.find((sensor) => sensor.sensor_id === itemId);
  }

  return _resource;
};

const findDemandResource = (itemId, state) => {
  const { organizations, sites, meters } = state;
  let _resource = null;
  if (itemId?.startsWith('org:')) {
    _resource = organizations.find((org) => {
      return org.org_id === itemId;
    });
  } else if (itemId?.startsWith('site:')) {
    _resource = sites.find((site) => site.site_id === itemId);
  } else if (itemId?.startsWith('meter:')) {
    _resource = meters.find((meter) => meter.meter_id === itemId);
  }

  return _resource;
};

const useAppResource = (appId) => {
  const location = useLocation();
  const solarState = useSelector((state) => state.solar);
  const demandState = useSelector((state) => state.demand);

  const [resource, setResource] = useState(null);

  useEffect(() => {
    let _resource = null;
    if (!location?.state?.itemId) return;

    if (appId === SOLAR_APP_ID) {
      _resource = findSolarResource(location?.state?.itemId, solarState);
    } else if (appId === DEMAND_APP_ID) {
      _resource = findDemandResource(location?.state?.itemId, demandState);
    }

    setResource(_resource);
    return () => {
      setResource(null);
    };
  }, [location?.state?.itemId, appId, solarState, demandState]);

  return resource;
};

export default useAppResource;
