import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

const IconImg = (props) => {
  const { iconName, label, height } = props;
  const dispatch = useDispatch();

  return (
    <IconButton
      sx={{ p: 0, mr: 2, height, width: height }}
      onClick={() => dispatch(push(`/${iconName}`))}>
      <Box
        component='img'
        height={height}
        alt={`${label} Icon`}
        title={`${label} Icon`}
        src={`/icons/${iconName}-icon.png`}
      />
    </IconButton>
  );
};
export default IconImg;
