import React from 'react';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { DEMAND_APP_ID } from '../../../store/demand';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import AddressTimezoneListItems from '../AddressTimezoneListItems';
import useOrganization from '../../../store/hooks/useOrganization';
import ResourceListItem from '../../List/ResourceListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultState = {
  org_id: '',
  name: '',
  address: '',
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  location: { lat: 0, lng: 0 },
};

function SiteForm(props) {
  const { mode, site, setSite, handleSubmit } = props;
  const organization = useOrganization(site.org_id, DEMAND_APP_ID);

  const handleChange = (e) => {
    let value = e.target.value;
    setSite((prevSite) => ({ ...prevSite, [e.target.id]: value }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'create' && (
          <ResourceListItem
            label='Organization'
            name={organization.name}
            icon='buildings'
          />
        )}
        {mode === 'create' && <Divider />}
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField id='site_id' value={site.site_id} label='Site ID' />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label='Site Name'
          value={site.name}
          onChange={handleChange}
        />
        <AddressTimezoneListItems
          mode={mode}
          resource={site}
          setResource={setSite}
        />
      </List>
    </form>
  );
}

export default SiteForm;
