import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { TAG_VALUE_OPTIONS } from './options';
import SwipeableContainer from '../../../../components/SwipeableContainer';
import ApplyFilterDialog from './ApplyFilterDialog';

const CostsChartFilters = (props) => {
  const { filters, handleChangeFilters, width } = props;

  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState('');
  const [value, setValue] = useState('');
  const [valueOptions, setValueOptions] = useState([]);

  useEffect(() => {
    if (Object.keys(TAG_VALUE_OPTIONS).includes(tag)) {
      setValueOptions(TAG_VALUE_OPTIONS[tag]);
    } else {
      setValueOptions([]);
    }
  }, [tag]);

  const handleApplyFilter = () => {
    const _filters = [...filters, { tag, value }];
    handleChangeFilters(_filters);
    handleClose();
  };

  const handleDelete = (idx) => {
    handleChangeFilters(filters.filter((_, i) => i !== idx));
  };

  const handleClose = () => {
    setOpen(false);
    setTag('');
    setValue('');
  };

  return (
    <>
      <Card raised sx={{ minWidth: width }}>
        <SwipeableContainer>
          <Stack
            direction='row'
            spacing={1}
            mx={1}
            py='2px'
            justifyContent='flex-start'
            alignItems='center'
            width='100%'>
            <IconButton onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={['fal', 'plus-circle']} size='sm' />
            </IconButton>
            {filters.length === 0 && <Chip label='Filters' />}
            {filters.map((filter, idx) => {
              const label = `${filter.tag}:${filter.value}`;
              return (
                <Chip
                  key={idx}
                  label={label}
                  onDelete={() => handleDelete(idx)}
                />
              );
            })}
          </Stack>
        </SwipeableContainer>
      </Card>
      <ApplyFilterDialog
        open={open}
        tag={tag}
        setTag={setTag}
        value={value}
        setValue={setValue}
        valueOptions={valueOptions}
        handleClose={handleClose}
        handleApplyFilter={handleApplyFilter}
      />
    </>
  );
};

export default CostsChartFilters;
