import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDialog } from '../../store/dialogs';
import BaseDialog from './BaseDialog';

function ConfirmDialog() {
  const dispatch = useDispatch();
  const { title, message, onConfirm } = useSelector(
    (state) => state.dialogs.confirm
  );

  const handleClose = () => {
    dispatch(closeDialog('confirm'));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onConfirm();
    handleClose();
  };

  return (
    <BaseDialog
      id={title}
      title={title}
      disabled={false}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      submitText='Confirm'>
      {message}
    </BaseDialog>
  );
}

export default ConfirmDialog;
