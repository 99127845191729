import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import { init } from '@sentry/react';
import ClipboardJS from 'clipboard';

import _package from '../../package.json';
import icons from '../assets/icons';
import Landing from './Landing';
import Layout from './App/Layout';
import Home from './App/Home';
import AWS from './App/AWS';
import Users from './App/Users';
import LightLevel from './App/LightLevel';
import Gridcap from './App/Gridcap';
import EnergyTracer from './App/EnergyTracer';

import { ROUTES } from '../helpers/constants';
import { setTokens } from '../helpers/auth/tokens';
import { getEnvUserPoolClients } from '../helpers/auth/clients';
import { authenticateSolarUser, SOLAR_APP_ID } from '../store/solar';
import { authenticateDemandUser, DEMAND_APP_ID } from '../store/demand';
import { setUser } from '../store/user';

icons();
let didInit = false;
export const clipboard = new ClipboardJS('.copy-btn');

if (['staging', 'prod'].includes(process.env.REACT_APP_ENV)) {
  init({
    dsn: 'https://75d9350217c4a9c29705874e54e195da@o240785.ingest.us.sentry.io/4507725145964544',
    environment: process.env.REACT_APP_ENV,
    release: _package.version,
  });
}

function AppRoutes() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const handleRefreshTokens = async () => {
      const clients = getEnvUserPoolClients();
      try {
        for (const [clientId, refreshToken] of searchParams.entries()) {
          if (clientId in clients) {
            setTokens(clientId, { RefreshToken: refreshToken });
          }
        }
        let promises = [];
        for (const clientId of Object.keys(clients)) {
          const client = clients[clientId];
          if (client.appName === DEMAND_APP_ID) {
            promises.push(dispatch(authenticateDemandUser()));
          }
          if (client.appName === SOLAR_APP_ID) {
            promises.push(dispatch(authenticateSolarUser()));
          }
          // if (client.appName === 'management') {
          //   Promise.resolve(dispatch());
          // }
        }
        await Promise.all(promises);
        dispatch(setUser());
      } catch (err) {
        console.error(err);
        dispatch(push(ROUTES.LANDING.path));
      } finally {
        if (searchParams.size > 0) setSearchParams({}, { replace: true });
        dispatch(hideLoading());
      }
    };
    // React.Strict renders twice in dev
    // didInit avoids trying to authenticate twice
    if (!didInit) {
      didInit = true;
      dispatch(showLoading());
      handleRefreshTokens();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route path={ROUTES.LANDING.path} element={<Landing />} />
        <Route path={ROUTES.INDEX.path} element={<Layout />}>
          <Route
            path={ROUTES.INDEX.path}
            element={<Navigate to={ROUTES.HOME.path} />}
          />
          <Route path={ROUTES.HOME.path} element={<Home />} />
          <Route path={ROUTES.AWS.path} element={<AWS />} />
          <Route path={ROUTES.USERS.path} element={<Users />} />
          <Route path={ROUTES.LIGHTLEVEL.path} element={<LightLevel />} />
          <Route path={ROUTES.GRIDCAP.path} element={<Gridcap />} />
          <Route path={ROUTES.ENERGYTRACER.path} element={<EnergyTracer />} />
          <Route path={ROUTES.HUB.path} element={<></>} />
          <Route path={ROUTES.ENERGYTRACER.path} element={<></>} />
        </Route>
        <Route path={'*'} element={<Navigate to={ROUTES.INDEX.path} />} />
      </Routes>
    </div>
  );
}

export default AppRoutes;
