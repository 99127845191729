import React from 'react';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import MeterForm, { defaultMeterState } from '../../../Forms/solar/MeterForm';

const EndAdorment = (props) => {
  const { idx, setMeters } = props;

  const handleDeleteMeter = () => {
    setMeters((prevMeters) => {
      return prevMeters.filter((_, innerIdx) => {
        return innerIdx !== idx;
      });
    });
  };

  return (
    <InputAdornment position='end'>
      <IconButton onClick={handleDeleteMeter}>
        <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
      </IconButton>
    </InputAdornment>
  );
};

function MetersForms(props) {
  const { meters, setMeters, handleSubmit } = props;

  const handleAddMeter = () => {
    setMeters([...meters, { ...defaultMeterState, parent_index: 2 }]);
  };

  const handleChange = (e, idx) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setMeters((prevMeters) => {
      return prevMeters.map((meter, innerIdx) => {
        if (innerIdx === idx) {
          return { ...meter, [id]: value };
        }
        return meter;
      });
    });
  };

  return (
    <>
      {meters.length === 1 && (
        <Chip
          key='add-meter-chip'
          label='Add Meter'
          icon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
          onClick={handleAddMeter}
        />
      )}

      {map(meters, (meter, idx) => {
        return (
          <MeterForm
            key={idx + 'create-meter'}
            mode='create'
            meter={meter}
            handleChange={(e) => handleChange(e, idx)}
            handleSubmit={handleSubmit}
            endAdornment={
              idx === 1 && <EndAdorment idx={idx} setMeters={setMeters} />
            }
          />
        );
      })}
    </>
  );
}

export default MetersForms;
