import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'redux-first-history';

import { ROUTES } from '../../helpers/constants';

// import { authAPI as authManagementAPI } from '../../api/management';

const authenticateManagementUser = createAsyncThunk(
  'management/authenticateManagementUser',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      // const { user, memberships } = await authManagementAPI();

      // dispatch(getOrganizations(user));

      return { user: [], memberships: [] };
    } catch (err) {
      console.error('Authentication Error: ', err);
      dispatch(push(ROUTES.LANDING.path));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { authenticateManagementUser };
