import React from 'react';
import dayjs from 'dayjs';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

export const DateTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => {
        if (!value) return null;
        return dayjs(value).format('MMMM D, YYYY');
      }}
      {...props}
    />
  );
};
