import React from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import SelectRTO from '../../Selectors/SelectRTO';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultMeterState = {
  name: '',
  type_: 'meter',
  rto_id: '',
  area: '',
  capacity: '',
};

function MeterForm(props) {
  const {
    mode,
    meter,
    handleChange,
    handleSubmit = null,
    endAdornment = null,
  } = props;

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='meter_id'
              value={meter.meter_id}
              label='Meter ID'
            />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label='Meter Name'
          value={meter.name}
          onChange={handleChange}
          InputProps={{ endAdornment: endAdornment }}
        />
        <ListItem disableGutters>
          <SelectRTO rto_id={meter.rto_id} handleChange={handleChange} />
        </ListItem>
        <IntegerFieldListItem
          id='area'
          label='Area'
          value={meter.area || ''}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position='end'>ft²</InputAdornment>,
          }}
        />
        <IntegerFieldListItem
          id='capacity'
          label='Capacity'
          value={meter.capacity || ''}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
          }}
        />
      </List>
    </form>
  );
}

export default MeterForm;
