import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { SimpleTreeView } from '@mui/x-tree-view';

const Label = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledTreeItem = styled(TreeItem)(({ theme }) => {
  return {
    [`& .${treeItemClasses.root}`]: {},
    [`& .${treeItemClasses.groupTransition}`]: {},
    [`& .${treeItemClasses.content}`]: {
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: theme.spacing(1),
    },
    [`& .${treeItemClasses.expanded}`]: {},
  };
});

const CollapseIcon = () => {
  const theme = useTheme();
  return (
    <FontAwesomeIcon
      icon={['fal', 'caret-down']}
      color={theme.palette.text.secondary}
    />
  );
};

const ExpandIcon = () => {
  const theme = useTheme();
  return (
    <FontAwesomeIcon
      icon={['fal', 'caret-right']}
      color={theme.palette.text.secondary}
    />
  );
};

export default function ResourceTree(props) {
  const { nodes, path, createComponent = null } = props;
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState([]);

  const handleExpandedItemsChange = (e, itemIds) => {
    setExpanded(itemIds);
  };

  const handleClick = (e, treeId) => {
    e.stopPropagation();

    let itemId = treeId;
    if (includes(treeId, '.')) {
      itemId = treeId.substring(0, indexOf(treeId, '.'));
    }

    dispatch(push(path, { itemId }));
  };

  const mapNodes = (_nodes) => {
    return _nodes.map((node) => {
      const { children, itemId, icon, label, attr } = node;
      const _children = sortBy(children, [
        (child) => {
          return child.type.includes('-inverter');
        },
        (child) => {
          return child.type.includes('-sensor');
        },
        (child) => {
          return child.type.includes('-meter');
        },
      ]);

      return (
        <StyledTreeItem
          itemId={itemId}
          key={itemId}
          label={
            <Label>
              <ListItemText
                secondary={label}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                  maxWidth: '100%',
                }}
              />
              {attr && <Chip label={attr} sx={{ mr: 1 }} />}
              <IconButton
                onClick={(e) => handleClick(e, itemId)}
                sx={{ height: 30, width: 30, my: 1 }}>
                <FontAwesomeIcon icon={icon} size='1x' />
              </IconButton>
            </Label>
          }>
          {_children && mapNodes(_children)}
        </StyledTreeItem>
      );
    });
  };

  return (
    <Card raised sx={{ width: '100%', maxWidth: 400 }}>
      {createComponent}
      {createComponent && nodes.length > 0 && <Divider />}
      {nodes.length > 0 && (
        <SimpleTreeView
          slots={{ collapseIcon: CollapseIcon, expandIcon: ExpandIcon }}
          expandedItems={expanded}
          onExpandedItemsChange={handleExpandedItemsChange}>
          {mapNodes(nodes)}
        </SimpleTreeView>
      )}
    </Card>
  );
}
