import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import { putSolarOrganization, SOLAR_APP_ID } from '../store/solar';
import { DEMAND_APP_ID, putDemandOrganization } from '../store/demand';
import OrganizationForm from './Forms/OrganizationForm';

function OrganizationCard(props) {
  const { organization: _organization, appId } = props;
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(_organization);

  useEffect(() => {
    setOrganization(_organization);
  }, [_organization]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (appId === SOLAR_APP_ID) {
      dispatch(putSolarOrganization(organization));
    } else if (appId === DEMAND_APP_ID) {
      dispatch(putDemandOrganization(organization));
    }
  };

  return (
    <Card raised sx={{ width: 400, px: 2, py: 1 }}>
      <OrganizationForm
        mode='edit'
        appId={appId}
        organization={organization}
        setOrganization={setOrganization}
        handleSubmit={handleSubmit}
      />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant='contained' onClick={handleSubmit}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}

export default OrganizationCard;
