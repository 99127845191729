import React from 'react';
import get from 'lodash/get';

import MaskedInput from 'react-text-mask';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultLoggerState = {
  name: '',
  ip_address: '',
  ingestion_method: 'vpn',
  active: false,
  type_: 'logger',
};

function IPAddressMask(props) {
  const { onChange, value } = props;
  return (
    <MaskedInput
      onChange={onChange}
      guide={false}
      mask={(value) => Array(value.length).fill(/[\d.]/)}
      render={(ref, innerProps) => {
        return (
          <ClipboardField
            ref={ref}
            id='ip_address'
            label='IP Address'
            value={value}
            disableUnderline={false}
            readOnly={false}
            {...innerProps}
          />
        );
      }}
    />
  );
}

function LoggerForm(props) {
  const { mode, logger, setLogger, handleSubmit } = props;

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setLogger({ ...logger, [id]: value });
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='logger_id'
              value={logger.logger_id}
              label='Logger ID'
            />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label='Logger Name'
          value={logger.name}
          onChange={handleChange}
        />
        <ListItem disableGutters>
          <IPAddressMask
            fullWidth
            variant='standard'
            id='ip_address'
            label='IP Address'
            value={logger.ip_address}
            onChange={handleChange}
          />
        </ListItem>
        <ListItem disableGutters>
          <FormControl id='ingestion_method' variant='standard' fullWidth>
            <InputLabel id='ingestion_method-label'>
              Data Ingestion Method
            </InputLabel>
            <Select
              labelId='ingestion_method-label'
              name='ingestion_method'
              value={get(logger, 'ingestion_method', '')}
              onChange={handleChange}
              MenuProps={{
                MenuListProps: { disablePadding: true },
                slotProps: {
                  paper: { sx: { minWidth: 'unset !important' } },
                },
              }}
              sx={{
                backgroundColor: 'unset',
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}>
              {['vpn', 'public', 'api', 'demo'].map((method) => {
                return (
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton
            disableRipple
            role={undefined}
            onClick={(e) =>
              handleChange({ target: { id: 'active', value: !logger.active } })
            }
            dense>
            <ListItemIcon>
              <Checkbox edge='start' checked={logger.active} tabIndex={-1} />
            </ListItemIcon>
            <ListItemText primary={logger.active ? 'Active' : 'Inactive'} />
          </ListItemButton>
        </ListItem>
      </List>
    </form>
  );
}

export default LoggerForm;
