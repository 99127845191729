import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';

import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import LicensesTable from './Table/LicensesTable';
import SelectOrganization from './Selectors/SelectOrganization';

function LicensesGrid(props) {
  const { appId } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { organizations: allOrganizations } = useSelector(
    (state) => state[appId]
  );
  const [selectedOrgId, setSelectedOrgId] = useState('');
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const _organizations = allOrganizations.filter((org) => !org.is_portfolio);
    setOrganizations(sortBy(_organizations, 'name'));
  }, [allOrganizations]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid
        item
        xs={12}
        mx={1}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <Card raised sx={{ width: '100%', maxWidth: 400 }}>
          <SelectOrganization
            label='Select Organization'
            variant='filled'
            showAllOption
            organizations={organizations}
            orgId={selectedOrgId}
            setOrgId={(orgId) => setSelectedOrgId(orgId)}
          />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        px={1}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <LicensesTable orgId={selectedOrgId} appId={appId} />
      </Grid>
    </Grid>
  );
}

export default LicensesGrid;
