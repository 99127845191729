import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { toastr } from 'react-redux-toastr';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { openConfirmDialog } from '../../../../store/dialogs';
import BaseTable from '../../../../components/Table/BaseTable';
import HeaderEditCell from '../../../../components/Table/cells/HeaderEditCell';
import EditCell from '../../../../components/Table/cells/EditCell';
import { MembershipRoleTypeProvider } from '../../../../components/Table/providers';
import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';
import OrganizationUserDialog from '../../../../components/Dialogs/energytracer/OrganizationUserDialog';

export default function OrganizationUsers() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [open, setOpen] = useState(false);

  const [tableColumnExtensions] = useState([
    { columnName: 'active', align: 'center' },
  ]);
  const [integratedFilteringColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      predicate: (value, filter) => {
        return value.toLowerCase().includes(filter.value.toLowerCase());
      },
    },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    {
      columnName: 'organization_name',
      compare: (a, b) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
      },
    },
  ]);
  const [columns] = useState([
    { title: 'Organization', name: 'organization_name', width: 0.25 },
    { title: 'First Name', name: 'first_name', width: 0.125 },
    { title: 'Last Name', name: 'last_name', width: 0.125 },
    { title: 'Email', name: 'email', width: 0.35 },
    { title: 'Role', name: 'role', width: 0.15 },
  ]);

  const fetchData = useCallback(async () => {
    dispatch(showLoading());

    try {
      const users = await new WebAPIClient().GET('/profile/users');
      const organizations = await new WebAPIClient().GET(
        '/organizations/users'
      );

      let user_lookup = {};
      users.forEach((user) => (user_lookup[user.id] = user));

      let table_rows = [];
      organizations.forEach((org) => {
        org.users.forEach((org_user) => {
          let user = user_lookup[org_user.user_id];
          table_rows.push({
            organization_id: org.id,
            organization_name: org.name,
            user_id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            role: org_user.role,
          });
        });
      });
      setUsers(
        orderBy(users, ['email']).map((user) => {
          return { ...user, name: user.first_name + ' ' + user.last_name };
        })
      );
      setOrganizations(orderBy(organizations, 'name'));
      setTableRows(table_rows);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }

    dispatch(hideLoading());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteClick = (membership) => {
    const message = <p>Are you sure you want to delete this membership?</p>;
    dispatch(
      openConfirmDialog({
        title: 'Delete Membership',
        message: message,
        onConfirm: () => deleteMembership(membership),
      })
    );
  };

  const deleteMembership = async (membership) => {
    dispatch(showLoading());
    try {
      await new WebAPIClient().DELETE(
        `organizations/${membership.organization_id}/users/${membership.user_id}`
      );
      setTableRows((prev) =>
        prev.filter(
          (data) =>
            !(
              data.user_id === membership.user_id &&
              data.organization_id === membership.organization_id
            )
        )
      );
      dispatch(hideLoading());
      toastr.success('Membership deleted');
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleEditClick = (membership, value) => {
    const message = (
      <div>
        <p>Are you sure you want to update this membership?</p>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'Update Membership',
        message: message,
        onConfirm: () => editMembership(membership, value),
      })
    );
  };

  const editMembership = async (membership, value) => {
    dispatch(showLoading());
    try {
      await new WebAPIClient().POST(
        `organizations/${membership.organization_id}/users/${membership.user_id}`,
        { role: value }
      );
      setTableRows((prev) =>
        prev.map((data) => {
          if (
            data.user_id === membership.user_id &&
            data.organization_id === membership.organization_id
          )
            return { ...data, role: value };
          return data;
        })
      );
      dispatch(hideLoading());
      toastr.success('Membership Updated');
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      {' '}
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={isMobile ? 0 : 1}
        padding={isMobile ? 0 : 1}
        mt={0}
        px={isMobile ? 0 : 1}
        sx={{ maxWidth: '100%' }}>
        <Grid
          item
          xs={12}
          px={1}
          mt={isMobile ? 1 : 0}
          sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
          <Card raised sx={{ maxWidth: 1000, width: '100%', px: 1 }}>
            <BaseTable
              minWidth={1000}
              rows={tableRows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              editColumn={
                <TableEditColumn
                  width={42}
                  headerCellComponent={(props) => {
                    return (
                      <HeaderEditCell
                        disabled={false}
                        tooltipText='Create Membership'
                        handleClick={() => setOpen(true)}
                        {...props}
                      />
                    );
                  }}
                  cellComponent={(props) => (
                    <EditCell
                      disabled={false}
                      tooltipText='Delete Membership'
                      icon={['fal', 'trash']}
                      // eslint-disable-next-line
                      handleClick={() => handleDeleteClick(props.row)}
                      {...props}
                    />
                  )}
                />
              }>
              <MembershipRoleTypeProvider
                for={['role']}
                appId={'energytracer'}
                handleClick={handleEditClick}
              />
              <EditingState />
              <FilteringState />
              <SortingState
                defaultSorting={[
                  { columnName: 'organization_name', direction: 'asc' },
                ]}
              />
              <PagingState defaultCurrentPage={0} pageSize={10} />

              <IntegratedFiltering
                columnExtensions={integratedFilteringColumnExtensions}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <IntegratedPaging />
            </BaseTable>
          </Card>
          <OrganizationUserDialog
            users={users}
            organizations={organizations}
            setTableRows={setTableRows}
            open={open}
            setOpen={setOpen}
          />
        </Grid>
      </Grid>
    </>
  );
}
