import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ROLES } from '../../../helpers/constants';

function SelectEnergyTracerRole(props) {
  const { role, setRole } = props;

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const roles = ROLES['energytracer'];
  return (
    <FormControl fullWidth variant='standard'>
      <InputLabel id='role-label'>Role</InputLabel>
      <Select
        labelId='role-label'
        id='role'
        value={role}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}>
        {Object.keys(roles).map((roleName) => {
          const role = roles[roleName];
          return (
            <MenuItem
              key={roleName}
              id={`${roleName}-menu-item`}
              value={role.value}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', role.icon]} />
              </ListItemIcon>
              {roleName.toLowerCase()}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectEnergyTracerRole.propTypes = {
  appId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
};

export default SelectEnergyTracerRole;
