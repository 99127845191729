import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { CssBaseline, useMediaQuery } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { logoutUser } from '../../store/user/_users';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
}));

const Div = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6px',
  };
});

export default function Landing() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.item);
  const sm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleLogInClick = (e) => {
    e.preventDefault();
    const url = `${AUTH_URL}login?client_id=admin`;
    window.location.href = url;
  };

  return (
    <Root>
      <CssBaseline />
      <Card
        raised
        sx={(theme) => ({
          backgroundColor: theme.palette.background.auth,
        })}>
        <CardHeader
          disableTypography
          title={
            <Box
              component='img'
              sx={{
                height: sm ? 65 : 100,
                display: 'flex',
                p: 1,
              }}
              alt='Orchestrate by Veregy'
              src='/orchestrate-logo-white.png'
              title='Orchestrate'
            />
          }
        />
        {user ? (
          <CardActions
            sx={{
              '&.MuiCardActions-root': {
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                pr: 2,
                pb: 2,
                pt: 0,
              },
            }}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              onClick={(e) => {
                e.preventDefault();
                dispatch(push('/app'));
              }}
              sx={{ width: '40%' }}>
              Dashboard
            </Button>
            <Div>
              <Typography
                variant='body2'
                sx={{ mr: '4px' }}
                color={(theme) => theme.palette.text.auth}>
                Logged in as {user.email}
              </Typography>
              <Link
                underline='hover'
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(logoutUser());
                }}
                color={(theme) => theme.palette.text.auth}
                fontWeight='bold'
                variant='body2'>
                Log out
              </Link>
            </Div>
          </CardActions>
        ) : (
          <CardActions
            sx={{
              '&.MuiCardActions-root': {
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                pr: 2,
                pb: 2,
                pt: 0,
              },
            }}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              onClick={handleLogInClick}
              sx={{ width: '40%' }}
              size={sm ? 'large' : 'medium'}>
              Log in
            </Button>
          </CardActions>
        )}
      </Card>
    </Root>
  );
}
