import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectEnergyTracerOrganization(props) {
  const {
    orgId,
    setOrgId,
    organizations = [],
    portfolios = [],
    showAllOption = false,
    variant = 'standard',
    label = 'Organization',
    noneMessage = 'None Available',
  } = props;

  const handleChange = (event) => {
    setOrgId(event.target.value);
  };

  return (
    <FormControl fullWidth size='small' variant={variant}>
      <InputLabel id='organization-label'>{label}</InputLabel>
      <Select
        labelId='organization-label'
        disableUnderline={variant === 'filled'}
        id='organization'
        value={orgId}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
            sx: {
              '& .MuiPaper-root': {
                minWidth: 'unset',
              },
            },
          },
          slotProps: {
            paper: {
              sx: {
                minWidth: 'unset !important',
              },
            },
          },
        }}
        sx={{
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {showAllOption && (
          <MenuItem id={'empty-menu-item'} value=''>
            <ListItemText primary='Show All' />
          </MenuItem>
        )}
        {portfolios.length === 0 && organizations.length === 0 && (
          <MenuItem value='' disabled>
            {noneMessage}
          </MenuItem>
        )}
        {portfolios.length > 0 && <ListSubheader>Portfolios</ListSubheader>}
        {portfolios.length > 0 &&
          portfolios.map((portfolio) => (
            <MenuItem
              key={portfolio.id}
              id={`${portfolio.id}-menu-item`}
              value={portfolio.id}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', 'folder']} />
              </ListItemIcon>
              <ListItemText primary={portfolio.name} />
            </MenuItem>
          ))}
        {organizations.length > 0 && (
          <ListSubheader>Organizations</ListSubheader>
        )}
        {organizations.length > 0 &&
          organizations.map((organization) => (
            <MenuItem
              key={organization.id}
              id={`${organization.id}-menu-item`}
              value={organization.id}>
              <ListItemIcon>
                <FontAwesomeIcon icon={['fal', 'buildings']} />
              </ListItemIcon>
              <ListItemText primary={organization.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

SelectEnergyTracerOrganization.defaultProps = {
  organizations: [],
  portfolios: [],
};

SelectEnergyTracerOrganization.propTypes = {
  orgId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setOrgId: PropTypes.func.isRequired,
  organizations: PropTypes.array,
  portfolios: PropTypes.array,
  label: PropTypes.string,
  noneMessage: PropTypes.string,
};

export default SelectEnergyTracerOrganization;
