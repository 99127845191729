import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { getInitalCosts } from '../../../store/costs';
import { getSolarResources } from '../../../store/solar';
import { getDemandResources } from '../../../store/demand';
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog';
import CustomAppBar from './CustomAppBar';
import CustomDrawer from './Drawer';

const Root = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100vw',
});

function Layout() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const downSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const toolbarRef = useRef();
  const user = useSelector((state) => state.user.item);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getInitalCosts());
      dispatch(getSolarResources());
      dispatch(getDemandResources());
    }
  }, [user, dispatch]);

  // close mobile drawer if window exceeds breakpoint
  useEffect(() => {
    if (!isMobile) setMobileOpen(false);
  }, [isMobile]);

  const marginTop = downSM ? 7 : 8;
  return (
    <Root>
      <CssBaseline />
      <CustomAppBar ref={toolbarRef} setMobileOpen={setMobileOpen} />
      <CustomDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Box component='main' sx={{ flexGrow: 1, marginTop }}>
        <Outlet context={{ toolbarRef }} />
      </Box>
      <ConfirmDialog />
    </Root>
  );
}

export default Layout;
