import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import WebAPIClient from '../../../../../api';
import { DEMAND_APP_ID } from '../../../../../store/demand';
import { current, getLocalTimzone } from '../../../../../helpers/date';
import ModelingChartToolbar from './Toolbar';
import ModelingChart from './Chart';

const compileModelingData = (rawData, range) => {
  let _data = [];
  let time = range.start.unix();
  const initialData = cloneDeep(rawData);

  while (time <= range.end.unix()) {
    const values = reduce(
      initialData, // eslint-disable-next-line
      (acc, metric) => {
        let dataPoint = head(remove(metric.data, { timestamp: time }));
        if (dataPoint) {
          acc[metric.metric] = dataPoint.value;
        }
        return acc;
      },
      {}
    );
    _data.push({ timestamp: time, ...values });
    time += 300;
  }
  return _data;
};

function ModelingChartContainer() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const rtos = useSelector((state) => state.demand.rtos);

  const [rto, setRTO] = useState({});
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([
    'inst_loads',
    'frcst_inst_loads',
  ]);

  const [timezone, setTimezone] = useState(getLocalTimzone());
  const [range, setRange] = useState({
    key: 0,
    label: 'Current',
    ...current(timezone.offset),
  });

  useEffect(() => {
    setRTO(find(rtos, { rto_id: 'PJM' }));
  }, [rtos]);

  useEffect(() => {
    if (!loading && !isEmpty(rawData)) {
      const compiledData = compileModelingData(rawData, range);
      setChartData(compiledData);
    }
    /* eslint-disable-next-line */
  }, [rawData, range, loading]);

  useEffect(() => {
    if (!loading && isEmpty(rawData) && range.key !== '' && rto?.rto_id) {
      fetchChartData();
    }
    // eslint-disable-next-line
  }, [range, loading, rto]);

  const fetchChartData = (_range = range) => {
    setLoading(true);
    Promise.all(
      map(selectedMetrics, async (metric) => {
        return {
          metric,
          data: await new WebAPIClient(DEMAND_APP_ID).GET(
            `/resource/${metric}/${rto.rto_id}`,
            {
              start: _range.start.unix(),
              end: _range.end.unix(),
            }
          ),
        };
      })
    )
      .then((payloads) => {
        setRawData(payloads);
      })
      .catch((err) => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ModelingChartToolbar
        selectedMetrics={selectedMetrics}
        setSelectedMetrics={setSelectedMetrics}
        range={range}
        setRange={setRange}
        timezone={timezone}
        setTimezone={setTimezone}
        fetchChartData={fetchChartData}
      />
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        mb={2}
        ml={isMobile ? 0 : 1}
        mx={1}
        display='flex'
        justifyContent='center'>
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[8],
            p: 1,
            width: '100%',
            maxWidth: 1000,
          }}>
          <ModelingChart
            chartData={chartData}
            selectedMetrics={selectedMetrics}
            range={range}
            timezone={timezone}
            loading={loading}
          />
        </Box>
      </Grid>
    </>
  );
}

ModelingChartContainer.propTypes = {};

export default ModelingChartContainer;
