import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectPortfolios(props) {
  const { appId, portfolioIds, setPortfolioIds } = props;

  const organizations = useSelector((state) => state[appId].organizations);
  const [availablePortfolios, setAvailablePortfolios] = useState([]);

  useEffect(() => {
    const _portfolios = organizations.filter((org) => org.is_portfolio);
    setAvailablePortfolios(_portfolios);
  }, [organizations]);

  const handleChange = (event) => {
    setPortfolioIds(event.target.value);
  };

  return (
    <FormControl fullWidth variant='standard'>
      <InputLabel id='portfolios-label'>Portfolios</InputLabel>
      <Select
        labelId='portfolios-label'
        id='portfolios'
        multiple
        value={portfolioIds}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}>
        {availablePortfolios.map((portfolio) => (
          <MenuItem key={portfolio.org_id} value={portfolio.org_id}>
            {portfolio.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
