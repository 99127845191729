import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import find from 'lodash/find';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/material/styles/styled';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { ROUTES } from '../../../helpers/constants';
import { setDrawerExpanded } from '../../../store/app';
import { logoutUser } from '../../../store/user';

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => !['expanded', 'drawerWidth'].includes(prop),
})(({ expanded, theme, drawerWidth }) => ({
  boxShadow: 'unset',
  [theme.breakpoints.up('md')]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: expanded
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
}));

const DrawerToggleButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {},
  [theme.breakpoints.down('md')]: {
    marginLeft: '-4px',
  },
}));

const CustomAppBar = forwardRef((props, ref) => {
  const { setMobileOpen } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { drawerExpanded } = useSelector((state) => state.app);

  const toggleMenu = () => {
    if (isMobile) setMobileOpen((currentOpen) => !currentOpen);
    else dispatch(setDrawerExpanded(!drawerExpanded));
  };

  const handleLogOut = () => {
    dispatch(logoutUser());
  };

  const drawerWidth = drawerExpanded
    ? theme.drawer.width.expanded
    : theme.drawer.width.collapsed;
  const title = find(ROUTES, { path: pathname }).title;
  return (
    <StyledAppBar
      ref={ref}
      position='fixed'
      drawerWidth={drawerWidth}
      expanded={drawerExpanded}>
      <Toolbar>
        <DrawerToggleButton color='inherit' edge='start' onClick={toggleMenu}>
          <FontAwesomeIcon
            size='1x'
            icon={[
              'fal',
              !isMobile && drawerExpanded ? 'arrow-to-left' : 'bars',
            ]}
          />
        </DrawerToggleButton>
        <Typography variant='h6' noWrap sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <IconButton color='inherit' onClick={handleLogOut}>
          <FontAwesomeIcon size='1x' icon={['fal', 'sign-out']} />
        </IconButton>
        <IconButton
          color='inherit'
          href='https://support.veregy.com/section/107-admin-dashboard'>
          <FontAwesomeIcon icon={['fal', 'question-circle']} size='1x' />
        </IconButton>
      </Toolbar>
      <Divider />
    </StyledAppBar>
  );
});

export default CustomAppBar;
