import React, { useState } from 'react';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';

import InverterForm, {
  defaultInverterState,
} from '../../../Forms/solar/InverterForm';

const StartAdorment = (props) => {
  const { idx, setInverters } = props;

  const handleDeleteInverter = () => {
    setInverters((prevInverters) => {
      return prevInverters.filter((_, innerIdx) => {
        return innerIdx !== idx;
      });
    });
  };

  return (
    <InputAdornment position='start'>
      <IconButton onClick={handleDeleteInverter}>
        <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
      </IconButton>
    </InputAdornment>
  );
};

function InvertersForms(props) {
  const { inverters, setInverters, handleSubmit } = props;
  const [expandedInverter, setExpandedInverter] = useState(-1);

  const handleAddInverter = () => {
    const parentIndex = inverters.length + 1;
    setInverters([
      ...inverters,
      {
        ...defaultInverterState,
        name: `Inverter ${parentIndex}`,
        parent_index: parentIndex,
      },
    ]);
  };

  const handleSetExpanded = (idx) => {
    if (idx === expandedInverter) {
      setExpandedInverter(-1);
    } else {
      setExpandedInverter(idx);
    }
  };

  const handleChange = (e, idx) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setInverters((prevInverters) => {
      return prevInverters.map((inverter, innerIdx) => {
        if (innerIdx === idx) {
          return { ...inverter, [id]: value };
        }
        return inverter;
      });
    });
  };

  return (
    <>
      <Chip
        key='add-inverter-chip'
        label='Add Inverter'
        icon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
        onClick={handleAddInverter}
      />
      {map(inverters, (inverter, idx) => {
        return (
          <InverterForm
            key={idx + 'create-inverter'}
            mode='create'
            inverter={inverter}
            handleChange={(e) => handleChange(e, idx)}
            handleSubmit={handleSubmit}
            expanded={idx === expandedInverter}
            setExpanded={() => handleSetExpanded(idx)}
            startAdornment={
              idx === inverters.length - 1 && (
                <StartAdorment idx={idx} setInverters={setInverters} />
              )
            }
          />
        );
      })}
    </>
  );
}

export default InvertersForms;
