import React from 'react';
import ReactLoader from 'react-loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const DataCard = (props) => {
  const { loading, icon, value, label, color } = props;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 1,
        mx: '4px',
        px: '4px',
        minWidth: 140,
        minHeight: 140,
        bgcolor: color,
      }}>
      <FontAwesomeIcon icon={['fal', icon]} size='2x' color='white' />
      {loading ? (
        <ReactLoader
          type='bubbles'
          color='white'
          height={50}
          width={50}
          delay={250}
        />
      ) : (
        <Typography variant='h4' mt={1} color='white' fontWeight='bold'>
          {value}
        </Typography>
      )}

      <Typography variant='body2' color='white' align='center'>
        {label}
      </Typography>
    </Card>
  );
};

export default DataCard;
