import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { postSolarMembership, SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID, postDemandMembership } from '../../store/demand';
import SelectUser from '../Selectors/SelectUser';
import SelectRole from '../Selectors/SelectRole';
import SelectOrganization from '../Selectors/SelectOrganization';
import BaseDialog from './BaseDialog';

export const defaultState = {
  org_id: '',
  user_id: '',
  email: '',
  type_: '',
  role: '',
};

function MembershipDialog(props) {
  const { appId, orgId, open, setOpen } = props;
  const dispatch = useDispatch();
  const allOrganizations = useSelector((state) => state[appId].organizations);

  const [membership, setMembership] = useState(defaultState);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    let _organizations = filter(allOrganizations, { is_portfolio: false });
    _organizations = sortBy(_organizations, 'name');

    let _portfolios = filter(allOrganizations, { is_portfolio: true });
    _portfolios = sortBy(_portfolios, 'name');

    setOrganizations(_organizations);
    setPortfolios(_portfolios);
  }, [allOrganizations]);

  useEffect(() => {
    setMembership((prevMembership) => ({ ...prevMembership, org_id: orgId }));
  }, [orgId]);

  const handleSelectUser = (user) => {
    setMembership((prevMembership) => ({
      ...prevMembership,
      user_id: user?.user_id || '',
      email: user?.email || '',
      name: user?.name || '',
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setMembership({ ...defaultState, org_id: orgId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();
    if (appId === SOLAR_APP_ID) {
      dispatch(postSolarMembership(membership));
    } else if (appId === DEMAND_APP_ID) {
      dispatch(postDemandMembership(membership));
    }
  };

  const id = open ? 'create-membership-dialog' : '';
  return (
    <BaseDialog
      id={id}
      title='Create Membership'
      submitText='Create'
      disabled={false}
      handleClose={handleClose}
      handleSubmit={handleSubmit}>
      <List disablePadding>
        <ListItem disableGutters>
          <SelectOrganization
            label='Organization'
            organizations={organizations}
            portfolios={portfolios}
            orgId={membership.org_id}
            setOrgId={(orgId) =>
              setMembership({ ...membership, org_id: orgId })
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <SelectUser
            appId={appId}
            orgId={membership.org_id}
            userId={membership.user_id}
            setUser={handleSelectUser}
          />
        </ListItem>
        <ListItem disableGutters>
          <SelectRole
            appId={appId}
            email={membership.email}
            role={membership.role}
            setRole={(role) => setMembership({ ...membership, role: role })}
          />
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default MembershipDialog;
