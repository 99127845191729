import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import BaseSelect from '../../../../components/Selectors/BaseSelect';
import { TAG_OPTIONS } from './options';

const ApplyFilterDialog = (props) => {
  const {
    open,
    tag,
    setTag,
    value,
    setValue,
    valueOptions,
    handleClose,
    handleApplyFilter,
  } = props;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ pb: 1, pl: 2 }}>
        Apply Tag Filter
        <div style={{ position: 'absolute', top: 8, right: 8 }}>
          <IconButton
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500] }}>
            <FontAwesomeIcon icon={['fal', 'times']} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300, p: 2 }}>
        <BaseSelect
          key='select-tag'
          label='Tag'
          value={tag}
          setValue={setTag}
          options={TAG_OPTIONS}
          width='100%'
        />
        {tag !== '' && (
          <BaseSelect
            key='select-value'
            label='Value'
            value={value || '[none]'}
            setValue={setValue}
            options={valueOptions}
            width='100%'
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color='primary'
          variant='outlined'
          onClick={handleClose}
          size='medium'>
          Cancel
        </Button>
        <Button
          disabled={tag === ''}
          type='submit'
          color='primary'
          variant='contained'
          onClick={handleApplyFilter}
          size='medium'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyFilterDialog;
