import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';

import DialogTitleOptions from '../../../../components/Dialogs/DialogTitleOptions';
import AutocompleteListItem from '../../../../components/List/AutocompleteListItem';
import { TIMEZONES } from '../../../../components/Selectors/SelectTimezone';

function SelectMeter(props) {
  const { meter, setMeter, setTimezone } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const {
    organizations: allOrganizations,
    sites: allSites,
    meters: allMeters,
  } = useSelector((state) => state.solar);

  const [open, setOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState(null);
  const [meters, setMeters] = useState([]);

  useEffect(() => {
    let _organizations = [...allOrganizations];
    _organizations = filter(_organizations, { is_portfolio: false });
    setOrganizations(sortBy(_organizations, 'name'));
  }, [allOrganizations]);

  useEffect(() => {
    let _sites = [...allSites];
    _sites = filter(_sites, { org_id: organization?.org_id });
    setSites(sortBy(_sites, 'name'));
  }, [allSites, organization]);

  useEffect(() => {
    let _meters = [...allMeters];
    if (organization) {
      _meters = filter(_meters, { org_id: organization?.org_id });
    }
    if (site) {
      _meters = filter(_meters, { site_id: site?.site_id });
    }
    setMeters(sortBy(_meters, 'name'));
  }, [allMeters, organization, site]);

  const handleSelectOrganization = (e) => {
    let orgName;
    if (e.target.innerText) {
      orgName = e.target.innerText;
    } else if (e.target.value) {
      orgName = e.target.value;
    }

    const org = find(organizations, { name: orgName });
    setOrganization(org);
    setSite(null);
    setMeter(null);
    setTimezone(TIMEZONES[0]);
  };

  const handleSelectSite = (e) => {
    let siteName;
    if (e.target.innerText) {
      siteName = e.target.innerText;
    } else if (e.target.value) {
      siteName = e.target.value;
    }

    const site = find(sites, { name: siteName });
    setSite(site);
    setMeter(null);
    setTimezone(TIMEZONES[0]);
  };

  const handleSelectMeter = (e) => {
    let meterName;
    if (e.target.innerText) {
      meterName = e.target.innerText;
      const _meter = find(meters, { name: meterName });

      let timezone = {};
      const _site = find(allSites, { site_id: _meter.site_id });
      if (_site.timezone) timezone = _site.timezone;
      else timezone = organization.timezone;

      setMeter(_meter);
      setTimezone(timezone);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonText = (() => {
    if (!meter) return 'Select Meter';
    let text = '';
    if (organization) text += organization.name + ' - ';
    if (site) text += site.name + ' - ';
    text += meter.name;
    return text;
  })();
  return (
    <>
      <Button color='primary' onClick={() => setOpen(true)}>
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
        <DialogTitle>
          Select Meter
          <DialogTitleOptions handleClose={handleClose} />
        </DialogTitle>
        <DialogContent sx={isMobile ? {} : { width: '100%', minWidth: 450 }}>
          <List disablePadding>
            <AutocompleteListItem
              id='org_id'
              label='Organization'
              value={organization?.name || ''}
              options={organizations.map((org) => org.name)}
              onChange={handleSelectOrganization}
            />
            <AutocompleteListItem
              id='site_id'
              label='Site'
              value={site?.name || ''}
              options={sites.map((site) => site.name)}
              onChange={handleSelectSite}
            />
            <AutocompleteListItem
              id='meter_id'
              label='Meter'
              value={meter?.name || ''}
              options={meters.map((meter) => meter.name)}
              onChange={handleSelectMeter}
            />
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

SelectMeter.propTypes = {
  meter: PropTypes.object,
  setMeter: PropTypes.func,
  setTimezone: PropTypes.func,
};

export default SelectMeter;
