import get from 'lodash/get';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';

const calculateAverageAppCost = (costs, appId) => {
  let total = 0;
  let dayCount = 0;
  costs.forEach((dayCosts) => {
    let appGroups = dayCosts.Groups.filter((group) =>
      includes(group.Keys, `app$${appId}`)
    );
    let dayTotal = reduce(
      appGroups,
      (acc, group) => {
        return (
          acc +
          reduce(
            Object.entries(group.Metrics),
            (acc, metric) => {
              let amount = get(metric, '1.Amount', '0');
              return acc + parseFloat(amount);
            },
            0
          )
        );
      },
      0
    );

    total += dayTotal;
    dayCount += 1;
  });
  if (dayCount > 0) {
    return total / dayCount;
  }
  return 0;
};

const renderLatLngValue = (value, defaultValue = '') => {
  if (!value) return defaultValue;
  return value.toFixed(2) + '°';
};

export { calculateAverageAppCost, renderLatLngValue };
