import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { filter, find, get, includes, map, sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

import { useSelector } from 'react-redux';

function MeterFilterButton(props) {
  const {
    selectedOrganizationId,
    setSelectedOrganizationId,
    selectedSiteId,
    setSelectedSiteId,
  } = props;
  const { organizations, portfolioMemberships, sites } = useSelector(
    (state) => state.demand
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeOrganization = (e) => {
    setSelectedOrganizationId(e.target.value);
    setSelectedSiteId('');
  };

  const handleChangeSite = (e) => {
    setSelectedSiteId(e.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-meter-filter' : undefined;

  const siteOptions = (() => {
    if (!selectedOrganizationId) {
      return sites;
    }
    let organization = find(organizations, { org_id: selectedOrganizationId });
    if (!get(organization, 'is_portfolio')) {
      return filter(sites, { org_id: selectedOrganizationId });
    } else {
      let memberOrgIds = map(
        filter(portfolioMemberships, {
          portfolio_id: selectedOrganizationId,
        }),
        (portfolioMembership) => get(portfolioMembership, 'member_id')
      );
      return filter(sites, (site) => {
        return includes(memberOrgIds, get(site, 'org_id'));
      });
    }
  })();

  let _organizations = sortBy(organizations, 'name');
  let _sites = sortBy(siteOptions, 'name');

  return (
    <>
      <Tooltip title={'filter meters'}>
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ width: 35, height: 35, mb: '8px !important' }}>
          <FontAwesomeIcon icon={['fal', 'filter']} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}>
        <List disablePadding>
          <ListItem
            key='organization-select-list-item'
            sx={{ pt: selectedOrganizationId ? 1 : 0 }}>
            <FormControl
              variant='standard'
              size='small'
              fullWidth
              sx={{ minWidth: 150 }}>
              <InputLabel id='org-select-label'>Organization</InputLabel>
              <Select
                disableUnderline
                labelId='org-select-label'
                id='organization-select'
                value={selectedOrganizationId}
                onChange={handleChangeOrganization}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}>
                <MenuItem value={''}>All</MenuItem>
                {map(_organizations, (organization) => {
                  return (
                    <MenuItem
                      key={organization.org_id}
                      value={organization.org_id}>
                      {organization?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem key='site-select-list-item'>
            <FormControl variant='standard' size='small' fullWidth>
              <InputLabel id='site-select-label'>Site</InputLabel>
              <Select
                disableUnderline
                labelId='site-select-label'
                id='site-select'
                value={selectedSiteId}
                onChange={handleChangeSite}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}>
                <MenuItem value={''}>All</MenuItem>
                {map(_sites, (site) => {
                  return (
                    <MenuItem key={site.site_id} value={site.site_id}>
                      {site?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

MeterFilterButton.propTypes = {
  selectedOrganizationId: PropTypes.string,
  setSelectedOrganizationId: PropTypes.func,
  selectedSiteId: PropTypes.string,
  setSelectedSiteId: PropTypes.func,
};

export default MeterFilterButton;
