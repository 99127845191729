import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';

import Card from '@mui/material/Card';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';

import { openConfirmDialog } from '../../store/dialogs';
import { putSolarLicense, SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID, putDemandLicense } from '../../store/demand';
import useOrgNamePredicate from '../../store/hooks/useOrgNamePredicate';
import useCompareOrgName from '../../store/hooks/useCompareOrgName';
import BaseTable from './BaseTable';
import {
  DateTypeProvider,
  OrganizationIdNameTypeProvider,
  SelectTypeProvider,
} from './providers';

const MESSAGES = {
  null: (
    <>
      <p>
        Are you sure you want to set the activation status for this license to
        <strong> auto</strong>?
      </p>
      <p>
        The activation status will be determined by the Stripe subscription
        associated with this organization.
      </p>
    </>
  ),
  true: (
    <p>
      Are you sure you want to <strong> activate</strong> this license?
    </p>
  ),
  false: (
    <p>
      Are you sure you want to <strong> deactivate</strong> this license?
    </p>
  ),
};

function LicensesTable(props) {
  const { orgId, appId } = props;
  const dispatch = useDispatch();
  const { licenses: allLicenses, organizations } = useSelector(
    (state) => state[appId]
  );

  const orgNamePredicate = useOrgNamePredicate(appId);
  const compareOrgName = useCompareOrgName(appId);

  const [tableColumnExtensions] = useState([
    { columnName: 'active', align: 'center' },
  ]);
  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'org_id', predicate: orgNamePredicate },
  ]);
  const [integratedSortingColumnExtensions] = useState([
    { columnName: 'org_id', compare: compareOrgName },
  ]);
  const [licenses, setLicenses] = useState([]);

  const [columns] = useState([
    { title: 'Status', name: 'active', width: 0.1 },
    { title: 'Organization', name: 'org_id', width: 0.5 },
    { title: 'License', name: 'name', width: 0.15 },
    { title: 'End Date', name: 'end_date', width: 0.2 },
  ]);

  useEffect(() => {
    let _licenses = allLicenses;
    if (orgId) {
      _licenses = filter(_licenses, { org_id: orgId });
    }
    if (_licenses) {
      setLicenses(_licenses);
    }
  }, [allLicenses, orgId]);

  const handleToggleForceActive = (license, value) => {
    const message = MESSAGES[value];
    dispatch(
      openConfirmDialog({
        title: 'Update License',
        message,
        onConfirm: () => {
          if (appId === SOLAR_APP_ID) {
            dispatch(putSolarLicense({ ...license, force_active: value }));
          } else if (appId === DEMAND_APP_ID) {
            dispatch(putDemandLicense({ ...license, force_active: value }));
          }
        },
      })
    );
  };

  return (
    <Card raised sx={{ maxWidth: 900, width: '100%', px: 1 }}>
      <BaseTable
        minWidth={900}
        rows={licenses}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}>
        <OrganizationIdNameTypeProvider
          for={['org_id']}
          organizations={organizations}
        />
        <SelectTypeProvider
          key='active'
          for={['active']}
          handleClick={handleToggleForceActive}
        />
        <DateTypeProvider for={['end_date']} />

        <EditingState />
        <FilteringState />
        <SortingState
          defaultSorting={[{ columnName: 'org_id', direction: 'asc' }]}
        />
        <PagingState defaultCurrentPage={0} pageSize={10} />

        <IntegratedFiltering
          columnExtensions={integratedFilteringColumnExtensions}
        />
        <IntegratedSorting
          columnExtensions={integratedSortingColumnExtensions}
        />
        <IntegratedPaging />
      </BaseTable>
    </Card>
  );
}

export default LicensesTable;
