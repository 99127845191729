import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import isIP from 'validator/lib/isIP';

import Divider from '@mui/material/Divider';

import { postSolarLogger } from '../../../store/solar';
import useOrganization from '../../../store/hooks/useOrganization';
import useSite from '../../../store/hooks/useSite';
import LoggerForm, { defaultLoggerState } from '../../Forms/solar/LoggerForm';
import ResourceListItem from '../../List/ResourceListItem';
import BaseDialog from '../BaseDialog';

function LoggerDialog(props) {
  const { open, closeDialog, orgId, siteId } = props;
  const dispatch = useDispatch();
  const organization = useOrganization(orgId, 'solar');
  const site = useSite(siteId, 'solar');

  const [logger, setLogger] = useState(defaultLoggerState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setLogger((prevLogger) => ({
      ...prevLogger,
      org_id: orgId,
      site_id: siteId,
    }));
  }, [orgId, siteId]);

  useEffect(() => {
    const validIp = isIP(logger.ip_address);
    if (logger.name.length === 0) {
      setMessage('Logger name required');
    } else if (!validIp) {
      setMessage('Invalid IP Address');
    } else {
      setMessage('');
    }
  }, [logger]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning(message);
    } else {
      dispatch(postSolarLogger(logger));
      handleClose();
    }
  };

  const handleClose = () => {
    closeDialog();
    setLogger({
      ...defaultLoggerState,
      org_id: orgId,
      site_id: siteId,
    });
  };

  const id = open ? 'create-logger-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Logger'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <ResourceListItem
        label='Organization'
        name={organization.name}
        onClick={null}
        icon='buildings'
      />
      <ResourceListItem
        label='Site'
        name={site.name}
        onClick={null}
        icon='building'
      />
      <Divider />
      <LoggerForm
        mode='create'
        logger={logger}
        setLogger={setLogger}
        handleSubmit={handleSubmit}
      />
    </BaseDialog>
  );
}

export default LoggerDialog;
