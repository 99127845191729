import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const EditCell = ({
  disabled = true,
  row,
  tooltipText,
  handleClick,
  icon,
  ...props
}) => {
  return (
    <TableEditColumn.Cell {...props} style={{ padding: 1 }}>
      <Tooltip
        placement='left'
        title={disabled ? 'Insufficient Permissions' : tooltipText}>
        <span>
          <IconButton
            onClick={handleClick}
            disabled={disabled}
            sx={{ right: 4 }}>
            <FontAwesomeIcon size='sm' icon={icon ? icon : ['fal', 'edit']} />
          </IconButton>
        </span>
      </Tooltip>
    </TableEditColumn.Cell>
  );
};

export default EditCell;
