import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { numberToCurrency2 } from '../../../helpers/currency';
import { calculateAverageAppCost } from '../../../helpers';
import IconImg from './IconImg';
import DataCard from './DataCard';
import SwipeableContainer from '../../../components/SwipeableContainer';
import { SOLAR_APP_ID } from '../../../store/solar';

export default function LightLevelGrid() {
  const theme = useTheme();
  const { loading, alarms, loggers, users } = useSelector(
    (state) => state.solar
  );
  const { initial: costs, loading: costsLoading } = useSelector(
    (state) => state.costs
  );

  const [connections, setConnections] = useState([]);
  const [activeAlarms, setActiveAlarms] = useState([]);
  const [monthlyActiveUsers, setMonthlyActiveUsers] = useState([]);
  const [avgCost, setAvgCost] = useState(0);

  useEffect(() => {
    setConnections(loggers.filter((logger) => logger.online));
  }, [loggers]);

  useEffect(() => {
    setActiveAlarms(alarms.filter((alarm) => alarm.status));
  }, [alarms]);

  useEffect(() => {
    const thirtyDaysAgo = dayjs().subtract(30, 'day');
    const _users = users.filter(
      (user) => dayjs(user.last_update) > thirtyDaysAgo
    );
    setMonthlyActiveUsers(_users);
  }, [users]);

  useEffect(() => {
    setAvgCost(calculateAverageAppCost(costs, SOLAR_APP_ID));
  }, [costs]);

  return (
    <Grid item md={12} sx={{ maxWidth: '100vw' }}>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <IconImg iconName='lightlevel' height={45} label='LightLevel' />
        <Typography variant='h4' ml={2}>
          LightLevel
        </Typography>
      </Box>
      <SwipeableContainer>
        <DataCard
          loading={loading}
          icon='router'
          value={connections.length}
          label='Active Connections'
          color={theme.palette.veregy_colors.grey}
        />
        <DataCard
          loading={loading}
          icon='bell-on'
          value={activeAlarms.length}
          label='Active Alarms'
          color={theme.palette.veregy_colors.orange}
        />
        <DataCard
          loading={loading}
          icon='users'
          value={monthlyActiveUsers.length}
          label='Monthly Active Users'
          color={theme.palette.veregy_colors.blue}
        />
        <DataCard
          loading={costsLoading}
          icon='dollar-sign'
          value={numberToCurrency2.format(avgCost)}
          label='Avg Daily Cost'
          color={theme.palette.veregy_colors.green}
        />
      </SwipeableContainer>
    </Grid>
  );
}
