import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MuiGrid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {
  SortingState,
  IntegratedSorting,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableInlineCellEditing,
  TableHeaderRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { putNiagara } from '../../../../../store/demand';
import Header from '../../../../../components/Table/Header';
import EditCell from '../../../../../components/Table/cells/EditCell';
import HeaderEditCell from '../../../../../components/Table/cells/HeaderEditCell';

const FocusableCell = ({ onClick, ...restProps }) => (
  <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />
);
FocusableCell.propTypes = {
  onClick: PropTypes.func,
};

function NiagaraDevicesTable({ niagara }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [changed, setChanged] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [startEditAction] = useState('doubleClick');
  const [selectTextOnEditStart] = useState(true);
  const [columns] = useState([
    { title: 'Host ID', name: 'host_id' },
    { title: 'Name', name: 'name' },
    { title: 'Type', name: 'type' },
  ]);

  useEffect(() => {
    if (niagara.devices) {
      setTableData(map(niagara.devices, (v, k) => ({ ...v, host_id: k })));
    }
  }, [niagara]);

  const handleCreateClick = () => {
    setTableData([
      ...tableData,
      { host_id: 'Win-', name: '', type: 'Niagara-Supervisor' },
    ]);
    setChanged(true);
  };

  const handleDeleteClick = (row) => {
    setTableData(tableData.filter((_, index) => index !== row.tableRow.rowId));
    setChanged(true);
  };

  const handleClear = () => {
    setTableData(map(niagara.devices, (v, k) => ({ ...v, host_id: k })));
    setChanged(false);
  };

  const handleSubmit = () => {
    let devices = {};
    tableData.forEach((device) => {
      devices[device.host_id] = device;
    });

    dispatch(
      putNiagara({
        niagara_id: niagara.niagara_id,
        org_id: niagara.org_id,
        devices,
      })
    );

    setChanged(false);
  };

  const commitChanges = ({ changed }) => {
    let changedRows;
    if (changed) {
      setChanged(true);
      changedRows = tableData.map((row, idx) =>
        changed[idx] ? { ...row, ...changed[idx] } : row
      );
    }
    setTableData(changedRows);
  };

  return (
    <MuiGrid
      item
      xs={12}
      mt={isMobile ? 1 : 0}
      px={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100vw',
      }}>
      <Card raised sx={{ width: 750, px: 2, py: 1 }}>
        <CardHeader
          title='Niagara Devices'
          sx={{ pt: 0.5, pb: 0 }}
          action={
            <Stack direction='row' spacing={1}>
              <Button
                disabled={!changed}
                onClick={handleClear}
                variant='outlined'>
                Clear
              </Button>
              <Button
                disabled={!changed}
                color='primary'
                variant='contained'
                onClick={handleSubmit}>
                Submit
              </Button>
            </Stack>
          }
        />
        <Grid rows={tableData} columns={columns}>
          <EditingState onCommitChanges={commitChanges} />
          <SortingState />
          <IntegratedSorting columnExtensions={[]} />
          <Table cellComponent={FocusableCell} />
          <TableHeaderRow contentComponent={Header} showSortingControls />
          <TableInlineCellEditing
            startEditAction={startEditAction}
            selectTextOnEditStart={selectTextOnEditStart}
          />
          <TableEditColumn
            width={42}
            showEditCommand
            cellComponent={(props) => (
              <EditCell
                disabled={false}
                tooltipText='Delete Device'
                // eslint-disable-next-line
                handleClick={() => handleDeleteClick(props)}
                icon={['fal', 'trash']}
                {...props}
              />
            )}
            headerCellComponent={(props) => {
              return (
                <HeaderEditCell
                  disabled={false}
                  tooltipText='Create Device'
                  handleClick={handleCreateClick}
                  {...props}
                />
              );
            }}
          />
        </Grid>
      </Card>
    </MuiGrid>
  );
}

NiagaraDevicesTable.propTypes = {
  niagara: PropTypes.object,
};

export default NiagaraDevicesTable;
