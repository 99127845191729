import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectSensor(props) {
  const { orgId, sensorId, handleSelect } = props;
  const { meters, sensors: allSensors } = useSelector((state) => state.solar);

  const [sensors, setSensors] = useState([]);

  useEffect(() => {
    setSensors(filter(allSensors, { org_id: orgId }));
  }, [orgId, allSensors]);

  return (
    <FormControl id='sensor' variant='standard' fullWidth>
      <InputLabel id='sensor-label'>Sensor</InputLabel>
      <Select
        labelId='sensor-label'
        value={sensors.length ? sensorId : ''}
        onChange={handleSelect}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}
        sx={{
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem value=''>
          <ListItemText
            primary='None'
            primaryTypographyProps={{
              variant: 'body1',
              color: 'text.secondary',
            }}
          />
        </MenuItem>
        {map(sortBy(sensors, 'name'), (sensor) => {
          let meter = find(meters, { meter_id: sensor.meter_id });
          return (
            <MenuItem key={sensor.sensor_id} value={sensor.sensor_id}>
              <ListItemText
                primary={meter.name + ' - ' + sensor.name}
                primaryTypographyProps={{
                  variant: 'body1',
                  color: 'text.secondary',
                }}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectSensor.propTypes = {
  sensorId: PropTypes.string,
  sensors: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default SelectSensor;
