import React, { useState } from 'react';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { renderLatLngValue } from '../../helpers';
import { TextFieldListItem } from '../List/TextFieldListItem';
import AddressField from '../AddressField';

function AddressTimezoneListItems(props) {
  const { mode, resource, setResource } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddressSelect = (address) => {
    setResource((item) => ({
      ...item,
      address: address.formatted_address,
      location: {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng(),
      },
    }));
  };

  const handleAddressChange = (e) => {
    setResource((item) => ({ ...item, address: e.target.value }));
  };

  return (
    <>
      <ListItem disableGutters>
        <AddressField
          id='address'
          address={resource.address}
          onAddressSelect={handleAddressSelect}
          handleAddressChange={handleAddressChange}
        />
      </ListItem>
      {mode === 'edit' && (
        <TextFieldListItem
          label='Timezone'
          value={get(resource, 'timezone.zone', '')}
          sx={{ pb: 0 }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleClick}>
                  <FontAwesomeIcon
                    icon={['fal', `chevron-${open ? 'up' : 'down'}`]}
                    size='sm'
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List disablePadding>
          <ListItem
            disableGutters
            sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              disabled
              label='Timezone Abbreviation'
              value={get(resource, 'timezone.name', '')}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{ disableUnderline: true }}
            />
            <TextField
              disabled
              label='Timezone Offset'
              value={get(resource, 'timezone.offset', 0)}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{ disableUnderline: true }}
            />
          </ListItem>
          <ListItem
            disableGutters
            sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              label='Latitude'
              disabled
              value={renderLatLngValue(get(resource, 'location.lat', ''))}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{ disableUnderline: true }}
            />
            <TextField
              label='Longitude'
              disabled
              value={renderLatLngValue(get(resource, 'location.lng', ''))}
              variant='standard'
              sx={{ width: '45%' }}
              InputProps={{ disableUnderline: true }}
            />
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}

export default AddressTimezoneListItems;
