import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import { ROUTES } from '../../../helpers/constants';
import TabBar from '../../../components/Tab/TabBar';
import KPIs from './KPIs';
import Licenses from './Licenses';
import OrganizationUsers from './OrganizationUsers';
import PortfolioUsers from './PortfolioUsers';
import PortfolioOrganizations from './PortfolioOrganizations';
import Tools from './Tools';

const KPIS_TAB = 'kpis';
const LICENSES_TAB = 'licenses';
const ORGANIZATION_USERS_TAB = 'organization_users';
const PORTFOLIO_USERS_TAB = 'portfolio_users';
const PORTFOLIO_ORGANIZATIONS_TAB = 'portfolio_organizations';
const TOOLS_TAB = 'tools';
const TABS = [
  KPIS_TAB,
  LICENSES_TAB,
  ORGANIZATION_USERS_TAB,
  PORTFOLIO_USERS_TAB,
  PORTFOLIO_ORGANIZATIONS_TAB,
  TOOLS_TAB,
];

export default function EnergyTracer() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(KPIS_TAB);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(KPIS_TAB);
    }
  }, [location.hash]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.ENERGYTRACER.path + '#' + value));
  };

  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <KPIs tab={KPIS_TAB} />
      <Licenses tab={LICENSES_TAB} />
      <OrganizationUsers tab={ORGANIZATION_USERS_TAB} />
      <PortfolioUsers tab={PORTFOLIO_USERS_TAB} />
      <PortfolioOrganizations tab={PORTFOLIO_ORGANIZATIONS_TAB} />
      <Tools tab={TOOLS_TAB} />
    </TabBar>
  );
}
