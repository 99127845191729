import React, { forwardRef, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import {
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  selectDay,
  getLocalTimzone,
} from '../../../../helpers/date';
import BaseSelect from '../../../../components/Selectors/BaseSelect';
import SwipeableContainer from '../../../../components/SwipeableContainer';
import {
  DIMENSION_OPTIONS,
  GRANULARITY_OPTIONS,
  METRIC_OPTIONS,
  TAG_OPTIONS,
} from './options';
import SelectRange from '../../../../components/Selectors/SelectRange';

const RANGES = [
  {
    key: 0,
    label: 'This Week',
    func: thisWeek,
  },
  {
    key: 1,
    label: 'Last Week',
    func: lastWeek,
  },
  {
    key: 2,
    label: 'This Month',
    func: thisMonth,
  },
  {
    key: 3,
    label: 'Last Month',
    func: lastMonth,
  },
  {
    key: 4,
    label: 'Custom Range',
    func: selectDay,
  },
];

const CostsChartOptions = forwardRef((props, ref) => {
  const {
    range,
    handleRangeSelect,
    granularity,
    handleSetGranularity,
    metric,
    handleSelectMetric,
    groupByType,
    groupByKey,
    handleChangeGroupBy,
  } = props;

  const [timezone] = useState(getLocalTimzone());
  const [dimension, setDimension] = useState('');
  const [tag, setTag] = useState('app');

  useEffect(() => {
    if (groupByType === 'TAG') {
      setDimension('TAG');
    } else {
      setDimension(groupByKey);
    }
  }, [groupByType, groupByKey]);

  const handleDimensionChange = (value) => {
    if (value === 'TAG') {
      handleChangeGroupBy('TAG', TAG_OPTIONS[0]);
    } else {
      handleChangeGroupBy('DIMENSION', value);
    }
  };

  const handleTagChange = (value) => {
    handleChangeGroupBy('TAG', value);
    setTag(value);
  };

  return (
    <Card ref={ref} raised sx={{ py: 0.5, px: 1 }}>
      <SwipeableContainer>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          divider={
            <Divider orientation='vertical' flexItem sx={{ m: 0.5, mx: 2 }} />
          }>
          <SelectRange
            ranges={RANGES}
            range={range}
            defaultRange={RANGES[5]}
            setRange={handleRangeSelect}
            timezone={timezone}
          />
          <BaseSelect
            key='select-granularity'
            label='Granularity'
            value={granularity}
            setValue={handleSetGranularity}
            options={GRANULARITY_OPTIONS}
          />
          <BaseSelect
            key='select-metric'
            label='Metric'
            value={metric}
            setValue={handleSelectMetric}
            options={METRIC_OPTIONS}
          />
          <BaseSelect
            key='select-dimension'
            label='Dimension'
            value={dimension}
            setValue={handleDimensionChange}
            options={DIMENSION_OPTIONS}
          />
          {dimension === 'TAG' && (
            <BaseSelect
              key='select-tag'
              label='Tag'
              value={tag}
              setValue={handleTagChange}
              options={TAG_OPTIONS}
            />
          )}
        </Stack>
      </SwipeableContainer>
    </Card>
  );
});

export default CostsChartOptions;
