import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import startCase from 'lodash/startCase';
import isEmail from 'validator/es/lib/isEmail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import {
  deleteSolarUser,
  postSolarUser,
  putSolarUser,
  generateSolarAPIKey,
  SOLAR_APP_ID,
} from '../../../store/solar';
import SelectDefaultOrganization from '../../Selectors/SelectDefaultOrganization';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import { getUserStatusColor } from '../../Table/providers/UserStatusTypeProvider';
import BaseDialog from '../BaseDialog';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultState = {
  user_id: '',
  name: '',
  email: '',
  account_status: '',
  default_organization: '',
  api: {},
  sign_in_count: 0,
};

function SolarUserDialog(props) {
  const { mode, setMode, selectedUser, setSelectedUser } = props;
  const dispatch = useDispatch();

  const [user, setUser] = useState(defaultState);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  const handleClose = () => {
    setSelectedUser(defaultState);
    setMode(null);
  };

  const handleChange = (e) => {
    setUser((user) => ({ ...user, [e.target.id]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();
    if (mode === 'create') {
      dispatch(
        postSolarUser({
          email: user.email.toLowerCase(),
          name: user.name,
          super_user: user.super_user,
          default_organization: user.default_organization,
        })
      );
    }
    if (mode === 'edit') {
      dispatch(putSolarUser(user));
    }
  };

  const handleDelete = () => {
    handleClose();
    dispatch(deleteSolarUser(user.user_id));
  };

  const handleGenerateAPIKey = () => {
    handleClose();
    dispatch(generateSolarAPIKey(user.user_id));
  };

  const disabled = (() => {
    if (mode === 'create') {
      return !isEmail(user.email) || user.name === '';
    }
    return false;
  })();

  const titleOptions = [
    mode === 'edit' && !user?.api?.key && (
      <MenuItem key='generate-api-key-button' onClick={handleGenerateAPIKey}>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fal', 'key']} />
        </ListItemIcon>
        <ListItemText primary='Generate API Key' />
      </MenuItem>
    ),
    mode === 'edit' && (
      <MenuItem key='delete-button' onClick={handleDelete}>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fal', 'trash']} />
        </ListItemIcon>
        <ListItemText primary='Delete' />
      </MenuItem>
    ),
  ].filter(Boolean);

  const submitText = mode === 'create' ? 'Create' : 'Save';
  const userId = mode === 'create' ? 'create-user-dialog' : user.user_id;
  return (
    <BaseDialog
      id={userId}
      title={`${startCase(mode)} User`}
      submitText={submitText}
      disabled={disabled}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      titleOptions={titleOptions}>
      <List disablePadding>
        {/* USER ID */}
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField id='user_id' value={user.user_id} label='UserID' />
          </ListItem>
        )}
        {/* EMAIL */}
        <TextFieldListItem
          required={mode === 'create'}
          id='email'
          label='Email'
          value={user.email}
          InputProps={{
            readOnly: mode === 'edit',
            disableUnderline: mode === 'edit',
          }}
          onChange={mode !== 'edit' ? handleChange : null}
        />
        {/* NAME */}
        <TextFieldListItem
          required={mode === 'create'}
          id='name'
          label='Name'
          value={user.name}
          onChange={handleChange}
        />
        {/* DEFAULT ORGANIZATION */}
        <ListItem disableGutters>
          <SelectDefaultOrganization
            appId={SOLAR_APP_ID}
            mode={mode}
            userId={user?.user_id}
            orgId={user?.default_organization}
            setOrgId={(orgId) =>
              setUser((user) => ({
                ...user,
                default_organization: orgId,
              }))
            }
          />
        </ListItem>
        {/* API KEY */}
        {mode === 'edit' && user?.api?.key && (
          <ListItem disableGutters>
            <ClipboardField id='api.key' value={user.api.key} label='API Key' />
          </ListItem>
        )}
        {/* ACCOUNT STATUS */}
        {mode === 'edit' && (
          <ListItem
            disableGutters
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <ListItemText secondary='Cognito Status' />
            <Chip
              label={user.account_status}
              color={getUserStatusColor(user.account_status)}
            />
          </ListItem>
        )}
        {/* SIGN IN COUNT */}
        {mode === 'edit' && (
          <TextFieldListItem
            id='sign_in_count'
            label='Sign in Count'
            value={user.sign_in_count}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        )}
      </List>
    </BaseDialog>
  );
}

export default SolarUserDialog;
