import dayjs from 'dayjs';

const CSV_PAYLOAD_OPTS = {
  headers: {
    Accept: 'application/vnd.openxmlformats-',
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

export const REPORT_OPTIONS = {
  license: {
    header: 'License Report',
    opts: CSV_PAYLOAD_OPTS,
    fileExtension: 'xlsx',
  },
  user: {
    header: 'User Report',
    opts: CSV_PAYLOAD_OPTS,
    fileExtension: 'xlsx',
  },
  provider_breakdown: {
    header: 'Provider Breakdown Report',
    opts: CSV_PAYLOAD_OPTS,
    fileExtension: 'xlsx',
  },
  provider_organization: {
    header: 'Provider Organization Report',
    opts: CSV_PAYLOAD_OPTS,
    fileExtension: 'xlsx',
  },
};

export const downloadEnergyTracerFile = (response, report) => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const fileName = generateSimpleFilename(report);

  const old_link = document.getElementById('report-download-link');
  if (old_link) {
    old_link.remove();
  }
  const link = document.createElement('a');
  link.href = url;
  link.id = 'report-download-link';
  link.target = '_blank';
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export function generateSimpleFilename(report) {
  return [
    'EnergyTracer ' +
      report['header'] +
      ' - ' +
      dayjs.utc(new Date()).format('MMM-DD-YYYY'),
    report['fileExtension'],
  ].join('.');
}
