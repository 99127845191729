import { useSelector } from 'react-redux';
import find from 'lodash/find';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

const useOrgNamePredicate = (appId) => {
  const organizations = useSelector((state) => state[appId].organizations);

  return (value, filter) => {
    const organization = find(organizations, { org_id: value });
    return includes(toLower(organization?.name), toLower(filter.value));
  };
};

export default useOrgNamePredicate;
