const GRANULARITY_OPTIONS = ['DAILY', 'MONTHLY'];
const METRIC_OPTIONS = [
  'AmortizedCost',
  'BlendedCost',
  'NetAmortizedCost',
  'NetUnblendedCost',
  'NormalizedUsageAmount',
  'UnblendedCost',
  'UsageQuantity',
];
const DIMENSION_OPTIONS = [
  'None',
  'AZ',
  'SERVICE',
  'OPERATION',
  'TAG',
  'USAGE_TYPE',
];
const TAG_OPTIONS = ['app', 'env', 'stack'];
const TAG_VALUE_OPTIONS = {
  app: [
    '',
    'admin',
    'demand',
    'empowered',
    'energytracer',
    'enpowered',
    'excel-addin',
    'gridcap',
    'management',
    'orchestrate',
    'orchestrate-auth',
    'other',
    'solar',
    'tech-service',
  ],
  env: ['', 'dev', 'staging', 'prod'],
  stack: [
    '',
    'alarm',
    'auth',
    'bills',
    'eclypse',
    'gats',
    'kpi',
    'licenses',
    'listener',
    'misc',
    'pjm',
    'plug',
    'primary',
    'public',
    'public_api',
    'regression',
    'reporting',
    'rto',
    'services',
    'solar',
    'summary',
    'urjanet',
    'util',
    'utility_cloud',
    'weather',
    'web',
  ],
};

export {
  GRANULARITY_OPTIONS,
  METRIC_OPTIONS,
  DIMENSION_OPTIONS,
  TAG_OPTIONS,
  TAG_VALUE_OPTIONS,
};
