import React from 'react';
import PropTypes from 'prop-types';

import styled from '@mui/material/styles/styled';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function MenuItemListItem(props) {
  const { value } = props;
  return (
    <ListItemText
      primary={value}
      primaryTypographyProps={{ align: 'center' }}
    />
  );
}

MenuItemListItem.propTypes = {
  value: PropTypes.string,
};

export { MenuItemListItem, StyledMenuItem };
