const details = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const dialogs = {
  confirm: { title: '', message: '', onConfirm: null },
};

export const solar = {
  user: null,
  alarms: [],
  memberships: [],
  organizations: [],
  portfolioMemberships: [],
  sites: [],
  loggers: [],
  meters: [],
  inverters: [],
  sensors: [],
  users: [],
  ...details,
};

export const demand = {
  user: { item: null },
  alarms: [],
  memberships: [],
  niagaras: [],
  organizations: [],
  portfolioMemberships: [],
  licenses: [],
  sites: [],
  meters: [],
  rtos: [],
  users: [],
  ...details,
};

export const management = {
  user: { item: null },
  memberships: [],
  organizations: [],
  ...details,
};

export const user = {
  item: null,
  ...details,
};

export const costs = {
  initial: [],
  ...details,
};

export const cognito = {
  users: [],
  ...details,
};
