import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import { openConfirmDialog } from '../../store/dialogs';
import {
  deleteDemandPortfolioMembership,
  DEMAND_APP_ID,
} from '../../store/demand';
import {
  deleteSolarPortfolioMembership,
  SOLAR_APP_ID,
} from '../../store/solar';
import BaseTable from './BaseTable';
import EditCell from './cells/EditCell';
import HeaderEditCell from './cells/HeaderEditCell';
import WidgetLoader from '../Loaders/WidgetLoader';
import { OrganizationIdNameTypeProvider } from './providers';
import PortfolioMembershipDialog from '../Dialogs/PortfolioMembershipDialog';

function PortfolioMembershipsTable(props) {
  const { portfolio, appId } = props;
  const dispatch = useDispatch();
  const {
    loading,
    portfolioMemberships: allPortfolioMemberships,
    organizations,
  } = useSelector((state) => state[appId]);

  const [open, setOpen] = useState(false);
  const [portfolioMemberships, setPortfolioMemberships] = useState([]);

  const [columns] = useState([
    { title: 'Child Organization', name: 'member_id', width: 0.95 },
  ]);

  useEffect(() => {
    let _portfolioMemberships = allPortfolioMemberships;
    if (portfolio.org_id) {
      _portfolioMemberships = filter(_portfolioMemberships, {
        portfolio_id: portfolio.org_id,
      });
    }

    setPortfolioMemberships(_portfolioMemberships);
  }, [portfolio, allPortfolioMemberships]);

  const handleDeleteClick = (portfolioMembership) => {
    const message = (
      <p>Are you sure you want to delete this portfolio membership?</p>
    );
    dispatch(
      openConfirmDialog({
        title: 'Delete Portfolio Membership',
        message: message,
        onConfirm: () => {
          if (appId === SOLAR_APP_ID) {
            dispatch(deleteSolarPortfolioMembership(portfolioMembership));
          } else if (appId === DEMAND_APP_ID) {
            dispatch(deleteDemandPortfolioMembership(portfolioMembership));
          }
        },
      })
    );
  };

  return (
    <>
      <Card raised sx={{ maxWidth: 500, width: '100%', px: 1 }}>
        <WidgetLoader loading={loading} />
        <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
          <BaseTable
            maxWidth={400}
            minWidth={350}
            rows={portfolioMemberships}
            columns={columns}
            editColumn={
              <TableEditColumn
                showEditCommand
                width={42}
                headerCellComponent={(props) => {
                  return (
                    <HeaderEditCell
                      disabled={false}
                      tooltipText='Create Portfolio Membership'
                      handleClick={() => setOpen(true)}
                      {...props}
                    />
                  );
                }}
                cellComponent={(props) => (
                  <EditCell
                    disabled={false}
                    tooltipText='Delete Portfolio Membership'
                    icon={['fal', 'trash']}
                    // eslint-disable-next-line
                    handleClick={() => handleDeleteClick(props.row)}
                    {...props}
                  />
                )}
              />
            }>
            <OrganizationIdNameTypeProvider
              for={['portfolio_id', 'member_id']}
              organizations={organizations}
            />

            <EditingState />
            <FilteringState />
            <SortingState
              defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
            />
            <PagingState defaultCurrentPage={0} pageSize={10} />

            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
          </BaseTable>
        </CardContent>
      </Card>
      <PortfolioMembershipDialog
        appId={appId}
        portfolioId={portfolio.org_id}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default PortfolioMembershipsTable;
