import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID } from '../../store/demand';

function SelectResource(props) {
  const { appId, resourceIds, selectedResourceId, setSelectedResourceId } =
    props;
  const theme = useTheme();
  const sitesState = useSelector((state) => {
    if (appId === SOLAR_APP_ID) {
      return state.solar.sites;
    }
    if (appId === DEMAND_APP_ID) {
      return state.demand.sites;
    }
    return [];
  });
  const orgsState = useSelector((state) => {
    if (appId === SOLAR_APP_ID) {
      return state.solar.organizations;
    }
    if (appId === DEMAND_APP_ID) {
      return state.demand.organizations;
    }
    return [];
  });

  const [sites, setSites] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    let uniqSiteIds = new Set();
    let uniqOrgIds = new Set();
    resourceIds.forEach((resourceId) => {
      if (resourceId.startsWith('site')) {
        uniqSiteIds.add(resourceId);
      } else if (resourceId.startsWith('org')) {
        uniqOrgIds.add(resourceId);
      }
    });

    const _sites = Array.from(uniqSiteIds).map((siteId) => {
      return find(sitesState, { site_id: siteId });
    });
    const _organizations = Array.from(uniqOrgIds).map((orgId) => {
      return find(orgsState, { org_id: orgId });
    });

    setSites(_sites);
    setOrganizations(_organizations);
  }, [resourceIds, sitesState, orgsState]);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedResourceId(e.target.value);
  };

  const resourceName = (resourceId) => {
    if (resourceId === '') {
      return 'All';
    }
    if (resourceId.startsWith('org')) {
      return find(organizations, { org_id: resourceId }).name;
    }
    if (resourceId.startsWith('site')) {
      return find(sites, { site_id: resourceId }).name;
    }
    return 'Resource Name';
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='select-resource-label'
        sx={{ textAlign: 'center', width: '8rem' }}>
        Select Resource
      </InputLabel>
      <Select
        disableUnderline
        labelId='select-resource-label'
        name='select-resource'
        value={selectedResourceId || 'all'}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={resourceName(selectedResourceId)}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        sx={{
          minWidth: 100,
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem
          key='all'
          value=''
          sx={{
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          }}>
          All
        </MenuItem>
        {organizations.length > 0 && (
          <ListSubheader sx={{ color: 'grey', lineHeight: 'unset' }}>
            Organizations
          </ListSubheader>
        )}
        {organizations.map((organization) => {
          return (
            <MenuItem
              key={organization?.org_id}
              value={organization?.org_id}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {organization?.name}
            </MenuItem>
          );
        })}
        {sites.length > 0 && (
          <ListSubheader sx={{ color: 'grey', lineHeight: 'unset' }}>
            Sites
          </ListSubheader>
        )}
        {sites.map((site) => {
          return (
            <MenuItem
              key={site?.site_id}
              value={site?.site_id}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {site?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectResource.propTypes = {
  appId: PropTypes.string,
  resourceIds: PropTypes.array,
  selectedResourceId: PropTypes.string,
  setSelectedResourceId: PropTypes.func,
};

export default SelectResource;
