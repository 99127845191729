import React, { useState } from 'react';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Chip from '@mui/material/Chip';

import SensorForm, {
  defaultSensorState,
} from '../../../Forms/solar/SensorForm';

const StartAdorment = (props) => {
  const { idx, setSensors } = props;

  const handleDeleteSensor = () => {
    setSensors((prevSensors) => {
      return prevSensors.filter((_, innerIdx) => {
        return innerIdx !== idx;
      });
    });
  };

  return (
    <InputAdornment position='start'>
      <IconButton onClick={handleDeleteSensor}>
        <FontAwesomeIcon icon={['fal', 'trash']} size='sm' />
      </IconButton>
    </InputAdornment>
  );
};

function SensorsForms(props) {
  const { sensors, setSensors, handleSubmit } = props;
  const [expandedSensor, setExpandedSensor] = useState(-1);

  const handleAddSensor = () => {
    const parentIndex = sensors.length + 1;
    setSensors([
      ...sensors,
      {
        ...defaultSensorState,
        name: `Sensor ${parentIndex}`,
        parent_index: parentIndex,
      },
    ]);
  };

  const handleSetExpanded = (idx) => {
    if (idx === expandedSensor) {
      setExpandedSensor(-1);
    } else {
      setExpandedSensor(idx);
    }
  };

  const handleChange = (e, idx) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setSensors((prevSensors) => {
      return prevSensors.map((sensor, innerIdx) => {
        if (innerIdx === idx) {
          return { ...sensor, [id]: value };
        }
        return sensor;
      });
    });
  };

  return (
    <>
      {sensors.length < 2 && (
        <Chip
          key='add-sensor-chip'
          label='Add Sensor'
          icon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
          onClick={handleAddSensor}
        />
      )}

      {map(sensors, (sensor, idx) => {
        return (
          <SensorForm
            key={idx + 'create-sensor'}
            mode='create'
            sensor={sensor}
            handleChange={(e) => handleChange(e, idx)}
            handleSubmit={handleSubmit}
            expanded={idx === expandedSensor}
            setExpanded={() => handleSetExpanded(idx)}
            startAdornment={
              idx === sensors.length - 1 && (
                <StartAdorment idx={idx} setSensors={setSensors} />
              )
            }
          />
        );
      })}
    </>
  );
}

export default SensorsForms;
