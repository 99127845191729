import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import LightLevelGrid from './LightLevelGrid';
import GridcapGrid from './GridcapGrid';
import HubGrid from './HubGrid';
import EnergyTracerGrid from './EnergyTracerGrid';

export default function Home() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={isMobile ? 1 : 0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <LightLevelGrid />
      <GridcapGrid />
      <HubGrid />
      <EnergyTracerGrid />
    </Grid>
  );
}
