import React from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

const HeaderEditCell = ({
  disabled = true,
  tooltipText,
  handleClick,
  ...props
}) => {
  return (
    <TableEditColumn.HeaderCell {...props} style={{ padding: 1 }}>
      <Tooltip
        placement='top'
        title={disabled ? 'Insufficient Permissions' : tooltipText}>
        <span>
          <IconButton
            aria-label='create'
            onClick={handleClick}
            disabled={disabled}
            sx={{ right: 4 }}>
            <FontAwesomeIcon size='sm' icon={['fal', 'plus-circle']} />
          </IconButton>
        </span>
      </Tooltip>
    </TableEditColumn.HeaderCell>
  );
};

export default HeaderEditCell;
