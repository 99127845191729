import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { user as initialState } from '../initialState';

import { setUser, logoutUser } from './_users';

const { reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [setUser, logoutUser]);
  },
});

export { setUser, logoutUser };
export default reducer;
