import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import { ROUTES } from '../../../helpers/constants';
import generateNodes from '../../../helpers/demandTree';
import { DEMAND_APP_ID } from '../../../store/demand';
import TabBar from '../../../components/Tab/TabBar';
import LicensesGrid from '../../../components/LicensesGrid';
import MembershipsGrid from '../../../components/MembershipsGrid';
import UsersGrid from '../../../components/UsersGrid';
import KioskUsage from '../../../components/KioskUsage';
import ResourcesPage from '../ResourcesPage';
import KPIs from './KPIs';
import PJMMarkets from './PJMMarkets';
import LoadShed from './LoadShed';

const RESOURCES = 'resources';
const LICENSES = 'licenses';
const USERS = 'users';
const MEMBERSHIPS = 'memberships';
const LOAD_SHED = 'load-shed';
const KPIS = 'kpis';
const MARKETS = 'markets';
const KIOSK_USAGE = 'kiosk-usage';
const TABS = [
  RESOURCES,
  LICENSES,
  USERS,
  MEMBERSHIPS,
  LOAD_SHED,
  KPIS,
  MARKETS,
  KIOSK_USAGE,
];

export default function Gridcap() {
  const dispatch = useDispatch();
  const location = useLocation();

  const demandState = useSelector((state) => state.demand);
  const [selectedTab, setSelectedTab] = useState(RESOURCES);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(RESOURCES);
    }
  }, [location.hash]);

  useEffect(() => {
    if (!demandState.loading) {
      setNodes(generateNodes(demandState));
    }
  }, [demandState]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.GRIDCAP.path + '#' + value));
  };

  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <ResourcesPage
        tab={RESOURCES}
        appId={DEMAND_APP_ID}
        nodes={nodes}
        path={ROUTES.GRIDCAP.path}
      />
      <LicensesGrid tab={LICENSES} appId={DEMAND_APP_ID} />
      <UsersGrid tab={USERS} appId={DEMAND_APP_ID} />
      <MembershipsGrid tab={MEMBERSHIPS} appId={DEMAND_APP_ID} />
      <LoadShed tab={LOAD_SHED} />
      <KPIs tab={KPIS} />
      <PJMMarkets tab={MARKETS} />
      <KioskUsage tab={KIOSK_USAGE} appId={DEMAND_APP_ID} />
    </TabBar>
  );
}
