import traverse from 'traverse';

import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqueId from 'lodash/uniqueId';

const generatePortfolioTree = (
  portfolio,
  portfolioMemberships,
  organizations,
  sites,
  meters
) => {
  const childRelations = filter(portfolioMemberships, {
    portfolio_id: portfolio.org_id,
  });
  const childOrgs = [];

  each(childRelations, (relation) => {
    let organization = find(organizations, { org_id: relation.member_id });
    if (organization) childOrgs.push(organization);
  });

  return {
    type: 'tree-node-parent',
    resourceType: 'portfolio',
    label: get(portfolio, 'name'),
    itemId: get(portfolio, 'org_id'),
    hide: false,
    icon: ['fal', 'folder'],
    attr: String(childOrgs.length),
    children: childOrgs.map((org) => generateOrgTree(org, sites, meters)),
  };
};

const generateOrgTree = (organization, sites, meters) => {
  const orgSites = filter(sites, { org_id: organization.org_id });
  const children = map(orgSites, (site) => generateSiteTree(site, meters));
  return {
    type: 'tree-node-organization',
    label: organization.name,
    resourceType: 'organization',
    itemId: organization.org_id,
    icon: ['fal', 'buildings'],
    attr: String(orgSites.length),
    children,
  };
};

const generateSiteTree = (site, meters) => {
  const siteMeters = filter(meters, { site_id: site.site_id });
  return {
    type: 'tree-node-site',
    label: site.name,
    resourceType: 'site',
    itemId: site.site_id,
    icon: ['fal', 'building'],
    attr: String(siteMeters.length),
    children: map(sortBy(siteMeters, 'parent_index'), (meter) => ({
      type: 'tree-node-meter',
      label: meter.name,
      resourceType: 'meter',
      itemId: meter.meter_id,
      icon: ['fal', 'bolt'],
      attr: meter.capacity ? `${meter.capacity} kW` : null,
      children: [],
    })),
  };
};

const generateNodes = (state) => {
  const { organizations, portfolioMemberships, sites, meters } = state;

  let nodes = [];

  const portfolios = sortBy(
    filter(organizations, { is_portfolio: true }),
    'name'
  );

  if (portfolios.length > 0) {
    nodes = map(portfolios, (portfolio) =>
      generatePortfolioTree(
        portfolio,
        portfolioMemberships,
        organizations,
        sites,
        meters
      )
    );
  } else {
    nodes = map(organizations, (organization) =>
      generateOrgTree(organization, sites, meters)
    );
  }

  nodes = traverse(nodes).map(function (node) {
    if (this.notLeaf && !isArray(node)) {
      node.level = this.level;
      node.id = node.itemId;
      node.itemId = node.itemId + '.' + uniqueId();
    }
    if (node?.children) {
      node.children = orderBy(node.children, ['type', 'label']);
    }
    return node;
  });

  return nodes;
};

export default generateNodes;
export { generatePortfolioTree, generateOrgTree, generateSiteTree };
