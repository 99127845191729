import React from 'react';

import Grid from '@mui/material/Grid';

import ResourcesGrid from '../../components/ResourcesGrid';

function AllResources(props) {
  const { nodes, path, appId } = props;

  return (
    <Grid
      item
      xs={12}
      mt={0}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100vw',
      }}>
      <ResourcesGrid appId={appId} nodes={nodes} path={path} />
    </Grid>
  );
}

export default AllResources;
