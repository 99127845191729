import React from 'react';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ROUTES } from '../../../../helpers/constants';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...theme.mixins.toolbar, // necessary for content to be below app bar
}));

const Img = (props) => {
  const { routes, height } = props;
  const { pathname } = useLocation();
  const route = routes[0];

  const onPage = routes.map((route) => route.path).includes(pathname);

  return (
    <Box
      component='img'
      height={height}
      alt={`${route.title} Icon`}
      title={`${route.title} Icon`}
      src={
        onPage
          ? `/icons${route.path}-icon-white.png`
          : `/icons${route.path}-icon.png`
      }
    />
  );
};

const DrawerItem = (props) => {
  const { expanded, icon, text, paths } = props;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const onPage = paths.includes(pathname);

  const bgcolor = onPage ? 'primary.main' : 'unset';
  const textColor = onPage ? 'black' : 'grey';
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        onClick={() => dispatch(push(paths[0]))}
        sx={{
          justifyContent: expanded ? 'initial' : 'center',
        }}>
        <Card
          sx={{
            p: 1,
            minHeight: 45,
            minWidth: 45,
            height: 45,
            width: 45,
            bgcolor,
            mr: expanded ? 3 : 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <ListItemIcon
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {icon}
          </ListItemIcon>
        </Card>
        {expanded && (
          <ListItemText
            primary={text}
            primaryTypographyProps={{ color: textColor }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

function DrawerContent() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { drawerExpanded } = useSelector((state) => state.app);

  return (
    <Root>
      <DrawerHeader>
        {(isMobile || !drawerExpanded) && (
          <Box
            onClick={() => dispatch(push('/'))}
            height={35}
            component='img'
            alt='Orchestrate Icon'
            src='/icons/hub-icon.png'
            title='Orchestrate Icon'
          />
        )}
        {!isMobile && drawerExpanded && (
          <Box
            onClick={() => dispatch(push('/'))}
            height={27}
            component='img'
            alt='Orchestrate By Veregy'
            src='/orchestrate-logo.png'
            title='Orchestrate By Veregy'
          />
        )}
      </DrawerHeader>
      <Divider />
      <List disablePadding>
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.HOME.title}
          icon={
            <FontAwesomeIcon
              color={
                [ROUTES.INDEX.path, ROUTES.HOME.path].includes(
                  location.pathname
                )
                  ? 'white'
                  : 'inherit'
              }
              icon={['fal', 'home']}
              size='xl'
            />
          }
          paths={[ROUTES.INDEX.path, ROUTES.HOME.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.AWS.title}
          icon={
            <FontAwesomeIcon
              color={
                location.pathname === ROUTES.AWS.path ? 'white' : 'inherit'
              }
              icon={['fab', 'aws']}
              size='xl'
            />
          }
          paths={[ROUTES.AWS.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.USERS.title}
          icon={
            <FontAwesomeIcon
              color={
                location.pathname === ROUTES.USERS.path ? 'white' : 'inherit'
              }
              icon={['fal', 'users']}
              size='xl'
            />
          }
          paths={[ROUTES.USERS.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.LIGHTLEVEL.title}
          icon={<Img height={30} routes={[ROUTES.LIGHTLEVEL]} />}
          paths={[ROUTES.LIGHTLEVEL.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.GRIDCAP.title}
          icon={<Img height={25} routes={[ROUTES.GRIDCAP]} />}
          paths={[ROUTES.GRIDCAP.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.HUB.title}
          icon={<Img height={30} routes={[ROUTES.HUB]} />}
          paths={[ROUTES.HUB.path]}
        />
        <DrawerItem
          expanded={drawerExpanded || isMobile}
          text={ROUTES.ENERGYTRACER.title}
          icon={<Img height={25} routes={[ROUTES.ENERGYTRACER]} />}
          paths={[ROUTES.ENERGYTRACER.path]}
        />
      </List>
    </Root>
  );
}

export default DrawerContent;
