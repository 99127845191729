import React from 'react';

import { SOLAR_APP_ID } from '../../../../store/solar';
import WebAPIClient from '../../../../api';
import KPIsPage from '../../../../components/KPIsPage';

export default function KPIs() {
  const solarKpiMetrics = {
    alarms: [
      { label: 'All Alarms', metric: 'alarms', color: 'blue' },
      {
        label: 'Lost Communication Alarms',
        metric: 'lost_comms_alarms',
        color: 'orange',
      },
      {
        label: 'No Production Alarms',
        metric: 'no_prod_alarms',
        color: 'red',
      },
    ],
    resources: [
      { label: 'Portfolios', metric: 'portfolios', color: 'purple' },
      {
        label: 'Organizations',
        metric: 'organizations',
        color: 'blue',
      },
      { label: 'Sites', metric: 'sites', color: 'orange' },
      { label: 'Meters', metric: 'meters', color: 'lightgrey' },
      { label: 'Connections', metric: 'connections', color: 'green' },
      { label: 'Inverters', metric: 'inverters', color: 'orange' },
      { label: 'Sensors', metric: 'sensors', color: 'blue' },
    ],
    users: [
      { label: 'Users', metric: 'users', color: 'blue' },
      {
        label: 'Monthly Active Users',
        metric: 'monthly_active_users',
        color: 'orange',
      },
      {
        label: 'Daily Active Users',
        metric: 'daily_active_users',
        color: 'green',
      },
    ],
  };

  const fetchData = async (start, end, selectedMetrics) => {
    return await new WebAPIClient(SOLAR_APP_ID).GET('/resource/kpis', {
      start,
      end,
      metrics: JSON.stringify(selectedMetrics),
    });
  };

  return <KPIsPage metrics={solarKpiMetrics} fetchData={fetchData} />;
}
