import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { putCognitoUser, resendConfirmationEmail } from '../../store/cognito';
import { getUserStatusColor } from '../Table/providers';
import { TextFieldListItem } from '../List/TextFieldListItem';
import BaseDialog from './BaseDialog';
import ClipboardField from '../Buttons/ClipboardField';

export const defaultState = {
  Username: '',
  name: '',
  email: '',
  UserStatus: '',
  'custom:SuperUser': 'false',
  email_verified: 'false',
  sub: '',
};

function CognitoUserDialog(props) {
  const { handleClose, selectedUser } = props;
  const dispatch = useDispatch();

  const appUser = useSelector((state) => state.user.item);
  const [user, setUser] = useState(defaultState);

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  const handleChange = (e) => {
    setUser((prevUser) => ({ ...prevUser, [e.target.id]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();
    dispatch(putCognitoUser(user));
  };

  const handleResendEmail = (e) => {
    e.preventDefault();
    handleClose();
    dispatch(resendConfirmationEmail(user));
  };

  const titleOptions = [
    (user.UserStatus === 'FORCE_CHANGE_PASSWORD' ||
      user.UserStatus === 'UNCONFIRMED') && (
      <MenuItem key='resend-verification-email' onClick={handleResendEmail}>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fal', 'user-lock']} />
        </ListItemIcon>
        <ListItemText primary='Resend Verification Email' />
      </MenuItem>
    ),
  ].filter(Boolean);
  return (
    <BaseDialog
      id={user.sub}
      title='Update User'
      disabled={false}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      titleOptions={titleOptions}>
      <form onSubmit={handleSubmit}>
        <List disablePadding>
          {/* USER ID */}
          <ListItem disableGutters>
            <ClipboardField
              id='username'
              value={user.Username}
              label='Username (sub)'
            />
          </ListItem>
          {/* EMAIL */}
          <TextFieldListItem
            id='email'
            label='Email'
            value={user.email}
            InputProps={{ readOnly: true }}
          />
          {/* NAME */}
          <TextFieldListItem
            id='name'
            label='Name'
            value={user.name}
            onChange={handleChange}
          />
          {/* ACCOUNT STATUS */}
          <ListItem
            disableGutters
            sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <ListItemText secondary='Cognito Status' />
            <Chip
              label={user.UserStatus}
              color={getUserStatusColor(user.UserStatus)}
            />
          </ListItem>
          {/* SUPER USER */}
          <ListItem disableGutters>
            <FormControlLabel
              control={
                <Checkbox
                  id='super_user'
                  disabled={appUser?.sub === user.Username}
                  checked={user['custom:SuperUser'] === 'true'}
                  onChange={() => {
                    setUser((user) => ({
                      ...user,
                      'custom:SuperUser':
                        user['custom:SuperUser'] === 'true' ? 'false' : 'true',
                    }));
                  }}
                />
              }
              label='custom:SuperUser'
            />
          </ListItem>

          {/* EMAIL VERIFIED */}
          <ListItem disableGutters>
            <FormControlLabel
              control={
                <Checkbox
                  id='email_verified'
                  disabled={user.email_verified === 'true'}
                  checked={user.email_verified === 'true'}
                  onChange={() => {
                    setUser((prevUser) => ({
                      ...prevUser,
                      email_verified: 'true',
                    }));
                  }}
                />
              }
              label='Email Verified'
            />
          </ListItem>
        </List>
      </form>
    </BaseDialog>
  );
}

export default CognitoUserDialog;
