import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import UsersTable from './UsersTable';
import { getCognitoUsers } from '../../../store/cognito';

export default function Users() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(getCognitoUsers());
  }, [dispatch]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid
        item
        xs={12}
        px={1}
        mt={isMobile ? 1 : 0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        <UsersTable />
      </Grid>
    </Grid>
  );
}
