import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';

import BaseTooltip from '../charts/tooltips/BaseTooltip';

function Tooltip(props) {
  const { active, payload, day, label } = props;
  const dateStr = (() => {
    if (day) return day?.format('MMMM Do');
    if (label) return dayjs(Number(label * 1000)).format('MMMM Do');
    return null;
  })();

  return (
    <BaseTooltip
      label={dateStr}
      active={active}
      payload={payload}
      getName={(resource) => startCase(resource?.name)}
      getValue={(resource) => resource?.value}
    />
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  day: PropTypes.object,
  label: PropTypes.number,
};

export default Tooltip;
