import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import get from 'lodash/get';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { unixTimestampToString } from '../../../../../helpers/date';
import { StyledTd } from '../../../../../components/charts/tooltips/BaseTooltip';

function Tooltip(props) {
  const { active, payload, label, timezone, metrics } = props;

  const renderValue = (resource) => {
    const metric = metrics[resource.dataKey];
    const value = numeral(resource.value).format('0,0.[0]');
    return `${value} ${metric.unit}`;
  };

  if (!active || !payload) return null;
  return (
    <Card raised sx={{ width: '18rem', p: 1 }}>
      {label && (
        <Typography variant='body1' align='center'>
          {unixTimestampToString(Number(label), timezone?.offset)}
        </Typography>
      )}
      <table style={{ width: '100%' }}>
        <tbody>
          {map(payload, (resource) => {
            const color = resource?.color;
            return (
              <tr key={resource?.dataKey}>
                <StyledTd color={color} hideOverflow>
                  {startCase(get(resource, 'dataKey'))}
                </StyledTd>
                <StyledTd color={color} align='right'>
                  {renderValue(resource)}
                </StyledTd>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.number,
  timezone: PropTypes.object,
  metrics: PropTypes.object,
};

export default Tooltip;
