import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import startCase from 'lodash/startCase';

import {
  DEMAND_APP_ID,
  postDemandOrganization,
  postDemandPortfolioMemberships,
} from '../../store/demand';
import {
  postSolarOrganization,
  postSolarPortfolioMemberships,
  SOLAR_APP_ID,
} from '../../store/solar';
import OrganizationForm, { defaultState } from '../Forms/OrganizationForm';
import BaseDialog from './BaseDialog';

function OrganizationDialog(props) {
  const { appId, type, open, closeDialog, defaultPortfolioId = null } = props;
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState(defaultState);
  const [portfolioIds, setPortfolioIds] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (type === 'organization') {
      setOrganization((org) => ({ ...org, is_portfolio: false }));
    }
    if (type === 'portfolio') {
      setOrganization((org) => ({ ...org, is_portfolio: true }));
    }
  }, [type]);

  useEffect(() => {
    if (defaultPortfolioId) setPortfolioIds([defaultPortfolioId]);
  }, [defaultPortfolioId]);

  useEffect(() => {
    if (portfolioIds.length === 0 && !organization.is_portfolio) {
      setMessage('At least one portfolio required');
    } else if (organization.name.length === 0) {
      setMessage(
        `${
          organization.is_portfolio ? 'Portfolio' : 'Organization'
        } name required`
      );
    } else {
      setMessage('');
    }
  }, [organization, portfolioIds]);

  const handleClose = () => {
    closeDialog();
    setOrganization(defaultState);
    setPortfolioIds(defaultPortfolioId ? [defaultPortfolioId] : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning(message);
      return;
    }
    handleClose();

    let response, newOrg;
    if (appId === SOLAR_APP_ID) {
      response = await dispatch(postSolarOrganization(organization));
    } else if (appId === DEMAND_APP_ID) {
      response = await dispatch(postDemandOrganization(organization));
    }

    newOrg = response.payload.newOrg;
    if (!organization.is_portfolio) {
      const newPortfolioMemberships = portfolioIds.map((portfolioId) => {
        return {
          portfolio_id: portfolioId,
          member_id: newOrg.org_id,
        };
      });
      if (appId === SOLAR_APP_ID) {
        dispatch(postSolarPortfolioMemberships(newPortfolioMemberships));
      } else if (appId === DEMAND_APP_ID) {
        dispatch(postDemandPortfolioMemberships(newPortfolioMemberships));
      }
    }
  };

  const id = open ? 'create-organization-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title={`Create ${startCase(type)}`}
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <OrganizationForm
        appId={appId}
        mode='create'
        organization={organization}
        setOrganization={setOrganization}
        handleSubmit={handleSubmit}
        portfolioIds={portfolioIds}
        setPortfolioIds={setPortfolioIds}
      />
    </BaseDialog>
  );
}

export default OrganizationDialog;
