import React from 'react';
import dayjs from 'dayjs';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import union from 'lodash/union';

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';

import { numberToCurrency0 } from '../../../../helpers/currency';
import CostsTooltip from './CostsTooltip';
import WidgetLoader from '../../../../components/Loaders/WidgetLoader';

const COLORS = [
  'blue',
  'lightblue',
  'green',
  'grey',
  'orange',
  'purple',
  'yellow',
];

function CostsChart(props) {
  const { range, chartData, granularity, loading } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const metrics = (() => {
    let _metrics = reduce(
      chartData,
      (acc, point) => {
        let keys = Object.keys(point);
        return union(keys, acc);
      },
      []
    );
    _metrics.splice(_metrics.indexOf('timestamp'), 1);
    return _metrics.sort();
  })();

  const yAxisWidth = (() => {
    if (isMobile) {
      return 0;
    }
    return granularity === 'DAILY' ? 40 : 55;
  })();
  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        borderRadius: 1,
        boxShadow: (theme) => theme.shadows[10],
        p: 1,
      }}>
      <WidgetLoader loading={loading} />
      <ResponsiveContainer
        key='device-kpis-chart-responsive-container'
        width='100%'
        height={400}>
        <BarChart
          width='100%'
          height={400}
          data={chartData}
          maxBarSize={35}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 2,
          }}>
          <YAxis
            width={yAxisWidth}
            tickLine={false}
            tickFormatter={(value) => numberToCurrency0.format(value)}
          />
          <XAxis
            type='number'
            dataKey='timestamp'
            height={30}
            domain={[range.start.unix(), range.end.unix()]}
            padding={{ left: isMobile ? 0 : 10, right: isMobile ? 0 : 10 }}
            tickCount={null}
            tickFormatter={(unixTime) => {
              const date = dayjs(unixTime * 1000);
              if (granularity === 'MONTHLY') {
                return date.format('MMM YYYY');
              }
              return date.format('MM-DD');
            }}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CostsTooltip granularity={granularity} />}
            allowEscapeViewBox={{ y: true }}
          />
          {map(metrics, (metric, idx) => {
            let color = COLORS[idx % COLORS.length];
            return (
              <Bar
                stackId='a'
                key={metric}
                name={metric}
                type='monotone'
                dataKey={metric}
                fill={theme.palette.veregy_colors[color]}
                dot={false}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default CostsChart;
