import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'redux-first-history';
import { toastr } from 'react-redux-toastr';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '.';
import { ROUTES } from '../../helpers/constants';

const postSolarSite = createAsyncThunk(
  'solar/postSolarSite',
  async (newSite, { dispatch, getState }) => {
    const stateSites = getState().solar.sites;
    let sites = cloneDeep(stateSites);
    try {
      dispatch(showLoading());
      const site = await new WebAPIClient(SOLAR_APP_ID).POST(
        '/resource/sites',
        newSite
      );

      toastr.success('Site created', site.name);
      return { sites: [...sites, site] };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putSolarSite = createAsyncThunk(
  'solar/putSolarSite',
  async (updatedSite, { dispatch, getState }) => {
    const stateSites = getState().solar.sites;
    let sites = cloneDeep(stateSites);
    try {
      dispatch(showLoading());

      const site = await new WebAPIClient(SOLAR_APP_ID).PUT(
        `/resource/sites/${updatedSite.org_id}/${updatedSite.site_id}`,
        updatedSite
      );
      remove(sites, {
        org_id: updatedSite.org_id,
        site_id: updatedSite.site_id,
      });
      sites = concat(sites, site);

      toastr.success('Site updated');
      return { sites };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteSolarSite = createAsyncThunk(
  'solar/deleteSolarSite',
  async (deletedSite, { dispatch, getState }) => {
    const {
      sites: stateSites,
      loggers: stateLoggers,
      meters: stateMeters,
      inverters: stateInverters,
      sensors: stateSensors,
    } = getState().solar;
    let sites = cloneDeep(stateSites);
    let loggers = cloneDeep(stateLoggers);
    let meters = cloneDeep(stateMeters);
    let inverters = cloneDeep(stateInverters);
    let sensors = cloneDeep(stateSensors);
    try {
      dispatch(showLoading());
      await new WebAPIClient(SOLAR_APP_ID).DELETE(
        `/resource/sites/${deletedSite.org_id}/${deletedSite.site_id}`
      );

      remove(sites, {
        org_id: deletedSite.org_id,
        site_id: deletedSite.site_id,
      });

      const siteLoggerIds = loggers
        .filter((logger) => logger.site_id === deletedSite.site_id)
        .map((logger) => logger.logger_id);
      const siteMeterIds = meters
        .filter((meter) => meter.site_id === deletedSite.site_id)
        .map((meter) => meter.meter_id);
      const siteInverterIds = inverters
        .filter((inverter) => siteMeterIds.includes(inverter.meter_id))
        .map((inverter) => inverter.inverter_id);
      const siteSensorIds = sensors
        .filter((sensor) => siteMeterIds.includes(sensor.meter_id))
        .map((sensor) => sensor.sensor_id);

      remove(loggers, (logger) => siteLoggerIds.includes(logger.logger_id));
      remove(meters, (meter) => siteMeterIds.includes(meter.meter_id));
      remove(inverters, (inverter) =>
        siteInverterIds.includes(inverter.inverter_id)
      );
      remove(sensors, (sensor) => siteSensorIds.includes(sensor.sensor_id));

      toastr.success('Site deleted');
      return { sites, loggers, meters, inverters, sensors };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
      dispatch(push(ROUTES.LIGHTLEVEL.path));
    }
  }
);

export { postSolarSite, putSolarSite, deleteSolarSite };
