import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'app',
  initialState: { drawerExpanded: false },
  reducers: {
    setDrawerExpanded(state, action) {
      state.drawerExpanded = action.payload;
    },
  },
});

const { setDrawerExpanded } = actions;
export { setDrawerExpanded };
export default reducer;
