import React from 'react';
import ReactLoader from 'react-loading';

import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';

function WidgetLoader(props) {
  const { loading, height = 40 } = props;
  const theme = useTheme();

  if (!loading) return null;
  return (
    <Box
      sx={{
        height: 0,
        width: '100%',
        position: 'relative',
        top: 15,
        display: 'flex',
        justifyContent: 'flex-end',
        pr: 2,
        zIndex: 1,
      }}>
      <ReactLoader
        type='spin'
        color={theme.palette.primary.main}
        height={height}
        width={height}
        delay={250}
      />
    </Box>
  );
}

export default WidgetLoader;
