import React from 'react';
import Box from '@mui/material/Box';

import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { generateTicks } from '../../helpers/date';
import { unixTimestampToString } from '../../helpers/date';
import { chartColor } from '../../styles/muiTheme';
import WidgetLoader from '../Loaders/WidgetLoader';
import ChartTooltip from './Tooltip';

export const isLineSelected = (
  selectedResourceId,
  selectedIpAddress,
  selectedUserAgent,
  resourceId,
  ipAddress,
  userAgent
) => {
  if (selectedResourceId && selectedResourceId !== resourceId) {
    return false;
  }
  if (selectedIpAddress && selectedIpAddress !== ipAddress) {
    return false;
  }
  if (selectedUserAgent && selectedUserAgent !== userAgent) {
    return false;
  }
  return true;
};

export default function KioskUsageChart(props) {
  const {
    range,
    chartData,
    timezone,
    loading,
    appId,
    kioskInstances,
    selectedResourceId,
    selectedIpAddress,
    selectedUserAgent,
  } = props;

  let ticks = [];
  if (range) {
    ticks = generateTicks(range);
  }

  // sort kioskInstances into lines where "org" is first, then "site"
  let lines = kioskInstances.sort((a, b) => {
    if (a.includes('org')) return -1;
    if (b.includes('org')) return 1;
    return 0;
  });

  return (
    <Box
      sx={{
        px: 1,
        pt: 1,
        pb: 0.5,
        backgroundColor: 'background.paper',
        boxShadow: (theme) => theme.shadows[4],
        borderRadius: 1,
        width: '100%',
      }}>
      <WidgetLoader loading={loading} />
      <ResponsiveContainer
        key='kiosk-usage-container'
        width='100%'
        height={350}
        style={{ marginBottom: 2 }}>
        <LineChart
          width='100%'
          height={350}
          data={chartData}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}>
          <YAxis width={20} domain={[0, 'dataMax']} />
          <XAxis
            type='number'
            dataKey='timestamp'
            tickMargin={10}
            domain={[range?.start?.unix(), range?.end?.unix()]}
            ticks={ticks}
            tickFormatter={(unixTime) => {
              let format = 'MM/DD h:mm A';
              if (range?.end?.unix() - range?.start?.unix() > 90000) {
                format = 'MM/DD';
              }
              return unixTimestampToString(unixTime, timezone.offset, format);
            }}
          />
          <Tooltip
            content={
              <ChartTooltip
                appId={appId}
                timezone={timezone}
                selectedResourceId={selectedResourceId}
                selectedIpAddress={selectedIpAddress}
                selectedUserAgent={selectedUserAgent}
              />
            }
          />
          {lines.map((line, idx) => {
            const [resourceId, ipAddress, userAgent] = line.split('=');
            const lineSelected = isLineSelected(
              selectedResourceId,
              selectedIpAddress,
              selectedUserAgent,
              resourceId,
              ipAddress,
              userAgent
            );
            const color = chartColor(idx);
            const opacity = lineSelected ? 1 : 0.25;
            return (
              <Line
                key={line}
                type='monotone'
                dataKey={line}
                stroke={color}
                dot={false}
                strokeWidth={2}
                strokeOpacity={opacity}
                activeDot={lineSelected}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
