import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { postSolarPortfolioMemberships, SOLAR_APP_ID } from '../../store/solar';
import {
  DEMAND_APP_ID,
  postDemandPortfolioMemberships,
} from '../../store/demand';
import SelectOrganization from '../Selectors/SelectOrganization';
import BaseDialog from './BaseDialog';

function PortfolioMembershipDialog(props) {
  const { appId, portfolioId, open, setOpen } = props;
  const dispatch = useDispatch();
  const {
    portfolioMemberships: allPortfolioMemberships,
    organizations: allOrganizations,
  } = useSelector((state) => state[appId]);

  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [portfolioMemberId, setPortfolioMemberId] = useState('');

  useEffect(() => {
    const portfolioMemberships = allPortfolioMemberships.filter(
      (pm) => pm.portfolio_id === portfolioId
    );
    const organizations = allOrganizations.filter(
      (org) => org.is_portfolio === false
    );

    let availableOrgs = organizations.filter(
      (org) => !portfolioMemberships.some((pm) => pm.member_id === org.org_id)
    );

    availableOrgs = sortBy(availableOrgs, 'name');
    setAvailableOrganizations(availableOrgs);
  }, [portfolioId, allPortfolioMemberships, allOrganizations]);

  const handleClose = () => {
    setPortfolioMemberId('');
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleClose();

    const portfolioMembership = {
      portfolio_id: portfolioId,
      member_id: portfolioMemberId,
    };

    if (appId === SOLAR_APP_ID) {
      dispatch(postSolarPortfolioMemberships([portfolioMembership]));
    } else if (appId === DEMAND_APP_ID) {
      dispatch(postDemandPortfolioMemberships([portfolioMembership]));
    }
  };

  const id = open ? 'create-portfolio-membership-dialog' : '';
  return (
    <BaseDialog
      id={id}
      submitText='Create'
      disabled={portfolioMemberId === ''}
      title='Create Portfolio Membership'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <List disablePadding>
        <ListItem disableGutters>
          <SelectOrganization
            label='Member Organization'
            organizations={availableOrganizations}
            orgId={portfolioMemberId}
            setOrgId={(memberId) => setPortfolioMemberId(memberId)}
          />
        </ListItem>
      </List>
    </BaseDialog>
  );
}

export default PortfolioMembershipDialog;
