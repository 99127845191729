import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { openConfirmDialog } from '../../../../store/dialogs';
import { putDemandMeter } from '../../../../store/demand';

const cardStyles = {
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  width: '100%',
  cursor: 'pointer',
  mx: 1,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

const shedText = (shedValue) => {
  let textValue = 'auto';
  if (shedValue === false) textValue = 'inactive';
  else if (shedValue === true) textValue = 'active';
  return textValue;
};

function MeterGridItem({ meter, rto }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  let meterShedValue = get(meter, 'shed', null);
  let shedSignal =
    meterShedValue !== null ? meterShedValue : get(rto, 'shed', false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (value) => {
    const message = (
      <p>
        Are you sure you want to update {meter.name}'s shed state to{' '}
        <strong>{shedText(value)}</strong>?
      </p>
    );
    dispatch(
      openConfirmDialog({
        title: 'Update shed state',
        message,
        onConfirm: () => dispatch(putDemandMeter({ ...meter, shed: value })),
      })
    );
    handleClose();
  };

  const styles = (() => {
    let color = '';

    if (meterShedValue === null) {
      color = shedSignal ? 'lightgreen' : 'lightgrey';
    } else {
      color = shedSignal ? 'green' : 'grey';
    }

    return {
      ...cardStyles,
      bgcolor: color,
    };
  })();

  const open = Boolean(anchorEl);
  const id = open ? `popover-${meter.meter_id}` : undefined;

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      mt={1}>
      <Card raised onClick={handleClick} sx={styles}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}>
          {meter.name}
        </Typography>
      </Card>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}>
        <List disablePadding>
          {map([null, false, true], (value) => {
            return (
              <ListItemButton
                key={`list-item-${String(value)}`}
                disabled={meterShedValue === value}
                onClick={() => handleListItemClick(value)}>
                <Typography>{shedText(value)}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </Grid>
  );
}

MeterGridItem.propTypes = {
  meter: PropTypes.object.isRequired,
  rto: PropTypes.object.isRequired,
};

export default MeterGridItem;
