import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
  EditingState,
  FilteringState,
  PagingState,
} from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import BaseTable from '../../../components/Table/BaseTable';
import EditCell from '../../../components/Table/cells/EditCell';
import {
  CheckBoxTypeProvider,
  UserStatusTypeProvider,
} from '../../../components/Table/providers';
import CognitoUserDialog, {
  defaultState,
} from '../../../components/Dialogs/CognitoUserDialog';

function UsersTable() {
  const { users } = useSelector((state) => state.cognito);

  const [selectedUser, setSelectedUser] = useState(defaultState);
  const [integratedFilteringColumnExtensions] = useState([]);
  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.2 },
    { title: 'Email', name: 'email', width: 0.3 },
    { title: 'Status', name: 'UserStatus', width: 0.2 },
    { title: 'Super User', name: 'custom:SuperUser', width: 0.12 },
    { title: 'Email Verified', name: 'email_verified', width: 0.1 },
  ]);

  const handleEditClick = (row) => {
    setSelectedUser(row);
  };

  const handleClose = () => {
    setSelectedUser(defaultState);
  };

  return (
    <Card raised sx={{ maxWidth: 900 }}>
      <CardContent sx={{ p: 1, pt: 0, pb: '4px !important' }}>
        <BaseTable
          minWidth={600}
          rows={users}
          columns={columns}
          editColumn={
            <TableEditColumn
              width={42}
              showEditCommand
              cellComponent={(props) => (
                <EditCell
                  disabled={false}
                  tooltipText='Edit User'
                  // eslint-disable-next-line
                  handleClick={() => handleEditClick(props.row)}
                  {...props}
                />
              )}
            />
          }>
          <CheckBoxTypeProvider for={['custom:SuperUser', 'email_verified']} />
          <UserStatusTypeProvider for={['UserStatus']} />

          <EditingState />
          <FilteringState />
          <SortingState
            defaultSorting={[
              { columnName: 'custom:SuperUser', direction: 'desc' },
              { columnName: 'name', direction: 'asc' },
            ]}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />

          <IntegratedFiltering
            columnExtensions={integratedFilteringColumnExtensions}
          />
          <IntegratedSorting />
          <IntegratedPaging />
        </BaseTable>
      </CardContent>
      <CognitoUserDialog
        handleClose={handleClose}
        selectedUser={selectedUser}
      />
    </Card>
  );
}

export default UsersTable;
