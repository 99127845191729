import React from 'react';
import dayjs from 'dayjs';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { SOLAR_APP_ID } from '../../../store/solar';
import { TextFieldListItem } from '../../List/TextFieldListItem';
import AddressTimezoneListItems from '../AddressTimezoneListItems';
import useOrganization from '../../../store/hooks/useOrganization';
import ResourceListItem from '../../List/ResourceListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultState = {
  org_id: '',
  name: '',
  address: '',
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  location: { lat: 0, lng: 0 },
  installer: '',
  install_date: '',
  startup_date: '',
};

function SiteForm(props) {
  const { mode, site, setSite, handleSubmit } = props;
  const organization = useOrganization(site.org_id, SOLAR_APP_ID);

  const handleChange = (e) => {
    let value = e.target.value;
    setSite((prevSite) => ({ ...prevSite, [e.target.id]: value }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'create' && (
          <ResourceListItem
            label='Organization'
            name={organization.name}
            icon='buildings'
          />
        )}
        {mode === 'create' && <Divider />}
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField id='site_id' value={site.site_id} label='Site ID' />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label='Site Name'
          value={site.name}
          onChange={handleChange}
        />
        <AddressTimezoneListItems
          mode={mode}
          resource={site}
          setResource={setSite}
        />
        <TextFieldListItem
          id='installer'
          label='Installer'
          value={site.installer}
          onChange={handleChange}
        />
        <ListItem disableGutters>
          <DatePicker
            label='Install Date'
            value={dayjs(site.install_date)}
            onChange={(value) => {
              handleChange({ target: { id: 'install_date', value } });
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'standard',
                inputProps: { readOnly: true },
              },
            }}
          />
        </ListItem>
        <ListItem disableGutters>
          <DatePicker
            label='Startup Date'
            value={dayjs(site.startup_date)}
            onChange={(value) => {
              handleChange({ target: { id: 'startup_date', value } });
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'standard',
                inputProps: { readOnly: true },
              },
            }}
          />
        </ListItem>
      </List>
    </form>
  );
}

export default SiteForm;
