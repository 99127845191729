import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultSensorState = {
  name: 'Sensor 1',
  parent_index: 1,
  orientation: '',
  tilt: '',
  azimuth: '',
  type_: 'sensor',
};

function SensorForm(props) {
  const {
    mode,
    sensor,
    handleChange,
    handleSubmit = null,
    expanded,
    setExpanded = null,
    startAdornment = null,
  } = props;

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='sensor_id'
              value={sensor.sensor_id}
              label='Sensor ID'
            />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label={`Sensor ${sensor?.parent_index} Name`}
          value={sensor?.name}
          onChange={handleChange}
          InputProps={{
            startAdornment,
            endAdornment: setExpanded && (
              <InputAdornment position='end'>
                <IconButton onClick={setExpanded}>
                  <FontAwesomeIcon
                    icon={['fal', `chevron-${expanded ? 'up' : 'down'}`]}
                    size='sm'
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse
          in={expanded}
          timeout='auto'
          unmountOnExit
          sx={{ px: setExpanded ? 2 : 0 }}>
          <ListItem disableGutters>
            <FormControl id='orientation' variant='standard' fullWidth>
              <InputLabel id='orientation-label'>Orientation</InputLabel>
              <Select
                labelId='orientation-label'
                name='orientation'
                value={sensor?.orientation || ''}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''>None</MenuItem>
                {['Plane of Array', 'Global Horizontal Irradiance'].map(
                  (option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem
            disableGutters
            sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              type='number'
              variant='standard'
              id='tilt'
              label='Tilt'
              value={sensor?.tilt || ''}
              onChange={handleChange}
              sx={{ width: '45%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>°</InputAdornment>
                ),
              }}
            />
            <TextField
              type='number'
              variant='standard'
              id='azimuth'
              label='Azimuth'
              value={sensor?.azimuth || ''}
              onChange={handleChange}
              sx={{ width: '45%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>°</InputAdornment>
                ),
              }}
            />
          </ListItem>
        </Collapse>
      </List>
    </form>
  );
}

export default SensorForm;
