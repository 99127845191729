import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';

import WebAPIClient, { errorResponseToastr } from '../../../../../api';
import { SOLAR_APP_ID } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import SelectDatetime from '../../../../../components/Selectors/SelectDatetime';
import WidgetLoader from '../../../../../components/Loaders/WidgetLoader';

export const METHOD = {
  name: 'Manual',
  description: 'Insert or overwrite production data',
};

export default function ManualOptions(props) {
  const { meter, timezone, start, setStart, end, setEnd, setBackfilledData } =
    props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (start !== null && end !== null) {
      let current = start.clone();
      let _data = [];
      while (current.isSame(end) || current.isBefore(end)) {
        _data.push({
          id: current.unix(),
          timestamp: current.format('YYYY-MM-DD HH:mm'),
          value: 0,
        });
        current = current.add(15, 'minute');
      }
      setData(_data);
    }
  }, [start, end]);

  const backfill = () => {
    let backfillData = map(data, (point) => ({
      timestamp: point.id,
      value: point.value,
    }));
    const message = (
      <div>
        <p>Are you sure you want backfill this data?</p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <strong>Meter:</strong>
              </td>
              <td>{meter.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    dispatch(
      openConfirmDialog({
        title: 'Manual Backfill',
        message,
        onConfirm: async () => {
          try {
            const payload = await new WebAPIClient(SOLAR_APP_ID).POST(
              `/resource/backfill/${meter.org_id}/${meter.meter_id}`,
              {
                backfill_method: 'manual',
                data: backfillData,
              }
            );
            setBackfilledData(payload);
            toastr.success('Success', `Rows backfilled: ${payload.length}`);
          } catch (error) {
            if (error?.code === 'ECONNABORTED') {
              toastr.error('Timeout Error', error?.message);
            } else {
              errorResponseToastr(error);
            }
          } finally {
            setLoading(false);
          }
        },
      })
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Card raised sx={{ p: 0.5 }}>
          <CardHeader
            sx={{ pt: 1, pb: 0, mr: 1 }}
            title={METHOD.name}
            subheader={METHOD.description}
            action={
              <Stack
                direction='row'
                spacing={2}
                justifyContent='flex-end'
                alignItems='center'
                divider={
                  <Divider orientation='vertical' flexItem sx={{ m: 1 }} />
                }>
                <WidgetLoader loading={loading} />
                <SelectDatetime
                  value={start}
                  setValue={setStart}
                  label='Start'
                  timezone={timezone}
                />
                <SelectDatetime
                  value={end}
                  setValue={setEnd}
                  label='End'
                  timezone={timezone}
                />
                <Tooltip title='backfill data' placement='top'>
                  <span>
                    <IconButton
                      onClick={backfill}
                      disabled={(() => {
                        return start === null || end === null;
                      })()}>
                      <FontAwesomeIcon icon={['fal', 'paper-plane']} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={3}>
        <Card raised sx={{ p: 0.5 }}>
          <DataGrid
            rows={data}
            columns={[
              { field: 'timestamp', width: 250, headerName: 'Timestamp' },
              { field: 'value', headerName: 'kW', width: 150, editable: true },
            ]}
            hideFooter={true}
            onCellEditStop={(params, event) => {
              setData((d) => {
                let _data = [...d];
                const idx = d.findIndex((d) => d.id === params.id);
                _data[idx].value = Number(event.target.value);
                return _data;
              });
            }}
          />
        </Card>
      </Grid>
    </>
  );
}
