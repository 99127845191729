const APP_ID = 'admin';

const ROUTES = {
  LANDING: { path: 'login' },
  INDEX: { path: '/', title: 'Home' },
  HOME: { path: '/home', title: 'Home' },
  AWS: { path: '/aws', title: 'AWS' },
  USERS: { path: '/users', title: 'Cognito Users' },
  LIGHTLEVEL: { path: '/lightlevel', title: 'LightLevel' },
  GRIDCAP: { path: '/gridcap', title: 'Gridcap' },
  HUB: { path: '/hub', title: 'Hub' },
  ENERGYTRACER: { path: '/energytracer', title: 'EnergyTracer' },
};

const DEFAULT_ROLES = {
  ADMIN: {
    icon: 'user-crown',
    value: 0,
  },
  EDITOR: {
    icon: 'edit',
    value: 1,
  },
  VIEWER: {
    icon: 'eye',
    value: 2,
  },
};

const ENERGYTRACER_ROLES = {
  OWNER: {
    icon: 'user-crown',
    value: 'owner',
  },
  ADMIN: {
    icon: 'edit',
    value: 'admin',
  },
  VIEWER: {
    icon: 'eye',
    value: 'user',
  },
};

const ROLES = {
  solar: DEFAULT_ROLES,
  demand: DEFAULT_ROLES,
  energytracer: ENERGYTRACER_ROLES,
};

function findRole(appId, value) {
  for (const role in ROLES[appId]) {
    if (appId === 'energytracer' && ROLES[appId][role].value === value) {
      return role;
    }
    if (ROLES[appId][role].value === Number(value)) {
      return role;
    }
  }
  return null;
}

export { APP_ID, ROUTES, ROLES, findRole };
