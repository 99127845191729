import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { unregister } from './serviceWorker';

// hide error about default props
const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

unregister();
