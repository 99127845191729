import React from 'react';
import PropTypes from 'prop-types';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

function SelectDatetime(props) {
  const { value, setValue, label, timezone } = props;

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <DateTimePicker
      disableFuture
      label={label}
      timeSteps={{ minutes: 15 }}
      minutesStep={15}
      views={['year', 'day', 'hours', 'minutes']}
      defaultValue={null}
      value={value}
      onChange={handleChange}
      closeOnSelect={false}
      timezone={timezone.zone}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
      }}
      slotProps={{
        textField: {
          variant: 'standard',
          inputProps: { readOnly: true },
          InputProps: { disableUnderline: true },
        },
      }}
    />
  );
}

SelectDatetime.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  timezone: PropTypes.object.isRequired,
};

export default SelectDatetime;
