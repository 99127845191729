import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { postDemandMeter } from '../../../store/demand';
import MeterForm, { defaultMeterState } from '../../Forms/demand/MeterForm';
import BaseDialog from '../BaseDialog';

function MeterDialog(props) {
  const { open, closeDialog, orgId, siteId } = props;
  const dispatch = useDispatch();

  const meters = useSelector((state) => state.demand.meters);

  const [meter, setMeter] = useState(defaultMeterState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMeter((prevMeter) => ({
      ...prevMeter,
      org_id: orgId,
      site_id: siteId,
    }));
  }, [orgId, siteId, meters]);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setMeter((prevMeter) => ({ ...prevMeter, [id]: value }));
  };

  useEffect(() => {
    if (meter.name.length === 0) {
      setMessage('Meter name required');
    } else {
      setMessage('');
    }
  }, [meter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning(message);
    } else {
      dispatch(postDemandMeter(meter));
      handleClose();
    }
  };

  const handleClose = () => {
    closeDialog();
    setMeter({ ...defaultMeterState, org_id: orgId, site_id: siteId });
  };

  const id = open ? 'create-meter-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Meter'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <MeterForm
        mode='create'
        meter={meter}
        setMeter={setMeter}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </BaseDialog>
  );
}

export default MeterDialog;
