import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import Chip from '@mui/material/Chip';

export const getUserStatusColor = (status) => {
  if (status === 'CONFIRMED') return 'success';
  if (status === 'UNCONFIRMED') return 'warning';
  if (status === 'EXTERNAL_PROVIDER') return 'info';
  if (status === 'UNKNOWN') return 'error';
  if (status === 'RESET_REQUIRED') return 'error';
  if (status === 'FORCE_CHANGE_PASSWORD') return 'warning';
};

export const UserStatusTypeProvider = (props) => {
  return (
    <DataTypeProvider
      formatterComponent={(props) => {
        const { value } = props;
        return <Chip label={value} color={getUserStatusColor(value)} />;
      }}
      {...props}
    />
  );
};
