import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectRTO(props) {
  const { rto_id, handleChange } = props;
  const rtos = useSelector((state) => state.demand.rtos);

  return (
    <FormControl fullWidth variant='standard'>
      <InputLabel id='rto-label'>RTO</InputLabel>
      <Select
        labelId='rto-label'
        name='rto_id'
        value={rto_id || ''}
        onChange={handleChange}
        MenuProps={{
          MenuListProps: { disablePadding: true },
          slotProps: {
            paper: { sx: { minWidth: 'unset !important' } },
          },
        }}>
        <MenuItem key='' value={null}>
          None
        </MenuItem>
        {map(rtos, (_rto) => {
          return (
            <MenuItem key={_rto.rto_id} value={_rto?.rto_id}>
              {_rto?.rto_id}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectRTO.propTypes = {
  rto_id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SelectRTO;
