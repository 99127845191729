import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { ROUTES } from '../../helpers/constants';
import { SOLAR_APP_ID } from '../../store/solar';
import { DEMAND_APP_ID } from '../../store/demand';
import { useSelector } from 'react-redux';
import { push } from 'redux-first-history';

const generateSolarLinks = (state, resource) => {
  const { organizations, sites, loggers, meters } = state;
  let links = [
    {
      label: 'All',
      itemId: 'all',
    },
  ];
  let organization = organizations.find(
    (org) => org.org_id === resource?.org_id
  );
  let site = sites.find((site) => site.site_id === resource?.site_id);
  let logger = loggers.find(
    (logger) => logger.logger_id === resource?.logger_id
  );
  let meter = meters.find((meter) => meter.meter_id === resource?.meter_id);

  if (organization) {
    links.push({
      label: organization.name,
      itemId: organization.org_id,
    });
  }
  if (site) {
    links.push({
      label: site.name,
      itemId: site.site_id,
    });
  }
  if (logger) {
    links.push({
      label: logger.name,
      itemId: logger.logger_id,
    });
  }
  if (meter) {
    if (resource.type_ !== 'meter') {
      const site = sites.find((site) => site.site_id === meter.site_id);
      const logger = loggers.find(
        (logger) => logger.logger_id === meter.logger_id
      );
      links.push({
        label: site.name,
        itemId: site.site_id,
      });
      links.push({
        label: logger.name,
        itemId: logger.logger_id,
      });
    } else {
      links.push({
        label: meter.name,
        itemId: meter.meter_id,
      });
    }
  }

  if (['sensor', 'inverter'].includes(resource.type_)) {
    links.push({
      label: resource.name,
      itemId: resource[`${resource.type_}_id`],
    });
  }
  return links;
};

const generateDemandLinks = (state, resource) => {
  const { organizations, sites, meters } = state;
  let links = [
    {
      label: 'All',
      itemId: 'all',
    },
  ];
  let organization = organizations.find(
    (org) => org.org_id === resource?.org_id
  );
  let site = sites.find((site) => site.site_id === resource?.site_id);
  let meter = meters.find((meter) => meter.meter_id === resource?.meter_id);

  if (organization) {
    links.push({
      label: organization.name,
      itemId: organization.org_id,
    });
  }
  if (site) {
    links.push({
      label: site.name,
      itemId: site.site_id,
    });
  }
  if (meter) {
    links.push({
      label: meter.name,
      itemId: meter.meter_id,
    });
  }
  return links;
};

export default function ResourceBreadcrumbs(props) {
  const { appId, resource } = props;
  const dispatch = useDispatch();

  const solarState = useSelector((state) => state.solar);
  const demandState = useSelector((state) => state.demand);
  const [path, setPath] = useState(ROUTES.LIGHTLEVEL.path);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (appId === SOLAR_APP_ID) {
      setPath(ROUTES.LIGHTLEVEL.path);
    } else if (appId === DEMAND_APP_ID) {
      setPath(ROUTES.GRIDCAP.path);
    }
  }, [appId]);

  useEffect(() => {
    if (appId === SOLAR_APP_ID) {
      setLinks(generateSolarLinks(solarState, resource));
    } else if (appId === DEMAND_APP_ID) {
      setLinks(generateDemandLinks(demandState, resource));
    } else {
      setLinks([]);
    }
  }, [appId, resource, solarState, demandState]);

  const handleClick = (event) => {
    event.preventDefault();

    let itemId = event.target.id;

    if (itemId) {
      if (itemId === 'all') itemId = null;
      dispatch(push(path, { itemId }));
    }
  };

  return (
    <div
      role='presentation'
      onClick={handleClick}
      style={{ marginLeft: '8px', marginRight: '8px' }}>
      <Breadcrumbs maxItems={3} itemsAfterCollapse={1}>
        {links.map((link, idx) => {
          const underline = idx === links.length - 1 ? 'none' : 'hover';
          return (
            <Link
              underline={underline}
              color='inherit'
              id={link.itemId}
              key={link.itemId}>
              {link.label}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
