import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import { DEMAND_APP_ID } from '../../store/demand';
import { SOLAR_APP_ID } from '../../store/solar';
import useAppResource from '../../store/hooks/useAppResource';
import AllResources from './AllResources';
import ResourceBreadcrumbs from './ResourceBreadcrumbs';
import SolarOrganizationPage from './LightLevel/Resource/Organization';
import SolarSitePage from './LightLevel/Resource/Site';
import LoggerPage from './LightLevel/Resource/Logger';
import SolarMeterPage from './LightLevel/Resource/Meter';
import InverterPage from './LightLevel/Resource/Inverter';
import SensorPage from './LightLevel/Resource/Sensor';
import DemandOrganizationPage from './Gridcap/Resource/Organization';
import DemandSitePage from './Gridcap/Resource/Site';
import DemandMeterPage from './Gridcap/Resource/Meter';

function ResourcesPage(props) {
  const { nodes, path, appId } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const resource = useAppResource(appId);

  const renderGrid = () => {
    if (appId === SOLAR_APP_ID) {
      const type = resource?.type_;
      if (type === 'organization') {
        return <SolarOrganizationPage organization={resource} />;
      } else if (type === 'site') {
        return <SolarSitePage site={resource} />;
      } else if (type === 'logger') {
        return <LoggerPage logger={resource} />;
      } else if (type === 'meter') {
        return <SolarMeterPage meter={resource} />;
      } else if (type === 'inverter') {
        return <InverterPage inverter={resource} />;
      } else if (type === 'sensor') {
        return <SensorPage sensor={resource} />;
      }
    } else if (appId === DEMAND_APP_ID) {
      const type = resource?.type_;
      if (type === 'organization') {
        return <DemandOrganizationPage organization={resource} />;
      } else if (type === 'site') {
        return <DemandSitePage site={resource} />;
      } else if (type === 'meter') {
        return <DemandMeterPage meter={resource} />;
      }
    }

    return <AllResources appId={appId} nodes={nodes} path={path} />;
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={isMobile ? 1 : 0}
      mb={6}
      sx={{ maxWidth: '100%' }}>
      {resource && (
        <Grid
          item
          xs={12}
          mt={isMobile ? 1 : 0}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <ResourceBreadcrumbs appId={appId} resource={resource} />
        </Grid>
      )}
      {renderGrid()}
    </Grid>
  );
}

export default ResourcesPage;
