import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import concat from 'lodash/concat';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { DEMAND_APP_ID } from '.';
import { toastr } from 'react-redux-toastr';

const postDemandUser = createAsyncThunk(
  'demand/postDemandUser',
  async (newUser, { dispatch, getState }) => {
    const stateUsers = getState().demand.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(DEMAND_APP_ID).POST(
        '/resource/users',
        newUser
      );
      users = concat(users, user);
      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putDemandUser = createAsyncThunk(
  'demand/putDemandUser',
  async (updatedUser, { dispatch, getState }) => {
    const stateUsers = getState().demand.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/users/${updatedUser.user_id}`,
        updatedUser
      );

      remove(users, { user_id: user.user_id });
      users = concat(users, user);

      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteDemandUser = createAsyncThunk(
  'demand/deleteDemandUser',
  async (userId, { dispatch, getState }) => {
    const stateUsers = getState().demand.users;
    let users = cloneDeep(stateUsers);
    try {
      dispatch(showLoading());
      await new WebAPIClient(DEMAND_APP_ID).DELETE(`/resource/users/${userId}`);
      remove(users, { user_id: userId });

      return { users };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const generateDemandAPIKey = createAsyncThunk(
  'demand/generateDemandAPIKey',
  async (userId, { dispatch, getState }) => {
    try {
      const { users } = getState().demand;

      dispatch(showLoading());
      let updatedUser = await new WebAPIClient(DEMAND_APP_ID).PUT(
        `/resource/generate_api_key/${userId}`
      );
      let _users = cloneDeep(users);
      remove(_users, { user_id: updatedUser.user_id });

      toastr.success('API Key Generated', updatedUser.email);
      return { users: concat(_users, updatedUser) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export {
  postDemandUser,
  putDemandUser,
  deleteDemandUser,
  generateDemandAPIKey,
};
