import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { calculateAverageAppCost } from '../../../helpers';
import { numberToCurrency2 } from '../../../helpers/currency';
import IconImg from './IconImg';
import DataCard from './DataCard';
import SwipeableContainer from '../../../components/SwipeableContainer';

const APP_ID = 'management';

export default function HubGrid() {
  const theme = useTheme();
  // const { loading } = useSelector((state) => state.management);
  const { initial: costs, loading: costsLoading } = useSelector(
    (state) => state.costs
  );
  const [avgCost, setAvgCost] = useState(0);

  useEffect(() => {
    setAvgCost(calculateAverageAppCost(costs, APP_ID));
  }, [costs]);

  return (
    <Grid item md={12} sx={{ maxWidth: '100vw' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{ pt: 1 }}>
        <IconImg iconName='hub' height={45} label='Orchestrate Hub' />
        <Typography variant='h4' ml={2}>
          Orchestrate Hub
        </Typography>
      </Box>
      <SwipeableContainer>
        <DataCard
          icon='router'
          value='-'
          label=''
          color={theme.palette.veregy_colors.grey}
        />
        <DataCard
          icon='bell-on'
          value='-'
          label=''
          color={theme.palette.veregy_colors.orange}
        />
        <DataCard
          icon='users'
          value='-'
          label=''
          color={theme.palette.veregy_colors.blue}
        />
        <DataCard
          loading={costsLoading}
          icon='dollar-sign'
          value={numberToCurrency2.format(avgCost)}
          label='Avg Daily Cost'
          color={theme.palette.veregy_colors.green}
        />
      </SwipeableContainer>
    </Grid>
  );
}
