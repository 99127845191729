import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { ROUTES } from '../../../../../helpers/constants';
import { DEMAND_APP_ID } from '../../../../../store/demand';
import generateNodes, {
  generateSiteTree,
} from '../../../../../helpers/demandTree';
import ResourceTree from '../../../../../components/ResourceTree';
import OrganizationDialog from '../../../../../components/Dialogs/OrganizationDialog';
import SiteDialog from '../../../../../components/Dialogs/SiteDialog';

const CreateComponent = (props) => {
  const { organization } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <List disablePadding>
        <ListItemButton onClick={handleOpen}>
          <ListItemIcon>
            <FontAwesomeIcon icon={['fal', 'plus-circle']} />
          </ListItemIcon>
          <ListItemText
            secondary={`Create ${
              organization.is_portfolio ? 'Organization' : 'Site'
            }`}
          />
        </ListItemButton>
      </List>
      {organization.is_portfolio ? (
        <OrganizationDialog
          appId={DEMAND_APP_ID}
          type='organization'
          open={open}
          closeDialog={handleClose}
          defaultPortfolioId={organization.org_id}
        />
      ) : (
        <SiteDialog
          appId={DEMAND_APP_ID}
          open={open}
          closeDialog={handleClose}
          defaultOrgId={organization.org_id}
        />
      )}
    </>
  );
};

export default function OrganizationResources(props) {
  const { organization } = props;
  const demandState = useSelector((state) => state.demand);

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const { portfolioMemberships, organizations, sites, meters } = demandState;
    if (organization.is_portfolio) {
      const childRelations = filter(portfolioMemberships, {
        portfolio_id: organization.org_id,
      });
      const childOrgs = [];

      each(childRelations, (relation) => {
        let organization = find(organizations, { org_id: relation.member_id });
        if (organization) childOrgs.push(organization);
      });
      setNodes(
        generateNodes({
          ...demandState,
          portoflios: [],
          organizations: childOrgs,
        })
      );
    } else {
      const orgSites = filter(sites, { org_id: organization.org_id });
      const nodes = map(orgSites, (site) => generateSiteTree(site, meters));
      setNodes(nodes);
    }
  }, [organization, demandState]);

  return (
    <ResourceTree
      nodes={nodes}
      path={ROUTES.GRIDCAP.path}
      createComponent={<CreateComponent organization={organization} />}
    />
  );
}
