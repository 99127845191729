import React from 'react';

import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

const StyledSubtabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 60,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  minHeight: 'unset',
  marginBottom: '4px',
  paddingBottom: 'unset !important',
}));

const StyledSubtab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  padding: 8,
  minHeight: 'unset',
  '&.Mui-selected': { fontWeight: 'bold' },
}));

export { StyledSubtabs, StyledSubtab };
