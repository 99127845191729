import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { management as initialState } from '../initialState';

import { authenticateManagementUser } from './_management';

const { reducer } = createSlice({
  name: 'management',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [authenticateManagementUser]);
  },
});

// Export the reducer, either as a default or named export
export { authenticateManagementUser };
export default reducer;
