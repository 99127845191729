import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { SOLAR_APP_ID } from '../../store/solar';
import { TextFieldListItem } from '../List/TextFieldListItem';
import { ROUTES } from '../../helpers/constants';
import { DEMAND_APP_ID } from '../../store/demand';
import AddressTimezoneListItems from './AddressTimezoneListItems';
import ResourceListItem from '../List/ResourceListItem';
import SelectPortfolios from '../Selectors/SelectPortfolios';
import ClipboardField from '../Buttons/ClipboardField';

export const defaultState = {
  name: '',
  address: '',
  location: { lat: 0, lng: 0 },
  timezone: {
    name: '',
    zone: '',
    offset: '',
  },
  is_portfolio: false,
};

function OrganizationForm(props) {
  const {
    mode,
    appId,
    organization,
    setOrganization,
    handleSubmit,
    portfolioIds,
    setPortfolioIds,
  } = props;
  const dispatch = useDispatch();
  const { organizations, portfolioMemberships } = useSelector(
    (state) => state[appId]
  );
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    let _portfolios = [];
    if (portfolioIds) {
      _portfolios = portfolioIds.map((id) =>
        find(organizations, { org_id: id })
      );
    } else {
      const orgMemberships = portfolioMemberships.filter(
        (pm) => pm.member_id === organization?.org_id
      );
      _portfolios = orgMemberships.map((pm) =>
        find(organizations, { org_id: pm.portfolio_id })
      );
    }
    setPortfolios(_portfolios);
  }, [organization, portfolioMemberships, organizations, portfolioIds]);

  const handleChange = (e) => {
    let value = e.target.value;
    setOrganization((prevOrg) => ({ ...prevOrg, [e.target.id]: value }));
  };

  const handleIconClick = (itemId) => {
    if (appId === SOLAR_APP_ID) {
      dispatch(push(ROUTES.LIGHTLEVEL, { itemId }));
    } else if (appId === DEMAND_APP_ID) {
      dispatch(push(ROUTES.GRIDCAP, { itemId }));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'create' && !organization.is_portfolio && (
          <ListItem disableGutters>
            <SelectPortfolios
              appId={appId}
              portfolioIds={portfolioIds}
              setPortfolioIds={setPortfolioIds}
            />
          </ListItem>
        )}

        {mode === 'edit' &&
          portfolios.map((portfolio) => {
            return (
              <ResourceListItem
                key={portfolio.org_id}
                label='Portfolio'
                name={portfolio.name}
                onClick={() => handleIconClick(portfolio.org_id)}
                icon='folder'
              />
            );
          })}
        {mode === 'edit' && portfolios.length > 0 && <Divider />}
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='org_id'
              value={organization.org_id}
              label='Organization ID'
            />
          </ListItem>
        )}

        <TextFieldListItem
          id='name'
          label={
            organization?.is_portfolio ? 'Portfolio Name' : 'Organization Name'
          }
          value={organization.name}
          onChange={handleChange}
        />
        <AddressTimezoneListItems
          mode={mode}
          resource={organization}
          setResource={setOrganization}
        />
      </List>
    </form>
  );
}

export default OrganizationForm;
