import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import { user as initialState } from '../initialState';

import { ROUTES } from '../../helpers/constants';
import {
  getTokens,
  removeTokens,
  verifyToken,
} from '../../helpers/auth/tokens';
import { getEnvUserPoolClients } from '../../helpers/auth/clients';
import { SOLAR_APP_ID } from '../solar';

const setUser = createAsyncThunk('user/setUser', async (_, { dispatch }) => {
  try {
    dispatch(showLoading());

    const clients = getEnvUserPoolClients();
    const clientId = find(Object.entries(clients), ([_, client]) => {
      return client.appName === SOLAR_APP_ID;
    })[0];
    const { idToken } = getTokens(clientId);
    if (!idToken) {
      dispatch(push(ROUTES.LANDING.path));
      return initialState;
    } else {
      const item = await verifyToken(idToken, 'id', clientId);
      return { item };
    }
  } catch (err) {
    console.error('ERROR :: ', err);
  } finally {
    dispatch(hideLoading());
  }
});

const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      removeTokens();
      return initialState;
    } catch (err) {
      console.error('LOGOUT FAILED :: ', err);
    } finally {
      dispatch(push(ROUTES.LANDING.path));
      dispatch(hideLoading());
    }
  }
);

export { setUser, logoutUser };
