import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { toastr } from 'react-redux-toastr';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import BaseDialog from '../BaseDialog';
import SelectEnergyTracerRole from '../../Selectors/energytracer/SelectEnergyTracerRole';
import SelectEnergyTracerOrganization from '../../Selectors/energytracer/SelectEnergyTracerOrganization';
import SelectEnergyTracerUser from '../../Selectors/energytracer/SelectEnergyTracerUser';
import WebAPIClient from '../../../api/_energytracer';

export const defaultState = {
  organization_id: '',
  user_id: '',
  email: '',
  role: 'user',
};

function OrganizationUserDialog(props) {
  const dispatch = useDispatch();

  const { users, organizations, setTableRows, open, setOpen } = props;

  const [membership, setMembership] = useState(defaultState);

  const handleSelectUser = (user) => {
    setMembership((prevMembership) => ({
      ...prevMembership,
      user_id: user?.user_id || '',
      email: user?.email || '',
      name: user?.name || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setMembership({ ...defaultState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(showLoading());
    try {
      const response = await new WebAPIClient().POST(
        `organizations/${membership.organization_id}/users`,
        { ...membership }
      );
      setTableRows((prev) => [
        {
          ...membership,
          user_id: response.id,
          organization_name: organizations.find(
            (org) => org.id === membership.organization_id
          ).name,
        },
        ...prev,
      ]);
      dispatch(hideLoading());
      toastr.success('Membership Updated');
    } catch (err) {
      dispatch(hideLoading());
      toastr.error('Request Failed');
    }
    handleClose();
  };

  const id = open ? 'create-membership-dialog' : '';
  return (
    <BaseDialog
      id={id}
      title='Create Membership'
      submitText='Create'
      //disabled={!membership.email || !membership.organization_id}
      disabled={false}
      handleClose={handleClose}
      handleSubmit={handleSubmit}>
      <form onSubmit={handleSubmit}>
        <List disablePadding>
          <ListItem disableGutters>
            <SelectEnergyTracerOrganization
              label='Organization'
              organizations={organizations}
              orgId={membership.organization_id}
              setOrgId={(orgId) =>
                setMembership((prev) => {
                  return { ...prev, organization_id: orgId };
                })
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <SelectEnergyTracerUser
              users={users}
              userId={membership.user_id}
              setUser={handleSelectUser}
            />
          </ListItem>
          <ListItem disableGutters>
            <SelectEnergyTracerRole
              appId={'energytracer'}
              role={membership.role}
              setRole={(role) =>
                setMembership((prev) => {
                  return { ...prev, role: role };
                })
              }
            />
          </ListItem>
        </List>
      </form>
    </BaseDialog>
  );
}

export default OrganizationUserDialog;
