import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';

import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { openConfirmDialog } from '../../../../store/dialogs';
import { putRTO } from '../../../../store/demand';
import MetersGrid from './MetersGrid';
import ShedInfoButton from './ShedInfoButton';
import EditMetersButton from './EditMetersButton';
import MeterFilterButton from './MeterFilterButton';

const defaultState = {
  rto_id: '',
  shed: false,
  peak_percent: 0.95,
};

export default function LoadShed() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const {
    rtos,
    meters: allMeters,
    organizations,
    portfolioMemberships,
  } = useSelector((state) => state.demand);

  const [rto, setRto] = useState(defaultState);
  const [meters, setMeters] = useState([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');
  const [selectedSiteId, setSelectedSiteId] = useState('');

  useEffect(() => {
    const _rto = find(rtos, (_rto) => _rto?.rto_id === 'PJM');
    if (!_rto) return;
    setRto(_rto);
  }, [rtos, rto]);

  useEffect(() => {
    let _meters = [];
    if (selectedSiteId) {
      _meters = filter(allMeters, { site_id: selectedSiteId });
    } else if (selectedOrganizationId) {
      let organization = find(organizations, {
        org_id: selectedOrganizationId,
      });
      if (!get(organization, 'is_portfolio')) {
        _meters = filter(allMeters, { org_id: selectedOrganizationId });
      } else {
        let memberOrgIds = map(
          filter(portfolioMemberships, {
            portfolio_id: selectedOrganizationId,
          }),
          (portfolioMembership) => get(portfolioMembership, 'member_id')
        );
        _meters = filter(allMeters, (meter) =>
          includes(memberOrgIds, get(meter, 'org_id'))
        );
      }
    } else {
      _meters = filter(allMeters, { rto_id: rto?.rto_id });
    }
    setMeters(_meters);
  }, [
    rto,
    allMeters,
    organizations,
    portfolioMemberships,
    selectedOrganizationId,
    selectedSiteId,
  ]);

  // const selectRTO = (e) => {
  //   setRto(find(rtos, (_rto) => _rto?.rto_id === e.target.value));
  // };

  const changeShedStatus = (e) => {
    const message = (
      <p>
        Are you sure you want to change {rto.rto_id}'s shed value to{' '}
        <strong>{e.target.value ? 'active' : 'inactive'}</strong>?
      </p>
    );
    dispatch(
      openConfirmDialog({
        title: `Update ${rto.rto_id}'s Shed state`,
        message,
        onConfirm: () => dispatch(putRTO({ ...rto, shed: e.target.value })),
      })
    );
  };

  return (
    <Grid
      container
      justifyContent='flex-start'
      spacing={isMobile ? 0 : 1}
      mt={1}
      mx={1}
      maxWidth={900}>
      <Grid
        item
        xs={12}
        md={6}
        mt={isMobile ? 1 : 0}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-end',
          alignItems: 'center',
        }}>
        <Card raised sx={{ pt: 1, px: 1, width: 350 }}>
          <Stack
            spacing={1}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ mb: '8px !important' }}
              />
            }>
            <FormControl variant='standard' size='small' sx={{ minWidth: 80 }}>
              <InputLabel id='rto-select-label'>RTO</InputLabel>
              <Select
                autoWidth
                disableUnderline
                labelId='rto-select-label'
                id='rto-select'
                value={rtos.length ? rto.rto_id : ''}
                // onChange={selectRTO}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}>
                {map(rtos, (_rto) => {
                  return (
                    <MenuItem key={_rto.rto_id} value={_rto?.rto_id}>
                      {_rto?.rto_id}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant='standard' size='small' sx={{ minWidth: 80 }}>
              <InputLabel id='load-shed-select-label'>Loadshed</InputLabel>
              <Select
                autoWidth
                disableUnderline
                labelId='load-shed-select-label'
                id='load-shed-select'
                value={rto ? rto.shed : ''}
                onChange={changeShedStatus}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}>
                <MenuItem key={'active'} value={true}>
                  active
                </MenuItem>
                <MenuItem key={'inactive'} value={false}>
                  inactive
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant='standard'
              label='peak percent'
              id='peak-percent-field'
              value={rto?.peak_percent || ''}
              sx={{ width: 80 }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                readOnly: true,
                disableUnderline: true,
              }}
              inputProps={{ style: { textAlign: 'right' } }}
            />
          </Stack>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        mt={isMobile ? 1 : 0}
        mb={isMobile ? 2 : 0}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-start',
          alignItems: 'center',
        }}>
        <Card raised sx={{ px: 1, pt: 1, width: 300 }}>
          <Stack
            spacing={1}
            direction='row'
            justifyContent='space-around'
            alignItems='center'>
            <MeterFilterButton
              selectedOrganizationId={selectedOrganizationId}
              setSelectedOrganizationId={setSelectedOrganizationId}
              selectedSiteId={selectedSiteId}
              setSelectedSiteId={setSelectedSiteId}
            />
            <EditMetersButton meters={meters} />
            <ShedInfoButton />
          </Stack>
        </Card>
      </Grid>
      <MetersGrid rto={rto} meters={meters} selectedSiteId={selectedSiteId} />
      <Grid item xs={12} mb={5} />
    </Grid>
  );
}
