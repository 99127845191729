import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import dayjs from 'dayjs';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { SOLAR_APP_ID } from '../solar';

const ENV = process.env.REACT_APP_ENV;

const getInitalCosts = createAsyncThunk(
  'costs/getInitalCosts',
  async (_, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading } = getState().costs;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }
    try {
      dispatch(showLoading());
      const end = dayjs().subtract(1, 'days').endOf('day').valueOf() + 1;
      const start = dayjs()
        .subtract(1, 'days')
        .subtract(2, 'month')
        .startOf('day')
        .valueOf();
      const granularity = 'DAILY';
      const metric = 'UnblendedCost';
      const groupByType = 'TAG';
      const groupByKey = 'app';
      const filters = ENV === 'dev' ? {} : { filter0: `env$${ENV}` };
      let demo = false;
      if (ENV === 'dev') demo = true;

      const response = await new WebAPIClient(SOLAR_APP_ID).GET(
        '/resource/costs',
        {
          start,
          end,
          granularity,
          metric,
          groupByType,
          groupByKey,
          ...filters,
          demo,
        }
      );

      return { initial: response };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getInitalCosts };
