import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';

import { deleteDemandMeter, putDemandMeter } from '../../../../../store/demand';
import { openConfirmDialog } from '../../../../../store/dialogs';
import MeterForm from '../../../../../components/Forms/demand/MeterForm';

export default function MeterPage(props) {
  const { meter: stateMeter } = props;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [meter, setMeter] = useState(stateMeter);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setMeter((prevMeter) => ({ ...prevMeter, [id]: value }));
  };

  const handleSubmit = () => {
    dispatch(putDemandMeter(meter));
  };

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Delete Meter',
        message: <p>Are you sure you want to delete this meter?</p>,
        onConfirm: () => dispatch(deleteDemandMeter(meter)),
      })
    );
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid
        item
        xs={12}
        px={1}
        mt={isMobile ? 1 : 0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        <Card raised sx={{ width: 400, px: 2, py: 1 }}>
          <MeterForm
            mode='edit'
            meter={meter}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Button variant='outlined' color='error' onClick={handleDelete}>
              Delete
            </Button>
            <Button variant='contained' onClick={handleSubmit}>
              Save
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
