import React from 'react';

import { DEMAND_APP_ID } from '../../../../store/demand';
import WebAPIClient from '../../../../api';
import KPIsPage from '../../../../components/KPIsPage';

export default function KPIs() {
  const demandKpiMetrics = {
    alarms: [
      { label: 'All Alarms', metric: 'alarms', color: 'blue' },
      {
        label: 'Connection Alarms',
        metric: 'connection_alarms',
        color: 'orange',
      },
      {
        label: 'Load Shed Alarms',
        metric: 'load_shed_alarms',
        color: 'red',
      },
    ],
    resources: [
      { label: 'Portfolios', metric: 'portfolios', color: 'green' },
      {
        label: 'Organizations',
        metric: 'organizations',
        color: 'blue',
      },
      { label: 'Sites', metric: 'sites', color: 'orange' },
      { label: 'Meters', metric: 'meters', color: 'lightgrey' },
    ],
    users: [
      { label: 'Users', metric: 'users', color: 'blue' },
      {
        label: 'Monthly Active Users',
        metric: 'monthly_active_users',
        color: 'orange',
      },
      {
        label: 'Daily Active Users',
        metric: 'daily_active_users',
        color: 'green',
      },
    ],
  };

  const fetchData = async (start, end, selectedMetrics) => {
    return await new WebAPIClient(DEMAND_APP_ID).GET('/resource/kpis', {
      start,
      end,
      metrics: JSON.stringify(selectedMetrics),
    });
  };

  return <KPIsPage metrics={demandKpiMetrics} fetchData={fetchData} />;
}
