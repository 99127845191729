import find from 'lodash/find';
import { useSelector } from 'react-redux';

const useCompareOrgName = (appId) => {
  const organizations = useSelector((state) => state[appId].organizations);
  return (orgId1, orgId2) => {
    const organization1 = find(organizations, { org_id: orgId1 });
    const organization2 = find(organizations, { org_id: orgId2 });
    return organization1?.name.toLowerCase() < organization2?.name.toLowerCase()
      ? -1
      : 1;
  };
};

export default useCompareOrgName;
