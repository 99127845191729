import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../../../../helpers/constants';
import { generateSiteTree } from '../../../../../helpers/solarTree';
import ResourceTree from '../../../../../components/ResourceTree';
import LoggerDialog from '../../../../../components/Dialogs/solar/LoggerDialog';
import CreateLoggerAndDevicesDialog from '../../../../../components/Dialogs/solar/CreateSolarLoggerAndDevicesDialog';
import CreateResourceListButton from '../../../../../components/Buttons/CreateResourceListButton';

const CreateComponent = (props) => {
  const { site } = props;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      <CreateResourceListButton
        label='Create Logger & Devices'
        setOpen={setOpen1}
      />
      <CreateResourceListButton label='Create Logger' setOpen={setOpen2} />
      <CreateLoggerAndDevicesDialog
        open={open1}
        closeDialog={handleClose1}
        orgId={site.org_id}
        siteId={site.site_id}
      />
      <LoggerDialog
        open={open2}
        closeDialog={handleClose2}
        orgId={site.org_id}
        siteId={site.site_id}
      />
    </>
  );
};

export default function SiteResources(props) {
  const { site } = props;
  const solarState = useSelector((state) => state.solar);

  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const { loggers, meters, inverters, sensors } = solarState;
    const { children: nodes } = generateSiteTree(
      site,
      loggers,
      meters,
      inverters,
      sensors
    );
    setNodes(nodes);
  }, [site, solarState]);

  return (
    <ResourceTree
      nodes={nodes}
      path={ROUTES.LIGHTLEVEL.path}
      createComponent={<CreateComponent site={site} />}
    />
  );
}
