import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultMeterState = {
  name: '',
  parent_index: 1,
  type_: 'meter',
};

function MeterForm(props) {
  const {
    mode,
    meter,
    handleChange,
    handleSubmit = null,
    endAdornment = null,
  } = props;

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='meter_id'
              value={meter.meter_id}
              label='Meter ID'
            />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label={`Meter ${meter.parent_index} Name`}
          value={meter.name}
          onChange={handleChange}
          InputProps={{ endAdornment: endAdornment }}
        />
      </List>
    </form>
  );
}

export default MeterForm;
