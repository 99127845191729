import React, { useState, useRef, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

const SwipeableContainer = ({ children }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [dragStart, setDragStart] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && contentRef.current) {
        // Ensure translateX doesn't exceed bounds on resize
        setTranslateX((prevTranslateX) => {
          const containerWidth = containerRef.current.offsetWidth;
          const contentWidth = contentRef.current.scrollWidth;
          if (prevTranslateX > contentWidth - containerWidth) {
            return Math.max(0, contentWidth - containerWidth);
          }
          return prevTranslateX;
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTouchStart = (e) => {
    setDragStart(e.touches[0].clientX);
    setDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!dragging) return;

    const currentPosition = e.touches[0].clientX;
    const diff = dragStart - currentPosition;

    let nextTranslateX = translateX + diff;
    const containerWidth = containerRef.current.offsetWidth;
    const contentWidth = contentRef.current.scrollWidth + 4;

    if (nextTranslateX < 0) {
      nextTranslateX = 0;
    } else if (nextTranslateX > contentWidth - containerWidth) {
      nextTranslateX = contentWidth - containerWidth;
    }

    setTranslateX(nextTranslateX);
    setDragStart(currentPosition);
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  return (
    <Box
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      sx={{
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
      }}>
      <Box
        ref={contentRef}
        sx={{
          display: 'flex',
          justifyContent: isMobile ? 'flex-start' : 'center',
          transition: 'transform 0.3s ease',
          transform: `translateX(-${translateX}px)`,
        }}>
        {children}
      </Box>
    </Box>
  );
};

export default SwipeableContainer;
