import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { solar as initialState } from '../initialState';

import { authenticateSolarUser, getSolarResources } from './_solar';
import {
  postSolarOrganization,
  putSolarLicense,
  putSolarOrganization,
} from './organizations';
import {
  deleteSolarMembership,
  postSolarMembership,
  putSolarMembership,
} from './membership';
import {
  postSolarPortfolioMemberships,
  deleteSolarPortfolioMembership,
} from './portfolioMemberships';
import {
  deleteSolarUser,
  postSolarUser,
  putSolarUser,
  generateSolarAPIKey,
} from './user';
import { deleteSolarSite, postSolarSite, putSolarSite } from './sites';
import { deleteSolarLogger, postSolarLogger, putSolarLogger } from './loggers';
import { deleteSolarMeter, postSolarMeter, putSolarMeter } from './meters';
import {
  deleteSolarInverter,
  postSolarInverter,
  putSolarInverter,
} from './inverters';
import { deleteSolarSensor, postSolarSensor, putSolarSensor } from './sensors';

export const SOLAR_APP_ID = 'solar';

const { reducer } = createSlice({
  name: SOLAR_APP_ID,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateSolarUser,
      putSolarLicense,
      getSolarResources,
      postSolarLogger,
      putSolarLogger,
      deleteSolarLogger,
      deleteSolarInverter,
      postSolarInverter,
      putSolarInverter,
      deleteSolarMeter,
      postSolarMeter,
      putSolarMeter,
      postSolarOrganization,
      putSolarOrganization,
      postSolarPortfolioMemberships,
      deleteSolarPortfolioMembership,
      postSolarMembership,
      putSolarMembership,
      deleteSolarMembership,
      deleteSolarSensor,
      postSolarSensor,
      putSolarSensor,
      deleteSolarSite,
      postSolarSite,
      putSolarSite,
      putSolarUser,
      postSolarUser,
      deleteSolarUser,
      generateSolarAPIKey,
    ]);
  },
});

// Export the reducer, either as a default or named export
export {
  authenticateSolarUser,
  putSolarLicense,
  getSolarResources,
  postSolarLogger,
  putSolarLogger,
  deleteSolarLogger,
  deleteSolarInverter,
  postSolarInverter,
  putSolarInverter,
  deleteSolarMeter,
  postSolarMeter,
  putSolarMeter,
  postSolarOrganization,
  putSolarOrganization,
  postSolarPortfolioMemberships,
  deleteSolarPortfolioMembership,
  postSolarMembership,
  putSolarMembership,
  deleteSolarMembership,
  deleteSolarSensor,
  postSolarSensor,
  putSolarSensor,
  deleteSolarSite,
  postSolarSite,
  putSolarSite,
  putSolarUser,
  postSolarUser,
  deleteSolarUser,
  generateSolarAPIKey,
};
export default reducer;
