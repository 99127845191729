import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SOLAR_APP_ID } from '../../../../../store/solar';
import OrganizationCard from '../../../../../components/OrganizationCard';
import OrganizationResources from './Resources';
import PortfolioMembershipsTable from '../../../../../components/Table/PortfolioMembershipsTable';
import MembershipsTable from '../../../../../components/Table/MembershipsTable';
import LicensesTable from '../../../../../components/Table/LicensesTable';
import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../../components/StyledSubtabs';

export default function OrganizationPage(props) {
  const { organization } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [tab, setTab] = useState(0);

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  const label = (() => {
    if (tab === 0 || tab === 1) {
      return null;
    }
    if (tab === 2) return 'Memberships';
    if (tab === 3) {
      return organization.is_portfolio ? 'Portfolio Memberships' : 'Licenses';
    }
  })();

  return (
    <>
      <Grid
        item
        xs={12}
        mt={0}
        pt={'0 !important'}
        sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledSubtabs
          value={tab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            width: 400,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <StyledSubtab
            icon={
              <FontAwesomeIcon
                icon={[
                  'fal',
                  organization?.is_portfolio ? 'folder' : 'buildings',
                ]}
                size='xl'
              />
            }
          />
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'sitemap']} size='xl' />}
          />
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'user-friends']} size='xl' />}
          />
          <StyledSubtab
            icon={
              <FontAwesomeIcon
                icon={[
                  'fal',
                  organization?.is_portfolio
                    ? 'folder-tree'
                    : 'file-certificate',
                ]}
                size='xl'
              />
            }
          />
        </StyledSubtabs>
      </Grid>
      {label && (
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Typography variant='body1' align='center' width={400}>
            {label}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        px={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        {tab === 0 && (
          <OrganizationCard organization={organization} appId={SOLAR_APP_ID} />
        )}
        {tab === 1 && <OrganizationResources organization={organization} />}
        {tab === 2 && (
          <MembershipsTable
            orgId={organization.org_id}
            appId={SOLAR_APP_ID}
            mx={0}
          />
        )}
        {tab === 3 &&
          (organization.is_portfolio ? (
            <PortfolioMembershipsTable
              portfolio={organization}
              appId={SOLAR_APP_ID}
            />
          ) : (
            <LicensesTable orgId={organization.org_id} appId={SOLAR_APP_ID} />
          ))}
      </Grid>
    </>
  );
}
