import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import { toastr } from 'react-redux-toastr';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';

export default function LicenseTools() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = (loading) => {
    if (loading) {
      setIsLoading(true);
      dispatch(showLoading());
    } else {
      setIsLoading(false);
      dispatch(hideLoading());
    }
  };

  const callLicenseFunction = async (url) => {
    handleLoading(true);
    try {
      await new WebAPIClient().GET(`/providers/${url}_licenses`);
      toastr.success('Licenses Updating');
      handleLoading(false);
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <br></br>
      <Button
        type='submit'
        onClick={() => callLicenseFunction('deactivate')}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Deactivate Expired licenses
      </Button>
      <br></br>
      <br></br>
      <Button
        type='submit'
        onClick={() => callLicenseFunction('reactivate')}
        color='primary'
        variant='contained'
        disabled={isLoading}>
        Reactivate Non-Expired Licenses
      </Button>
    </div>
  );
}
