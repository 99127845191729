import React from 'react';
import get from 'lodash/get';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { TextFieldListItem } from '../../List/TextFieldListItem';
import { IntegerFieldListItem } from '../../List/IntegerFieldListItem';
import SelectSensor from '../../../routes/App/LightLevel/Resource/Inverter/SelectSensor';
import ClipboardField from '../../Buttons/ClipboardField';

export const defaultInverterState = {
  name: 'Inverter 1',
  parent_index: 1,
  manufacturer: '',
  serial_number: '',
  dc_size: '',
  ac_size: '',
  type_: 'inverter',
};

function InverterForm(props) {
  const {
    mode,
    inverter,
    handleChange,
    handleSubmit = null,
    expanded,
    setExpanded = null,
    startAdornment = null,
  } = props;

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          handleSubmit(event);
        }
      }}>
      <List disablePadding>
        {mode === 'edit' && (
          <ListItem disableGutters>
            <ClipboardField
              id='inverter_id'
              value={inverter.inverter_id}
              label='Inverter ID'
            />
          </ListItem>
        )}
        <TextFieldListItem
          id='name'
          label={`Inverter ${inverter.parent_index} Name`}
          value={inverter.name}
          onChange={handleChange}
          InputProps={{
            startAdornment,
            endAdornment: setExpanded && (
              <InputAdornment position='end'>
                <IconButton onClick={setExpanded}>
                  <FontAwesomeIcon
                    icon={['fal', `chevron-${expanded ? 'up' : 'down'}`]}
                    size='sm'
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse
          in={expanded}
          timeout='auto'
          unmountOnExit
          sx={{ px: setExpanded ? 2 : 0 }}>
          <ListItem disableGutters>
            <FormControl id='manufacturer' variant='standard' fullWidth>
              <InputLabel id='manufacturer-label'>Manufacturer</InputLabel>
              <Select
                labelId='manufacturer-label'
                name='manufacturer'
                value={get(inverter, 'manufacturer', '')}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''>None</MenuItem>
                {[
                  'Chint Power Systems',
                  'Canadian Solar Inc',
                  'SMA Solar Technology',
                ].map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <TextFieldListItem
            id='serial_number'
            label='Serial Number'
            value={get(inverter, 'serial_number', '')}
            onChange={handleChange}
          />
          <IntegerFieldListItem
            id='dc_size'
            label='DC Size'
            value={inverter.dc_size || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
            }}
          />
          <IntegerFieldListItem
            id='ac_size'
            label='AC Size'
            value={inverter.ac_size || ''}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kW</InputAdornment>,
            }}
          />
          {mode === 'edit' && (
            <SelectSensor
              sensorId={get(inverter, 'sensor_id', '')}
              orgId={inverter.org_id}
              handleSelect={(e) => {
                handleChange({
                  target: {
                    id: 'sensor_id',
                    value: e.target.value,
                  },
                });
              }}
            />
          )}
        </Collapse>
      </List>
    </form>
  );
}

export default InverterForm;
