import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { hideLoading, showLoading } from 'react-redux-loading-bar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { toastr } from 'react-redux-toastr';

import WebAPIClient, {
  errorResponseToastr,
} from '../../../../api/_energytracer';

const ACCOUNT_UPDATE_OPTIONS = {
  credential: {
    option: 'Account',
    url: '/tools/update_accounts',
  },
  account: {
    option: 'Organization',
    url: '/tools/update_org_accounts',
  },
};

const BILL_UPDATE_OPTIONS = {
  resource: {
    org: 'Organization',
    account: 'Account',
  },
  method: {
    1: 'Read Date',
    2: 'Bill Date',
  },
};

export default function UtilityAccountTools() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [updateAccountOption, setUpdateAccountOption] = useState('account');
  const [updateAccountId, setUpdateAccountId] = useState(null);

  const [updateBillResourceOption, setUpdateBillResourceOption] =
    useState('org');
  const [updateBillMethodOption, setUpdateBillMethodOption] = useState(1);
  const [updateBillResourceId, setUpdateBillResourceId] = useState(null);

  useEffect(() => {
    setUpdateAccountId(null);
  }, [updateAccountOption]);

  useEffect(() => {
    setUpdateBillResourceId(null);
  }, [updateBillResourceOption]);

  const handleLoading = (loading) => {
    if (loading) {
      setIsLoading(true);
      dispatch(showLoading());
    } else {
      setIsLoading(false);
      dispatch(hideLoading());
    }
  };

  const handleUpdateResource = async () => {
    handleLoading(true);
    let url =
      ACCOUNT_UPDATE_OPTIONS[updateAccountOption]['url'] +
      '/' +
      updateAccountId;

    try {
      await new WebAPIClient().GET(url);
      handleLoading(false);
      toastr.success('Update in progress. Please check back in a few minutes');
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleUpdateBill = async () => {
    handleLoading(true);
    let url = `tools/update_bill_dates/${updateBillResourceId}/${updateBillResourceOption}/${updateBillMethodOption}`;

    try {
      await new WebAPIClient().GET(url);
      handleLoading(false);
      toastr.success('Repull in progress. Please check back in a few minutes');
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <div>
      <h3>Update Account Max Date</h3>
      <form>
        <List disablePadding>
          <ListItem disableGutters>
            <FormControl id='account-max-date' variant='standard' fullWidth>
              <InputLabel id='account-max-date-label'>Update Option</InputLabel>
              <Select
                labelId='account-max-date-label'
                name='account-max-date'
                value={updateAccountOption}
                onChange={(e) => {
                  setUpdateAccountOption(e.target.value);
                }}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(ACCOUNT_UPDATE_OPTIONS).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {ACCOUNT_UPDATE_OPTIONS[key]['option']}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disableGutters>
            <TextField
              fullWidth
              variant='standard'
              id='value'
              label={`${ACCOUNT_UPDATE_OPTIONS[updateAccountOption]['option']} ID`}
              value={updateAccountId ? updateAccountId : ''}
              onChange={(e) => setUpdateAccountId(e.target.value)}
            />
          </ListItem>

          <Button
            type='submit'
            onClick={handleUpdateResource}
            color='primary'
            variant='contained'
            disabled={!updateAccountId || isLoading}>
            Update Resource
          </Button>
        </List>
      </form>
      <br></br>
      <h3>Update Bill Dates</h3>
      <form>
        <List disablePadding>
          <ListItem disableGutters>
            <FormControl
              id='account-bill-date-option'
              variant='standard'
              fullWidth>
              <InputLabel id='account-bill-date-option-label'>
                Update Option
              </InputLabel>
              <Select
                labelId='account-bill-date-option-label'
                name='account-bill-date-option'
                value={updateBillResourceOption}
                onChange={(e) => {
                  setUpdateBillResourceOption(e.target.value);
                }}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(BILL_UPDATE_OPTIONS['resource']).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {BILL_UPDATE_OPTIONS['resource'][key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disableGutters>
            <TextField
              fullWidth
              variant='standard'
              id='value'
              label={`${BILL_UPDATE_OPTIONS['resource'][updateBillResourceOption]} ID`}
              value={updateBillResourceId ? updateBillResourceId : ''}
              onChange={(e) => setUpdateBillResourceId(e.target.value)}
            />
          </ListItem>

          <ListItem disableGutters>
            <FormControl
              id='account-bill-date-method'
              variant='standard'
              fullWidth>
              <InputLabel id='account-bill-date-method-label'>
                Update Method
              </InputLabel>
              <Select
                labelId='account-bill-date-method-label'
                name='account-bill-date-method'
                onChange={(e) => {
                  setUpdateBillMethodOption(e.target.value);
                }}
                value={updateBillMethodOption}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  slotProps: {
                    paper: { sx: { minWidth: 'unset !important' } },
                  },
                }}
                sx={{
                  backgroundColor: 'unset',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}>
                <MenuItem value=''></MenuItem>
                {Object.keys(BILL_UPDATE_OPTIONS['method']).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {BILL_UPDATE_OPTIONS['method'][key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ListItem>
          <Button
            type='submit'
            onClick={handleUpdateBill}
            color='primary'
            variant='contained'
            disabled={!updateBillResourceId || isLoading}>
            Update Resource
          </Button>
        </List>
      </form>
    </div>
  );
}
