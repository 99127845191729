import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import get from 'lodash/get';

import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableEditRow,
  TableFilterRow,
  TableInlineCellEditing,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import TableCell from './cells/TableCell';
import Header from './Header';
import HeaderCell from './cells/HeaderCell';
import useTableWidth from '../../store/hooks/useTableWidth';

function BaseTable(props) {
  const {
    minWidth = 400,
    maxWidth = 900,
    rows,
    columns,
    children,
    editColumn,
    editRow,
    selection,
    inlineEditing,
  } = props;

  const tableColumnExtensions = props.tableColumnExtensions || [];
  const detail = props.detail || null;
  const filtering = props.filtering || true;
  const headerRow = props.headerRow || true;

  const width = useTableWidth(minWidth, maxWidth);
  const [columnWidths, setColumnWidths] = useState([]);

  useEffect(() => {
    const editColumnWidth = get(editColumn, 'props.width', 0);
    const availableWidth = width - editColumnWidth;

    if (availableWidth) {
      setColumnWidths(
        map(columns, (column) => {
          return {
            columnName: column.name,
            width: availableWidth * column.width,
          };
        })
      );
    }
  }, [columns, editColumn, width]);

  return (
    <Grid rows={rows} columns={columns}>
      {children}
      <Table
        columnExtensions={tableColumnExtensions}
        cellComponent={TableCell}
      />
      {selection && <TableSelection showSelectAll />}
      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
        resizingMode='nextColumn'
      />

      {headerRow && (
        <TableHeaderRow
          showSortingControls={filtering}
          contentComponent={Header}
          cellComponent={HeaderCell}
        />
      )}
      {inlineEditing && <TableInlineCellEditing selectTextOnEditStart />}
      {detail}
      {editColumn && editColumn}
      {editRow && <TableEditRow />}

      {filtering && rows.length > 10 && <TableFilterRow />}
      {rows.length > 10 && <PagingPanel />}
    </Grid>
  );
}

BaseTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  tableColumnExtensions: PropTypes.array,
  editColumn: PropTypes.object,
  editRow: PropTypes.bool,
  selection: PropTypes.bool,
  filtering: PropTypes.bool,
  inlineEditing: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default BaseTable;
