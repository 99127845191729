import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import ReduxToastr from 'react-redux-toastr';

import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { generateTheme } from './styles/muiTheme';
import _package from '../package.json';
import AppRoutes from './routes';
import { history, store } from './store/createStore';
import { ReduxLoader } from './components/Loaders/Redux';
import ErrorBoundary from './components/ErrorBoundary';

import './styles/index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

console.log('_package.version: ', _package.version);

function App() {
  const theme = React.useMemo(() => {
    return createTheme(generateTheme('light'));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ErrorBoundary>
          <HelmetProvider>
            <ReduxLoader />
            <Helmet>
              <title>Orchestrate Admin</title>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
            </Helmet>
            <ReduxToastr
              timeOut={10000}
              position='bottom-right'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
              progressBar
              preventDuplicates
              closeOnToastrClick
            />
            <AppRoutes />
          </HelmetProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function AppContainer() {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </ReduxProvider>
    </React.StrictMode>
  );
}

export default AppContainer;
