import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import reduce from 'lodash/reduce';

import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { getMetricNames } from './metrics';
import GroupContainer from './GroupContainer';
import KPITrendChart from './KPITrendChart';
import WidgetLoader from '../Loaders/WidgetLoader';
import { StyledSubtab, StyledSubtabs } from '../StyledSubtabs';

export default function KPIsPage(props) {
  const { metrics, fetchData } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(metrics)[0]);
  const [metricCounts, setMetricCounts] = useState(null);

  const fetchChartData = async () => {
    setLoading(true);
    const metricNames = getMetricNames(metrics);
    const start = dayjs().subtract(1, 'days').startOf('day').valueOf();
    const end = dayjs().valueOf();
    const payload = await fetchData(start, end, metricNames);
    const timestamps = Object.keys(payload);
    const mostRecent = Math.max(timestamps);

    let mostRecentData = payload[mostRecent];
    mostRecentData = reduce(
      mostRecentData,
      (acc, { metric, value }) => {
        return { ...acc, [metric]: value };
      },
      {}
    );
    setMetricCounts(mostRecentData);
    setLoading(false);
  };

  useEffect(() => {
    if (metricCounts === null) {
      fetchChartData();
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (e, groupName) => {
    setSelectedGroup(groupName);
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      {isMobile ? (
        <>
          <Grid item xs={12} mx={1}>
            <StyledSubtabs
              variant='fullWidth'
              sx={{ pb: 1 }}
              value={selectedGroup}
              onChange={handleSelect}>
              {Object.keys(metrics).map((metric) => (
                <StyledSubtab key={metric} label={metric} value={metric} />
              ))}
            </StyledSubtabs>
          </Grid>
          <Grid item xs={12} mt={0} display='flex' justifyContent='center'>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                mx: 1,
                maxWidth: 600,
                width: '100%',
              }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics[selectedGroup]}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={4}
            flexDirection='column'
            display='flex'
            justifyContent='flex-start'>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                mb: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.alarms}
                metricCounts={metricCounts}
              />
            </Card>
            <Card
              raised
              sx={{
                py: 1,
                px: 1,
                width: '100%',
              }}>
              <GroupContainer
                metrics={metrics.users}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
          <Grid item xs={4} display='flex' justifyContent='center'>
            <Card raised sx={{ py: 1, px: 1, width: '100%' }}>
              <WidgetLoader loading={loading} />
              <GroupContainer
                metrics={metrics.resources}
                metricCounts={metricCounts}
              />
            </Card>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        mb={2}
        ml={isMobile ? 0 : 1}
        mx={1}
        display='flex'
        justifyContent='center'>
        <KPITrendChart allMetrics={metrics} fetchData={fetchData} />
      </Grid>
    </Grid>
  );
}
