import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import styled from '@mui/material/styles/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabPanel from './TabPanel';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 60,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiTabs-scrollButtons': {
    color: theme.palette.primary.main,
  },
  minHeight: 'unset',
}));

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  paddingTop: 8,
  paddingBottom: 8,
  minHeight: 'unset',
  '&.Mui-selected': { fontWeight: 'bold' },
}));

function TabBar(props) {
  const { tabs, handleChangeTab, selectedTab, children } = props;
  const appbarRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { drawerExpanded } = useSelector((state) => state.app);

  const width = (() => {
    if (isMobile) return '100vw';
    const drawerWidth = drawerExpanded
      ? theme.drawer.width.expanded
      : theme.drawer.width.collapsed;
    return `calc(100vw - ${drawerWidth}px)`;
  })();

  const appbarHeight = appbarRef?.current?.offsetHeight || 48;
  const marginTop = isMobile ? '56px' : '64px';
  return (
    <Box>
      <AppBar
        ref={appbarRef}
        position='fixed'
        sx={{ backgroundColor: theme.palette.grey[200], marginTop, width }}>
        <StyledTabs
          scrollButtons
          allowScrollButtonsMobile
          value={selectedTab}
          onChange={handleChangeTab}
          indicatorColor='primary'
          textColor='primary'
          centered={!isMobile}
          variant={isMobile ? 'scrollable' : 'fullWidth'}>
          {map(tabs, (tab, idx) => {
            return (
              <StyledTab key={tab + idx} label={startCase(tab)} value={tab} />
            );
          })}
        </StyledTabs>
      </AppBar>

      <Box sx={{ height: appbarHeight + 'px ' }} />

      {map(children, (child) => {
        const tab = child.props.tab;
        return (
          <TabPanel key={tab} tab={tab} selectedTab={selectedTab}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
}

TabBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  handleChangeTab: PropTypes.func,
  tabIndex: PropTypes.number,
  children: PropTypes.array,
};

export default TabBar;
