import React from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import DialogTitleOptions from './DialogTitleOptions';

function BaseDialog(props) {
  const {
    id,
    title,
    disabled = true,
    handleClose,
    submitText = 'Save',
    handleSubmit = null,
    titleOptions = null,
    children,
  } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Dialog open={id !== ''} onClose={handleClose} fullScreen={isMobile}>
      <DialogTitle>
        {title}
        <DialogTitleOptions handleClose={handleClose}>
          {titleOptions}
        </DialogTitleOptions>
      </DialogTitle>
      <DialogContent sx={isMobile ? {} : { width: '100%', minWidth: 450 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {handleSubmit && (
          <Button
            type='submit'
            onClick={handleSubmit}
            color='primary'
            variant='contained'
            disabled={disabled}>
            {submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

BaseDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  submitText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  titleOptions: PropTypes.node,
  children: PropTypes.node,
};

export default BaseDialog;
