import React, { useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../components/StyledSubtabs';

import ReportTools from './ReportTools';
import LicenseTools from './LicenseTools';
import UtilityAccountTools from './UtilityAccountTools';
import PlugTools from './PlugTools';

const REPORT = 'Report Tools';
const LICENSE = 'License Tools';
const ACCOUNT = 'Utility Account Tools';
const PLUG = 'Plug Tools';
const SUBTABS = [REPORT, LICENSE, ACCOUNT, PLUG];

export default function Tools() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [tab, setTab] = useState(REPORT);

  const handleSelect = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Grid
      container
      justifyContent='center'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} mx={1} display='flex' justifyContent='center'>
        <StyledSubtabs
          variant='fullWidth'
          sx={{ maxWidth: 400, width: '100%' }}
          value={tab}
          onChange={handleSelect}>
          {SUBTABS.map((tab) => (
            <StyledSubtab key={tab} label={tab} value={tab} />
          ))}
        </StyledSubtabs>
      </Grid>
      {tab === REPORT && <ReportTools />}
      {tab === LICENSE && <LicenseTools />}
      {tab === ACCOUNT && <UtilityAccountTools />}
      {tab === PLUG && <PlugTools />}
    </Grid>
  );
}
