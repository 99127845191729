import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import useMediaQuery from '@mui/material/useMediaQuery';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { SOLAR_APP_ID } from '../../../../store/solar';
import CostsChart from './CostsChart';
import CostsChartOptions from './CostsChartOptions';
import CostsChartFilters from './CostsChartFilters';
import WebAPIClient from '../../../../api';

const ENV = process.env.REACT_APP_ENV;

const prepChartData = (data, range, metric) => {
  const filteredData = data.filter((dayData) => {
    const timestamp = dayjs(get(dayData, 'TimePeriod.Start')).unix();
    return timestamp >= range.start.unix() && timestamp <= range.end.unix();
  });

  return map(filteredData, (dayData) => {
    const groups = get(dayData, 'Groups');
    const total = get(dayData, 'Total');

    let values = [];
    if (groups && groups.length) {
      values = reduce(
        groups,
        (acc, group) => {
          const key = kebabCase(get(group, 'Keys.0'));
          const amount = Number(get(group, `Metrics.${metric}.Amount`));
          if (amount > 0) {
            return { ...acc, [key]: amount };
          }
          return acc;
        },
        {}
      );
    } else if (Object.keys(total).length > 0) {
      values = { total: Number(get(total, `${metric}.Amount`)) };
    }

    return {
      timestamp: dayjs(get(dayData, 'TimePeriod.Start')).unix(),
      ...values,
    };
  });
};

const prepGroupByParams = (type, key) => {
  if (key === 'None') {
    return [null, null];
  }
  return [type, key];
};

const prepFilterParams = (filters) => {
  return filters.reduce((acc, filter, idx) => {
    acc[`filter${idx}`] = `${filter.tag}$${filter.value}`;
    return acc;
  }, {});
};

export default function Costs() {
  const optionsRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { initial: initialCostsData, loading: costsLoading } = useSelector(
    (state) => state.costs
  );

  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [demo, setDemo] = useState(ENV === 'dev' ? true : false);
  const [range, setRange] = useState(() => {
    const start = dayjs()
      .subtract(1, 'days')
      .subtract(2, 'month')
      .startOf('day');
    const end = dayjs().subtract(1, 'days').endOf('day');
    return { key: 4, label: 'Custom Range', start, end };
  });
  const [granularity, setGranularity] = useState('DAILY');
  const [metric, setMetric] = useState('UnblendedCost');
  const [groupByType, setGroupByType] = useState('TAG');
  const [groupByKey, setGroupByKey] = useState('app');
  const [filters, setFilters] = useState(
    ENV === 'dev' ? [] : [{ tag: 'env', value: ENV }]
  );

  useEffect(() => {
    if (initialCostsData) {
      setChartData(prepChartData(initialCostsData, range, 'UnblendedCost'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCostsData]);

  const fetchChartData = async (options) => {
    setLoading(true);
    setChartData([]);
    const {
      _range,
      _granularity,
      _metric,
      _groupByType,
      _groupByKey,
      _filters,
      _demo,
    } = options;

    const demoParam = _demo === undefined ? demo : _demo;
    const rangeParam = _range || range;
    const filterParams = prepFilterParams(_filters || filters);
    const [type, key] = prepGroupByParams(
      _groupByType || groupByType,
      _groupByKey || groupByKey
    );

    const response = await new WebAPIClient(SOLAR_APP_ID).GET(
      '/resource/costs',
      {
        start: rangeParam.start.valueOf(),
        end: rangeParam.end.valueOf() + 1,
        granularity: _granularity || granularity,
        metric: _metric || metric,
        groupByType: type,
        groupByKey: key,
        demo: demoParam,
        ...filterParams,
      }
    );
    setChartData(prepChartData(response, rangeParam, metric));
    setLoading(false);
  };

  const handleRangeSelect = (_range) => {
    setRange(_range);
    fetchChartData({ _range });
  };

  const handleSetGranularity = (_granularity) => {
    setGranularity(_granularity);
    fetchChartData({ _granularity });
  };

  const handleSelectMetric = (_metric) => {
    setMetric(_metric);
    fetchChartData({ _metric });
  };

  const handleChangeGroupBy = (_groupByType, _groupByKey) => {
    setGroupByType(_groupByType);
    setGroupByKey(_groupByKey);
    fetchChartData({ _groupByType, _groupByKey });
  };

  const handleChangeFilters = (_filters) => {
    setFilters(_filters);
    fetchChartData({ _filters });
  };

  const handleChangeDemo = (event) => {
    const _demo = event.target.checked;
    setDemo(_demo);
    fetchChartData({ _demo });
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant='h5' align='center'>
          AWS Costs Chart
        </Typography>

        {ENV === 'dev' && (
          <FormControlLabel
            value={demo}
            control={
              <Switch
                color='primary'
                value={demo}
                checked={demo}
                onChange={handleChangeDemo}
              />
            }
            label='Demo data'
            labelPlacement='start'
            sx={{ ml: 4 }}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        px={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        <CostsChartOptions
          ref={optionsRef}
          range={range}
          handleRangeSelect={handleRangeSelect}
          granularity={granularity}
          handleSetGranularity={handleSetGranularity}
          metric={metric}
          handleSelectMetric={handleSelectMetric}
          groupByType={groupByType}
          groupByKey={groupByKey}
          handleChangeGroupBy={handleChangeGroupBy}
        />
      </Grid>
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center' }}>
        <CostsChartFilters
          width={optionsRef?.current?.offsetWidth}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
        />
      </Grid>
      <Grid item xs={12} mx={1} mt={isMobile ? 1 : 0}>
        <CostsChart
          range={range}
          chartData={chartData}
          granularity={granularity}
          loading={loading || costsLoading}
        />
      </Grid>
    </>
  );
}
