import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { costs as initialState } from '../initialState';

import { getInitalCosts } from './_costs';

const { reducer } = createSlice({
  name: 'costs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [getInitalCosts]);
  },
});

export { getInitalCosts };
export default reducer;
