import React from 'react';

import useTheme from '@mui/material/styles/useTheme';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

export default function TimezoneField(props) {
  const { timezone } = props;
  const theme = useTheme();

  return (
    <Tooltip
      title={
        <List disablePadding>
          <ListItemText secondary='Zone' primary={timezone.zone} />
          <ListItemText secondary='Abbreviation' primary={timezone.name} />
          <ListItemText secondary='Offset (hours)' primary={timezone.offset} />
        </List>
      }>
      <TextField
        variant='standard'
        label='Timezone'
        value={timezone.name}
        InputProps={{ readOnly: true, disableUnderline: true }}
        sx={{
          '& .MuiInputLabel-root': {
            textAlign: 'center',
            width: '6rem',
          },
          input: {
            color: theme.palette.primary.main,
            py: 0,
            width: '4rem',
            textAlign: 'center',
          },
        }}
      />
    </Tooltip>
  );
}
