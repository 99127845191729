import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectIpAddress(props) {
  const { ipAddresses, selectedIpAddress, setSelectedIpAddress } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedIpAddress(e.target.value);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='select-ip-address-label'
        sx={{ textAlign: 'center', width: '8rem' }}>
        Select IP Address
      </InputLabel>
      <Select
        disableUnderline
        labelId='select-ip-address-label'
        name='select-ip-address'
        value={selectedIpAddress || 'all'}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={selectedIpAddress || 'All'}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        sx={{
          minWidth: 100,
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem
          key='all'
          value=''
          sx={{
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          }}>
          All
        </MenuItem>
        {ipAddresses.map((ipAddress) => {
          return (
            <MenuItem
              key={ipAddress}
              value={ipAddress}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {ipAddress}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectIpAddress.propTypes = {
  appId: PropTypes.string,
  ipAddresses: PropTypes.array,
  selectedIpAddress: PropTypes.string,
  setSelectedIpAddress: PropTypes.func,
};

export default SelectIpAddress;
