import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { cognito as initialState } from '../initialState';

import {
  getCognitoUsers,
  putCognitoUser,
  resendConfirmationEmail,
} from './_cognito';

const { reducer } = createSlice({
  name: 'cognito',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getCognitoUsers,
      putCognitoUser,
      resendConfirmationEmail,
    ]);
  },
});

export { getCognitoUsers, putCognitoUser, resendConfirmationEmail };
export default reducer;
