import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Costs from './Costs';

export default function AWS() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={isMobile ? 1 : 0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Costs />
    </Grid>
  );
}
