import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import find from 'lodash/find';

import { ROUTES } from '../../../helpers/constants';
import generateNodes from '../../../helpers/solarTree';
import { SOLAR_APP_ID } from '../../../store/solar';
import TabBar from '../../../components/Tab/TabBar';
import LicensesGrid from '../../../components/LicensesGrid';
import KioskUsage from '../../../components/KioskUsage';
import MembershipsGrid from '../../../components/MembershipsGrid';
import UsersGrid from '../../../components/UsersGrid';
import ResourcesPage from '../ResourcesPage';
import KPIs from './KPIs';
import Backfill from './Backfill';

const RESOURCES = 'resources';
const LICENSES = 'licenses';
const USERS = 'users';
const MEMBERSHIPS = 'memberships';
const KPIS = 'kpis';
const BACKFILL = 'backfill';
const KIOSK_USAGE = 'kiosk-usage';
const TABS = [
  RESOURCES,
  LICENSES,
  USERS,
  MEMBERSHIPS,
  KPIS,
  BACKFILL,
  KIOSK_USAGE,
];

export default function LightLevel() {
  const dispatch = useDispatch();
  const location = useLocation();

  const solarState = useSelector((state) => state.solar);
  const [selectedTab, setSelectedTab] = useState(RESOURCES);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      let _tab = find(TABS, (value) => value === hash);
      setSelectedTab(_tab);
    } else {
      setSelectedTab(RESOURCES);
    }
  }, [location.hash]);

  useEffect(() => {
    if (!solarState.loading) {
      setNodes(generateNodes(solarState));
    }
  }, [solarState]);

  const handleChangeTab = (event, value) => {
    dispatch(push(ROUTES.LIGHTLEVEL.path + '#' + value));
  };

  return (
    <TabBar
      tabs={TABS}
      handleChangeTab={handleChangeTab}
      selectedTab={selectedTab}>
      <ResourcesPage
        tab={RESOURCES}
        appId={SOLAR_APP_ID}
        nodes={nodes}
        path={ROUTES.LIGHTLEVEL.path}
      />
      <LicensesGrid tab={LICENSES} appId={SOLAR_APP_ID} />
      <UsersGrid tab={USERS} appId={SOLAR_APP_ID} />
      <MembershipsGrid tab={MEMBERSHIPS} appId={SOLAR_APP_ID} />
      <KPIs tab={KPIS} />
      <Backfill tab={BACKFILL} />
      <KioskUsage tab={KIOSK_USAGE} appId={SOLAR_APP_ID} />
    </TabBar>
  );
}
