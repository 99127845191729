import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import SiteCard from './Card';
import SiteResources from './Resources';
import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../../components/StyledSubtabs';

export default function SitePage(props) {
  const { site } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [tab, setTab] = useState(0);

  const handleChange = (e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        mt={0}
        pt={'0 !important'}
        sx={{ display: 'flex', justifyContent: 'center' }}>
        <StyledSubtabs
          value={tab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='fullWidth'
          sx={{
            width: 400,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'building']} size='xl' />}
          />
          <StyledSubtab
            icon={<FontAwesomeIcon icon={['fal', 'sitemap']} size='xl' />}
          />
        </StyledSubtabs>
      </Grid>
      <Grid
        item
        xs={12}
        mt={isMobile ? 1 : 0}
        px={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '100vw',
        }}>
        {tab === 0 && <SiteCard site={site} />}
        {tab === 1 && <SiteResources site={site} />}
      </Grid>
    </>
  );
}
