import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { ROUTES } from '../../../../../helpers/constants';
import CreateResourceListButton from '../../../../../components/Buttons/CreateResourceListButton';
import MeterDialog from '../../../../../components/Dialogs/solar/MeterDialog';
import SensorDialog from '../../../../../components/Dialogs/solar/SensorDialog';
import InverterDialog from '../../../../../components/Dialogs/solar/InverterDialog';

export default function SiteResources(props) {
  const { logger } = props;
  const dispatch = useDispatch();
  const solarState = useSelector((state) => state.solar);

  const [meters, setMeters] = useState([]);
  const [sensors, setSensor] = useState([]);
  const [inverters, setInverters] = useState([]);
  const [meter1Id, setMeter1Id] = useState('');
  const [openMeter, setOpenMeter] = useState(false);
  const [openSensor, setOpenSensor] = useState(false);
  const [openInverter, setOpenInverter] = useState(false);

  useEffect(() => {
    const { meters, inverters, sensors } = solarState;
    const loggerMeters = filter(meters, { logger_id: logger.logger_id });

    const meterIds = map(loggerMeters, 'meter_id');
    const loggerSensors = filter(sensors, (sensor) => {
      return includes(meterIds, sensor.meter_id);
    });
    const loggerInverters = filter(inverters, (inverter) => {
      return includes(meterIds, inverter.meter_id);
    });

    setMeters(sortBy(loggerMeters, 'parent_index'));
    setSensor(sortBy(loggerSensors, 'parent_index'));
    setInverters(sortBy(loggerInverters, 'parent_index'));
  }, [logger, solarState]);

  useEffect(() => {
    const meter1 = meters.find((meter) => meter.parent_index === 1);
    if (meter1) {
      setMeter1Id(meter1.meter_id);
    } else {
      setMeter1Id('');
    }
  }, [meters]);

  const handleClick = (itemId) => {
    dispatch(push(ROUTES.LIGHTLEVEL.path, { itemId }));
  };

  const handleClose = () => {
    setOpenMeter(false);
    setOpenSensor(false);
    setOpenInverter(false);
  };

  return (
    <>
      <Card raised sx={{ width: 400 }}>
        <List disablePadding>
          <CreateResourceListButton
            label='Create Meter'
            setOpen={setOpenMeter}
            disabled={meters.length >= 2}
          />
          <CreateResourceListButton
            label='Create Sensor'
            setOpen={setOpenSensor}
            disabled={sensors.length >= 2}
          />
          <CreateResourceListButton
            label='Create Inverter'
            setOpen={setOpenInverter}
          />
          {meters.length > 0 && <Divider />}
          {meters.length > 1 && (
            <ListSubheader
              sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
              <ListItemText
                secondary='Meters'
                secondaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListSubheader>
          )}
          {map(meters, (meter) => (
            <ListItemButton
              key={meter.meter_id}
              onClick={() => handleClick(meter.meter_id)}>
              <ListItemText secondary={meter.name} />
              <IconButton sx={{ height: 30, width: 30 }}>
                <FontAwesomeIcon icon={['fal', 'bolt']} size='sm' />
              </IconButton>
            </ListItemButton>
          ))}
          {sensors.length > 0 && <Divider />}
          {sensors.length > 1 && (
            <ListSubheader
              sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
              <ListItemText
                secondary='Sensors'
                secondaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListSubheader>
          )}
          {map(sensors, (sensor) => (
            <ListItemButton
              key={sensor.sensor_id}
              onClick={() => handleClick(sensor.sensor_id)}>
              <ListItemText secondary={sensor.name} />
              <IconButton sx={{ height: 30, width: 30 }}>
                <FontAwesomeIcon icon={['fal', 'thermometer-half']} size='sm' />
              </IconButton>
            </ListItemButton>
          ))}
          {inverters.length > 0 && <Divider />}
          {inverters.length > 1 && (
            <ListSubheader
              sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
              <ListItemText
                secondary='Inverters'
                secondaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListSubheader>
          )}
          {map(inverters, (inverter) => (
            <ListItemButton
              key={inverter.inverter_id}
              onClick={() => handleClick(inverter.inverter_id)}>
              <ListItemText secondary={inverter.name} />
              <Chip
                label={(inverter.ac_size ? inverter.ac_size : '-') + ' kw'}
                sx={{ mr: 1 }}
              />
              <IconButton sx={{ height: 30, width: 30 }}>
                <FontAwesomeIcon icon={['fal', 'exchange']} size='sm' />
              </IconButton>
            </ListItemButton>
          ))}
        </List>
      </Card>

      <MeterDialog
        open={openMeter}
        closeDialog={handleClose}
        orgId={logger.org_id}
        siteId={logger.site_id}
        loggerId={logger.logger_id}
      />
      <SensorDialog
        open={openSensor}
        closeDialog={handleClose}
        orgId={logger.org_id}
        meterId={meter1Id}
        loggerId={logger.logger_id}
      />
      <InverterDialog
        open={openInverter}
        closeDialog={handleClose}
        orgId={logger.org_id}
        loggerId={logger.logger_id}
        meterId={meter1Id}
      />
    </>
  );
}
