import React, { useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

import WeatherChartContainer from './WeatherChart';
import {
  StyledSubtab,
  StyledSubtabs,
} from '../../../../components/StyledSubtabs';
import ModelingChartContainer from './ModelingChart';

const WEATHER = 'Aggregated Weather';
const PJM_MARKETS = 'PJM Markets';
const SUBTABS = [WEATHER, PJM_MARKETS];

export default function PJMMarkets(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [tab, setTab] = useState(WEATHER);

  const handleSelect = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Grid
      container
      justifyContent='center'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      mt={0}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%' }}>
      <Grid item xs={12} mx={1} display='flex' justifyContent='center'>
        <StyledSubtabs
          variant='fullWidth'
          sx={{ maxWidth: 400, width: '100%' }}
          value={tab}
          onChange={handleSelect}>
          {SUBTABS.map((tab) => (
            <StyledSubtab key={tab} label={tab} value={tab} />
          ))}
        </StyledSubtabs>
      </Grid>
      {tab === WEATHER && <WeatherChartContainer />}
      {tab === PJM_MARKETS && <ModelingChartContainer />}
    </Grid>
  );
}
