import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const boxStyle = { width: '1rem', height: '1rem', marginRight: '10px' };

export default function ShedInfoButton() {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'popover-meter-filter' : undefined;

  return (
    <>
      <Tooltip title='information'>
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ width: 35, height: 35, mb: '8px !important' }}>
          <FontAwesomeIcon icon={['fal', 'info-circle']} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}>
        <List disablePadding>
          <ListItem key='grey-explanation'>
            <Box sx={{ ...boxStyle, bgcolor: 'grey' }} />
            <Typography>
              Meter&apos;s shed value is manually set to inactive
            </Typography>
          </ListItem>
          <ListItem key='light-grey-explanation'>
            <Box sx={{ ...boxStyle, bgcolor: 'lightgrey' }} />
            <Typography>
              No manual shed, using RTO shed set to inactive
            </Typography>
          </ListItem>
          <ListItem key='light-green-explanation'>
            <Box sx={{ ...boxStyle, bgcolor: 'lightgreen' }} />
            <Typography>
              No manual shed, using RTO shed set to active
            </Typography>
          </ListItem>
          <ListItem key='green-explanation'>
            <Box sx={{ ...boxStyle, bgcolor: 'green' }} />
            <Typography>
              Meter&apos;s shed value is manually set to active
            </Typography>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
