import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

import { defaultState } from '../../components/Forms/OrganizationForm';

const useOrganization = (orgId, appId) => {
  const organizations = useSelector((state) => state[appId].organizations);
  const [organization, setOrganization] = useState(defaultState);

  useEffect(() => {
    if (orgId && orgId.startsWith('org') && organizations.length) {
      setOrganization(find(organizations, { org_id: orgId }));
    } else {
      setOrganization(defaultState);
    }
  }, [organizations, orgId]);

  return organization;
};

export default useOrganization;
