import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldListItem } from './TextFieldListItem';

export default function ResourceListItem(props) {
  const { label, name, onClick = null, icon } = props;

  return (
    <TextFieldListItem
      label={label}
      value={name || ''}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={onClick} sx={{ height: 35, width: 35 }}>
              <FontAwesomeIcon icon={['fal', icon]} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
