import get from 'lodash/get';
import isArray from 'lodash/isArray';
import toLower from 'lodash/toLower';
import uniqBy from 'lodash/uniqBy';

export const findAllNodes = (text, items) => {
  var i = 0,
    found,
    result = [];

  for (; i < items.length; i++) {
    const label = get(items, `${i}.label`);
    if (toLower(label).includes(toLower(text))) {
      result.push(items[i]);
    } else if (isArray(items[i].children)) {
      found = findAllNodes(text, items[i].children);
      if (found.length) {
        result = result.concat(found);
      }
    }
  }
  return uniqBy(result, 'id');
};
