import React, { useEffect, useState } from 'react';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useTheme } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';

const FORCE_ACTIVE = {
  text: 'force_active',
  checked: true,
  message: 'Force Active',
};
const FORCE_INACTIVE = {
  text: 'force_inactive',
  checked: false,
  message: 'Force Inactive',
};
const ACTIVE = {
  text: 'active',
  checked: true,
  message: 'Active Stripe subscription',
};
const INACTIVE = {
  text: 'inactive',
  checked: false,
  message: 'Inactive Stripe subscription',
};

const CustomCheckboxPopover = (props) => {
  const { row: license, handleClick } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState('');
  const [value, setValue] = useState(INACTIVE);

  useEffect(() => {
    if (license.force_active === true) {
      // FORCE ACTIVE
      setValue(FORCE_ACTIVE);
      setColor(theme.palette.primary.main);
    } else if (license.force_active === false) {
      // FORCE INACTIVE
      setValue(FORCE_INACTIVE);
      setColor(theme.palette.primary.main);
    } else if (license.active === true) {
      // ACTIVE
      setValue(ACTIVE);
      setColor(theme.palette.skeleton);
    } else if (
      license.active === false ||
      isNull(license.active) ||
      isUndefined(license.active)
    ) {
      // INACTIVE
      setValue(INACTIVE);
      setColor(theme.palette.skeleton);
    }
  }, [license, theme]);

  const handleIconClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `popover-${license.org_id}-${license.name}` : undefined;
  return (
    <>
      <Tooltip placement='left' title={value.message}>
        <Checkbox
          checked={value.checked}
          onClick={handleIconClick}
          sx={{
            color,
            '&.Mui-checked': { color },
          }}
        />
      </Tooltip>
      {handleClick && (
        <Popover
          key={`popover-${license.org_id}-${license.name}`}
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}>
          <List dense disablePadding>
            <ListItemButton
              key='list-item-auto'
              disabled={['active', 'inactive'].includes(value.text)}
              selected={['active', 'inactive'].includes(value.text)}
              onClick={() => {
                handleClick(license, null);
                handleClose();
              }}>
              auto
            </ListItemButton>
            <ListItemButton
              key='list-item-active'
              disabled={value.text === 'force_active'}
              selected={value.text === 'force_active'}
              onClick={() => {
                handleClick(license, true);
                handleClose();
              }}>
              activate
            </ListItemButton>
            <ListItemButton
              key='list-item-inactive'
              disabled={value.text === 'force_inactive'}
              selected={value.text === 'force_inactive'}
              onClick={() => {
                handleClick(license, false);
                handleClose();
              }}>
              deactivate
            </ListItemButton>
          </List>
        </Popover>
      )}
    </>
  );
};

export const SelectTypeProvider = (props) => {
  const { handleClick } = props;
  return (
    <DataTypeProvider
      formatterComponent={(innerProps) => {
        return (
          <CustomCheckboxPopover {...innerProps} handleClick={handleClick} />
        );
      }}
      {...props}
    />
  );
};
