import React from 'react';
import PropTypes from 'prop-types';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function SelectUserAgent(props) {
  const { userAgents, selectedUserAgent, setSelectedUserAgent } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    setSelectedUserAgent(e.target.value);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='select-user-agent-label'
        sx={{ textAlign: 'center', width: '10rem' }}>
        Select User Agent
      </InputLabel>
      <Select
        disableUnderline
        labelId='select-user-agent-label'
        name='select-user-agent'
        value={selectedUserAgent || 'all'}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={selectedUserAgent || 'All'}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        sx={{
          minWidth: 100,
          maxWidth: 200,
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        <MenuItem
          key='all'
          value=''
          sx={{
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          }}>
          All
        </MenuItem>
        {userAgents.map((userAgent) => {
          return (
            <MenuItem
              key={userAgent}
              value={userAgent}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {userAgent}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectUserAgent.propTypes = {
  appId: PropTypes.string,
  ipAddresses: PropTypes.array,
  selectedUserAgent: PropTypes.string,
  setSelectedUserAgent: PropTypes.func,
};

export default SelectUserAgent;
