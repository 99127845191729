import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import CreateButton from './CreateButton';

function SearchBar(props) {
  const { appId, searchText, setSearchText } = props;
  const theme = useTheme();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Paper
      component='form'
      sx={{
        px: '4px',
        pt: '6px',
        pb: '5px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: 400,
        boxShadow: (theme) => theme.shadows[8],
      }}>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Search'
        value={searchText}
        onChange={handleChange}
      />
      <Tooltip placement='top' title='clear'>
        <IconButton
          onClick={() => setSearchText('')}
          sx={{ height: 35, width: 35 }}>
          <FontAwesomeIcon
            icon={['fal', 'xmark']}
            color={theme.palette.text.secondary}
            size='sm'
          />
        </IconButton>
      </Tooltip>
      <Divider orientation='vertical' />
      <CreateButton appId={appId} />
    </Paper>
  );
}

export default SearchBar;
