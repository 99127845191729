import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { postSolarInverter } from '../../../store/solar';
import InverterForm, {
  defaultInverterState,
} from '../../Forms/solar/InverterForm';
import BaseDialog from '../BaseDialog';

function InverterDialog(props) {
  const { open, closeDialog, orgId, loggerId, meterId } = props;
  const dispatch = useDispatch();

  const meters = useSelector((state) => state.solar.meters);
  const inverters = useSelector((state) => state.solar.inverters);

  const [parentIndex, setParentIndex] = useState(1);
  const [inverter, setInverter] = useState(defaultInverterState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const meterIds = meters
      .filter((meter) => meter.logger_id === loggerId)
      .map((meter) => meter.meter_id);
    const loggerInverters = inverters.filter((inverter) =>
      meterIds.includes(inverter.meter_id)
    );

    setParentIndex(loggerInverters.length + 1);
  }, [meters, inverters, loggerId]);

  useEffect(() => {
    setInverter((prevInverter) => ({
      ...prevInverter,
      org_id: orgId,
      meter_id: meterId,
      parent_index: parentIndex,
      name: `Inverter ${parentIndex}`,
    }));
  }, [orgId, meterId, parentIndex]);

  useEffect(() => {
    if (inverter.name.length === 0) {
      setMessage('Inverter name required');
    } else {
      setMessage('');
    }
  }, [inverter]);

  const handleChange = (e) => {
    const id = e.target.id || e.target.name;
    const value = e.target.value;
    setInverter((prevInverter) => ({ ...prevInverter, [id]: value }));
  };

  const handleClose = () => {
    closeDialog();
    setInverter({
      ...defaultInverterState,
      org_id: orgId,
      meter_id: meterId,
      parent_index: parentIndex,
      name: `Inverter ${parentIndex}`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      toastr.warning(message);
    } else {
      dispatch(postSolarInverter(inverter));
      handleClose();
    }
  };

  const id = open ? 'create-inverter-dialog' : '';
  return (
    <BaseDialog
      id={id}
      disabled={message.length > 0}
      submitText='Create'
      title='Create Inverter'
      handleSubmit={handleSubmit}
      handleClose={handleClose}>
      <InverterForm
        mode='create'
        inverter={inverter}
        setInverter={setInverter}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        expanded={true}
      />
    </BaseDialog>
  );
}

export default InverterDialog;
