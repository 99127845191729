import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import { findAllNodes } from '../helpers/nodes';
import SearchBar from './SearchBar';
import ResourceTree from './ResourceTree';
import SelectOrganization from './Selectors/SelectOrganization';

function ResourcesGrid(props) {
  const { nodes, path, appId } = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { organizations: allOrganizations } = useSelector(
    (state) => state[appId]
  );

  const [searchText, setSearchText] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState({});

  const [treeNodes, setTreeNodes] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    const _organizations = allOrganizations.filter((org) => !org.is_portfolio);
    const _portfolios = allOrganizations.filter((org) => org.is_portfolio);
    setOrganizations(sortBy(_organizations, 'name'));
    setPortfolios(sortBy(_portfolios, 'name'));
  }, [allOrganizations]);

  useEffect(() => {
    let _nodes = nodes;
    if (selectedOrganization) {
      _nodes = findAllNodes(selectedOrganization.name, nodes);
    }

    if (searchText) {
      _nodes = findAllNodes(searchText, _nodes);
    }

    setTreeNodes(_nodes);
  }, [nodes, searchText, selectedOrganization]);

  const selectOrganization = (orgId) => {
    setSelectedOrganization(find(allOrganizations, { org_id: orgId }));
  };

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='stretch'
      spacing={isMobile ? 0 : 1}
      padding={isMobile ? 0 : 1}
      px={isMobile ? 0 : 1}
      sx={{ maxWidth: '100%', pt: 0 }}>
      <Grid
        item
        xs={12}
        mx={1}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <Card raised sx={{ width: '100%', maxWidth: 400 }}>
          <SelectOrganization
            label='Select Organization'
            variant='filled'
            showAllOption
            portfolios={portfolios}
            organizations={organizations}
            orgId={selectedOrganization?.org_id || ''}
            setOrgId={(orgId) => selectOrganization(orgId)}
          />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        mx={1}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <SearchBar
          appId={appId}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </Grid>
      <Grid
        item
        xs={12}
        mx={1}
        mt={isMobile ? 1 : 0}
        sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100vw' }}>
        <ResourceTree nodes={treeNodes} path={path} />
      </Grid>
    </Grid>
  );
}

export default ResourcesGrid;
