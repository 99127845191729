import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { push } from 'redux-first-history';

import { ROUTES } from '../../helpers/constants';
import WebAPIClient, { errorResponseToastr } from '../../api';

import { SOLAR_APP_ID } from '.';

const authenticateSolarUser = createAsyncThunk(
  'solar/authenticateSolarUser',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      const user = await new WebAPIClient(SOLAR_APP_ID).GET(
        '/resource/admin/init_auth'
      );
      return { user };
    } catch (err) {
      console.warn('Solar Authentication Error');
      throw new Error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getSolarResources = createAsyncThunk(
  'solar/getSolarResources',
  async (_, { dispatch }) => {
    try {
      dispatch(showLoading());
      const webClient = new WebAPIClient(SOLAR_APP_ID);

      const responses = await Promise.all([
        webClient.GET('/resource/admin/alarms'),
        webClient.GET('/resource/admin/memberships'),
        webClient.GET('/resource/admin/organizations'),
        webClient.GET('/resource/admin/portfolio_memberships'),
        webClient.GET('/resource/admin/sites'),
        webClient.GET('/resource/admin/licenses'),
        webClient.GET('/resource/admin/loggers'),
        webClient.GET('/resource/admin/meters'),
        webClient.GET('/resource/admin/inverters'),
        webClient.GET('/resource/admin/sensors'),
        webClient.GET('/resource/admin/users'),
      ]);
      const alarms = responses[0];
      const memberships = responses[1];
      const organizations = responses[2];
      const portfolioMemberships = responses[3];
      const sites = responses[4];
      const licenses = responses[5];
      const loggers = responses[6];
      const meters = responses[7];
      const inverters = responses[8];
      const sensors = responses[9];
      const users = responses[10];

      return {
        alarms,
        memberships,
        organizations,
        portfolioMemberships,
        sites,
        licenses,
        loggers,
        meters,
        inverters,
        sensors,
        users,
      };
    } catch (err) {
      errorResponseToastr(err);
      dispatch(push(ROUTES.LANDING.path));
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { authenticateSolarUser, getSolarResources };
