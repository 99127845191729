import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import { deleteSolarLogger, putSolarLogger } from '../../../../../store/solar';
import { openConfirmDialog } from '../../../../../store/dialogs';
import LoggerForm from '../../../../../components/Forms/solar/LoggerForm';

function LoggerCard(props) {
  const { logger: stateLogger } = props;
  const dispatch = useDispatch();

  const [logger, setLogger] = useState(stateLogger);

  const handleDelete = () => {
    dispatch(
      openConfirmDialog({
        title: 'Delete Logger',
        message: <p>Are you sure you want to delete this data logger?</p>,
        onConfirm: () => dispatch(deleteSolarLogger(logger)),
      })
    );
  };

  const handleSubmit = () => {
    dispatch(putSolarLogger(logger));
  };

  return (
    <Card raised sx={{ width: 400, px: 2, py: 1 }}>
      <LoggerForm
        mode='edit'
        logger={logger}
        setLogger={setLogger}
        handleSubmit={handleSubmit}
      />
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button variant='outlined' color='error' onClick={handleDelete}>
          Delete
        </Button>
        <Button variant='contained' onClick={handleSubmit}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}

export default LoggerCard;
