import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import useTheme from '@mui/material/styles/useTheme';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EclypseOptions, { METHOD as EclypseMethod } from './Eclypse/Options';
import CSVOptions, { METHOD as CSVMethod } from './CSV/Options';
import ManualOptions, { METHOD as ManualMethod } from './Manual/Options';
import InverterOptions, { METHOD as InverterMethod } from './Inverter/Options';

export const METHODS = [
  {
    ...EclypseMethod,
    component: EclypseOptions,
  },
  {
    ...CSVMethod,
    component: CSVOptions,
  },
  {
    ...ManualMethod,
    component: ManualOptions,
  },
  {
    ...InverterMethod,
    component: InverterOptions,
  },
];

function SelectBackfillMethod(props) {
  const { method, setMethod } = props;
  const theme = useTheme();

  const handleSelect = (e) => {
    e.preventDefault();
    let _method = find(METHODS, { name: e.target.value });
    setMethod(_method);
  };

  return (
    <FormControl variant='standard'>
      <InputLabel
        id='backfill-method-label'
        sx={{ textAlign: 'center', width: '8rem' }}>
        Backfill Method
      </InputLabel>
      <Select
        disableUnderline
        labelId='backfill-method-label'
        name='backfill-method'
        value={method.name}
        renderValue={() => (
          <ListItemText
            sx={{ color: theme.palette.primary.main, my: 0 }}
            primary={method.name}
            primaryTypographyProps={{ align: 'center' }}
          />
        )}
        onChange={handleSelect}
        IconComponent={() => null}
        inputProps={{
          sx: {
            padding: '0 !important',
            color: theme.palette.primary.main,
            ...theme.typography.body2,
          },
        }}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        sx={{
          minWidth: 100,
          backgroundColor: 'unset',
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}>
        {METHODS.map((method) => {
          return (
            <MenuItem
              key={method.name}
              value={method.name}
              sx={{
                color: theme.palette.primary.main,
                ...theme.typography.body2,
              }}>
              {method.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectBackfillMethod.propTypes = {
  method: PropTypes.object,
  setMethod: PropTypes.func,
};

export default SelectBackfillMethod;
