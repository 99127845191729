import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import OrganizationDialog from '../Dialogs/OrganizationDialog';

function CreateButton(props) {
  const { appId } = props;
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
    setType(null);
  };

  const handleOrgTypeSelect = (type) => {
    setType(type);
    setOpen(true);
    handleClosePopover();
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? `popover-org-type-select` : undefined;

  return (
    <>
      <Tooltip placement='top' title='create organization'>
        <IconButton onClick={handleClick} sx={{ height: 35, width: 35 }}>
          <FontAwesomeIcon
            icon={['fal', 'plus-circle']}
            color={theme.palette.text.secondary}
            size='sm'
          />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}>
        <List disablePadding>
          <ListItemButton onClick={() => handleOrgTypeSelect('portfolio')}>
            <Typography>Portfolio</Typography>
          </ListItemButton>
          <ListItemButton onClick={() => handleOrgTypeSelect('organization')}>
            <Typography>Organization</Typography>
          </ListItemButton>
        </List>
      </Popover>
      <OrganizationDialog
        appId={appId}
        type={type}
        open={open}
        closeDialog={handleClose}
      />
    </>
  );
}

export default CreateButton;
