import { toastr } from 'react-redux-toastr';
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider';

import { removeTokens, setTokens } from './tokens';

const REGION = process.env.REACT_APP_REGION;

class CognitoIdpSdkClient {
  constructor() {
    this.client = new CognitoIdentityProviderClient({ region: REGION });
  }
  async execute(Command, input) {
    return await this.client.send(new Command(input));
  }
}

const refreshTokens = async (clientId, appName, refreshToken) => {
  try {
    const response = await new CognitoIdpSdkClient().execute(
      InitiateAuthCommand,
      {
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: { REFRESH_TOKEN: refreshToken },
        ClientMetadata: { app: appName },
        ClientId: clientId,
      }
    );

    const payload = response.AuthenticationResult;
    setTokens(clientId, payload);

    return { accessToken: payload.AccessToken, idToken: payload.IdToken };
  } catch (error) {
    toastr.error('Error', 'Current session expired');
    removeTokens();
    window.location.href = '/login';
  }
};

export { refreshTokens };
