import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { defaultState } from '../../components/Forms/solar/SiteForm';

const useSite = (siteId, appId) => {
  const sites = useSelector((state) => state[appId].sites);

  const [site, setSite] = useState({});
  useEffect(() => {
    if (siteId && siteId.startsWith('site') && sites.length) {
      setSite(find(sites, { site_id: siteId }));
    } else {
      setSite(defaultState);
    }
  }, [sites, siteId]);

  return site;
};

export default useSite;
