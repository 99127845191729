import { refreshTokens } from './cognito';
import { getTokens } from './tokens';

const getCurrentSession = async (clientId, appName) => {
  const now = Math.round(new Date().getTime() / 1000);
  const { refreshToken, accessToken, idToken, exprTime } = getTokens(clientId);

  // if refreshToken has not expired, return values from localStorage
  if (now < exprTime && accessToken) {
    return {
      accessToken,
      idToken,
      exprTime: exprTime,
    };
  }
  if (refreshToken) {
    return await refreshTokens(clientId, appName, refreshToken);
  }

  console.warn('No current session');
  return { accessToken: null };
};

export { getCurrentSession };
