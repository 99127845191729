import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { openConfirmDialog } from '../../../../store/dialogs';
import { putMeterShedValues } from '../../../../store/demand';

const shedText = (shedValue) => {
  let textValue = 'auto';
  if (shedValue === false) textValue = 'inactive';
  else if (shedValue === true) textValue = 'active';
  return textValue;
};

function EditMetersButton(props) {
  const { meters } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenDialog = (value) => {
    const sortedMeters = sortBy(meters, 'name');
    dispatch(
      openConfirmDialog({
        title: `Update shed state to ${shedText(value)} for the
        following meters`,
        message: (
          <List disablePadding>
            {map(sortedMeters, (meter) => {
              const meterId = get(meter, 'meter_id');
              const meterName = get(meter, 'name');
              return <ListItemText key={meterId} primary={'• ' + meterName} />;
            })}
          </List>
        ),
        onConfirm: async () =>
          dispatch(putMeterShedValues({ shed: value, meters })),
      })
    );
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-meter-update-shed-state' : undefined;

  return (
    <>
      <Tooltip title='update meter shed state'>
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ width: 35, height: 35, mb: '8px !important' }}>
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}>
        <List disablePadding>
          {map([null, false, true], (value) => {
            return (
              <ListItemButton
                key={`list-item-${String(value)}`}
                onClick={() => handleOpenDialog(value)}>
                <Typography>{shedText(value)}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </>
  );
}

EditMetersButton.propTypes = {
  meters: PropTypes.array,
};

export default EditMetersButton;
